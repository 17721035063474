<template>
  <div>
    <MapComponent></MapComponent>
  </div>

</template>

<script>

import MapComponent from "@/components/Map/MapComponent";

export default {
  name: "MapView",
  components: {
    MapComponent,
    },
  methods: {}
}
</script>

<style scoped>

</style>
