import pako from 'pako/dist/pako.js'
import store from '../store'
import {getToken} from '../auth0/authObj'


function pakoDecomp(b64) {
    // source: http://stackoverflow.com/questions/4507316/zlib-decompression-client-side
    // Decode base64 (convert ascii to binary)
    const charData = atob(b64).split('').map(function (x) {
        return x.charCodeAt(0);
    });

    // Turn number array into byte-array
    const binData = new Uint8Array(charData);

    // Pako magic
    const pkStr = pako.inflate(binData, {to: 'string'});

    return JSON.parse(pkStr)
}

export async function ciboPost(payload) {

    payload.access_token = await getToken()

    // const response = await fetch('https://xp0z3obw0a.execute-api.ap-southeast-2.amazonaws.com/dev/', { // OLD
    // const response = await fetch('https://4g3f2iyfqc.execute-api.ap-southeast-2.amazonaws.com/api/', { // NEW DEV
    // const response = await fetch('https://0p4embf7w6.execute-api.ap-southeast-2.amazonaws.com/api/', { // NEW PROD

    let api_endpoint
    if (process.env.NODE_ENV === 'production') {
        api_endpoint = store.state.cibo_apis.prod
    } else {
        api_endpoint = store.state.cibo_apis.dev
    }

    console.log('ciob_api ➡️', api_endpoint)
    const response = await fetch(api_endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    })

    return pakoDecomp(await response.text())

}

async function traverse_convert_date(dict_obj) {

    function process_dt(o, k, v) {
        if (k.includes('_date') && v !== null) {
            v = new Date(v * 1000);    // to convert to miliseconds
            o[k] = v;
        }
    }

    function isArray(o) {
        return Object.prototype.toString.call(o) === '[object Array]'
    }

    function traverseArray(arr) {
        arr.forEach(function (x) {
            traverse(x)
        })
        return arr
    }

    function traverseObject(obj) {
        for (const [key, value] of Object.entries(obj)) {
            console.log(key, value)
            process_dt(obj, key, value);
            traverse(value)
        }
        return obj
    }

    function traverse(x) {
        if (isArray(x)) {
            x = traverseArray(x)
        } else if (typeof x === 'object' && x !== null) {
            x = traverseObject(x)
        }
        return x
    }

    return traverse(dict_obj);

}


export async function get_version() {

    const resp = await ciboPost({
        app_action: 'get_version',
        data: {app: 'mfk'}
    })

    return resp.data


}

export async function c_agriprove_soil_project_task(property_id, client_email, data) {

    console.log(property_id, client_email, data)
    let task_id = null
    const resp = await ciboPost({
        app_action: 'c_agriprove_soil_project_task',
        data: {
            property_id: property_id,
            client_email: client_email,
            d: data,
        }
    })

    if (await resp['status'] === 'success') {
        task_id = await resp.data.task_id
    }

    return task_id

}


export async function c_mfk_task(owner_id, owner_email, property_id, task_type, task_status, d) {
    // 'owner_id': data['owner_id'],
    //                        'owner_email': data['owner_email'],
    //                        'property_id': data['property_id'],
    //                        'task_name': data['task_name'],
    //                        'task_status': 'Submitted',
    //                        'd': psycopg2.Binary(pickle.dumps(data['d'], 2)),


    const resp = await ciboPost({
        app_action: 'c_mfk_task',
        data: {
            owner_id: owner_id,
            owner_email: owner_email,
            property_id: property_id,
            task_type: task_type,
            task_status: task_status,
            d: d
        }
    })
    let task_id
    if (await resp['status'] === 'success') {
        task_id = await resp.data.task_id
    }

    return task_id

}


export async function r_agriprove_soil_project_task() {

    let data = null
    const resp = await ciboPost({
        app_action: 'r_agriprove_soil_project_task',
        data: {}
    })

    if (await resp['status'] === 'success') {
        data = await resp.data
        console.log(data)
        store.state.agriProve.admin.soilProjectTasks = data

        for (let item of store.state.agriProve.admin.soilProjectsList) {
            let property_id = item.property_id
            if (Object.keys(store.state.agriProve.admin.soilProjectTasks).includes(property_id)) {
                item.submission_status = store.state.agriProve.admin.soilProjectTasks[property_id].task_status
                item.submission_post_id = store.state.agriProve.admin.soilProjectTasks[property_id].post_cer_id
                item.submission_post_resp = store.state.agriProve.admin.soilProjectTasks[property_id].post_cer_resp
            }
        }

        // let soilProjTasksKeys = Object.keys(store.state.agriProve.admin.soilProjectTasks).map((i) => Number(i))

        // for (let item of store.state.agriProve.admin.soilProjectsList2) {
        //     let property_id = item.property_id
        //     if (soilProjTasksKeys.includes(property_id)) {
        //         item.submission_status = store.state.agriProve.admin.soilProjectTasks[property_id].task_status
        //         item.submission_post_id = store.state.agriProve.admin.soilProjectTasks[property_id].post_cer_id
        //         item.submission_post_resp = store.state.agriProve.admin.soilProjectTasks[property_id].post_cer_resp
        //     }
        // }

    }

}


export async function rCibo(schema, table) {

    const resp = await ciboPost({
        'app_action': 'r',
        'data': {
            'schema_table_list': [
                {'schema': schema, 'table': table}
            ]
        },
    })

    if (await resp['status'] === 'success') {

        // if (!(schema in store.state.db)){
        //     store.state.db[schema] = {}
        // }
        // if (!(table in store.state.db[schema])){
        //     store.state.db[schema][table] = {}
        // }

        const data = await traverse_convert_date(resp['data'])

        for (const schema_name in data) {
            if (!(schema_name in store.state.db)) {
                store.state.db[schema_name] = {}
            }
            for (const table_name in data[schema_name]) {
                if (!(table_name in store.state.db[schema_name])) {
                    store.state.db[schema_name][table_name] = {}
                }
                store.state.db[schema_name][table_name] = data[schema_name][table_name]
            }
        }

        console.log('|||||||||||||||||||||||||||')
        console.log(store.state)
        // console.log('traverse out', await x)
    }

    console.log(resp)
}


export async function r_cerestag__tag_data() {

    const resp = await ciboPost({
        'app_action': 'r_cerestag__tag_data',
        'data': {},
    })

    if (!('cerestag' in store.state.db)) {
        store.state.db.cerestag = {}
    }
    if (!('tag_data' in store.state.db.cerestag)) {
        store.state.db.cerestag.tag_data = {}
    }

    const tag_data = await traverse_convert_date(resp['data']['cerestag']['tag_data'])
    store.state.db.cerestag.tag_data = tag_data
    console.log('+++++++++++++++++++++')
    console.log(store.state.db)

}


export async function rAll() {
    console.log(store)

    return await ciboPost({
        'app_action': 'r',
        'data': {
            'schema_table_list': [
                {'schema': 'cibo', 'table': 'farms'}
            ]
        },
    })
}

