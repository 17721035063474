import Vue from 'vue'
import VueRouter from 'vue-router'

import MapView from "@/views/MapView.vue";
import BenchmarkView from "../views/BenchmarkView";
import PropertiesView from "@/views/PropertiesView";
import ShopView from "@/views/ShopView";

import SettingsView from '@/views/SettingsView'
import AgriProveView from "@/views/AgriProveView";
import AgentAdminView from "@/views/AgentAdminView";
import AdminView from "@/views/AdminView";
import CreateAccountView from "../views/CreateAccountView";
// import MaintenanceModeView from "../views/MaintenanceModeView";
import { authGuard } from "@/auth0/authGuard";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: SettingsView,
        beforeEnter: authGuard
    },
    {
        path: '/map',
        name: 'Map',
        component: MapView,
        beforeEnter: authGuard
    },
    {
        path: '/benchmark',
        name: 'Benchmark',
        component: BenchmarkView,
        beforeEnter: authGuard
    },
    {
        path: '/farms',
        name: 'Farms',
        component: PropertiesView,
        beforeEnter: authGuard
    },
    {
        path: '/shop',
        name: 'Shop',
        component: ShopView,
        beforeEnter: authGuard
    },
    {
        path: '/agriprove',
        name: 'AgriProve',
        component: AgriProveView,
        beforeEnter: authGuard
    },
    {
        path: '/agent-admin',
        name: 'AgentAdmin',
        component: AgentAdminView,
        beforeEnter: authGuard
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminView,
        beforeEnter: authGuard
    },
    {
        path: '/settings',
        name: 'Settings',
        component: SettingsView,
        beforeEnter: authGuard
    },
    {
        path: '/create_account',
        name: 'CreateAccount',
        component: CreateAccountView,
        beforeEnter: authGuard
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: SettingsView,
        // component: MaintenanceModeView,
        beforeEnter: authGuard
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
