<template>
  <BenchmarkComponent></BenchmarkComponent>
</template>

<script>
import BenchmarkComponent from "../components/Benchmark/BenchmarkComponent";
export default {
  name: "BenchmarkView",
  components: {BenchmarkComponent,}
}
</script>

<style scoped>

</style>
