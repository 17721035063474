<template>
<ShoppingCart></ShoppingCart>
</template>

<script>

import ShoppingCart from "@/components/Market/ShoppingCart";

export default {
  name: "ShopView",
  components: {ShoppingCart}
}
</script>

<style scoped>

</style>
