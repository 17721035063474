<template>
  <div style="z-index: 10000;">
    <v-app-bar app dense clipped-left color="white">
      <!--      <v-app-bar-nav-icon @click="switchDrawer"></v-app-bar-nav-icon>-->

      <v-app-bar-title>
        <span class="font-weight-light text-capitalize">My</span>
        <span class="text-capitalize">FarmKey</span>
        <small style="margin-right: 5px;"> by </small>
      </v-app-bar-title>
      <v-img class="mr-2" max-width="120" max-height="20" src="../assets/cibolabs.png"/>
      <small style="color: gray; font-size: 0.7em;"> v {{ $store.state.version }}</small>

      <v-spacer></v-spacer>

      <!--      <div v-if="!$auth.loading" v-show="false">-->
      <!--        &lt;!&ndash; show login when not authenticated &ndash;&gt;-->
      <!--        <button v-if="!$auth.isAuthenticated" @click="login">Log in</button>-->
      <!--        &lt;!&ndash; show logout when authenticated &ndash;&gt;-->
      <!--        <button v-if="$auth.isAuthenticated" @click="logout">Log out</button>-->

      <!--      </div>-->

      <v-btn icon color="gray" class="mr-2" @click="logIn" v-if="!$auth.loading && !$auth.isAuthenticated">
        <!--        <span class="hidden-xs-only">Log In</span>-->
        <v-icon right>mdi-login</v-icon>
      </v-btn>

      <v-btn icon color="gray" class="mr-2" @click="logOut" v-if="!$auth.loading && $auth.isAuthenticated">
        <!--        <span class="hidden-xs-only">Log Out</span>-->
        <v-icon right>mdi-logout</v-icon>
      </v-btn>

      <!--      <v-btn color="gray" class="mr-2" @click.stop="showLogIn=true">-->
      <!--        <span class="hidden-xs-only">Log In</span>-->
      <!--        <v-icon right>mdi-login</v-icon>-->
      <!--        <LogIn v-if="showLogIn" v-model="showLogIn" data="hello" v-bind:dataObj="{items}"/>-->
      <!--      </v-btn>-->

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        permanent clipped absolute hide-overlay app width="50"
        v-if="$store.state.auth0_does_owner_exist">
      <!--    app :mini-variant.sync="mini"-->
      <!--      <v-list-item>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title class="title">-->
      <!--            Cibo Labs-->
      <!--          </v-list-item-title>-->
      <!--          <v-list-item-subtitle>-->
      <!--            My Farm Key-->
      <!--          </v-list-item-subtitle>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->


      <!--      <v-divider></v-divider>-->

      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            router :to="item.link"
        >
          <v-list-item-icon @click="amplitudeClicks(item)">

            <v-badge
                v-if="item.title === 'Shop' && $store.state.shoppingCart.length > 0"
                color="orange"
                :content="$store.state.shoppingCart.length"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>

            <v-icon v-else :title="item.title">{{ item.icon }}</v-icon>

          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  </div>

</template>

<script>

// import LogIn from "@/components/auth/LogIn";
// import amplitude from 'amplitude-js';
// amplitude.getInstance().init("0f41b47d6f6f2667ec423d2a834eda1a");
// import amplitude from 'amplitude'

// let Amplitude = new amplitude('0f41b47d6f6f2667ec423d2a834eda1a')

export default {
  name: "Navbar",
  // components: {LogIn},
  data() {
    return {
      mini: true,
      drawer: true,
      showLogIn: false,
      items: this.$store.state.navItems,
      right: null,
    }

  },
  methods: {
    amplitudeClicks(item) {

      console.log('AMPLITUDE', item.title)
      // amplitude.logEvent(`MFK-UserLoadsPage-${item.title}`);
      // Amplitude.track({
      //   event_type: `MFK-UserLoadsPage-${item.title}`
      // });

    },
    logIn() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logOut() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    switchDrawer() {
      console.log(this);

      this.drawer = !this.drawer
    },
  }
}
</script>

<style scoped>

</style>
