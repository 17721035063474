import pako from 'pako/dist/pako.js'
import store from '../store'
import router from "../router";
import {getToken} from '../auth0/authObj'

function pakoDecomp(b64) {
    // source: http://stackoverflow.com/questions/4507316/zlib-decompression-client-side
    // Decode base64 (convert ascii to binary)
    const charData = atob(b64).split('').map(function (x) {
        return x.charCodeAt(0);
    });

    // Turn number array into byte-array
    const binData = new Uint8Array(charData);

    // Pako magic
    const pkStr = pako.inflate(binData, {to: 'string'});

    return JSON.parse(pkStr)
}


export async function mfkLambdaPost(payload) {

    payload.access_token = await getToken()

    let api_endpoint
    if (process.env.NODE_ENV === 'production') {
        api_endpoint = store.state.cibo_apis.prod
    } else {
        api_endpoint = store.state.cibo_apis.dev
    }


    // const response = await fetch('https://xp0z3obw0a.execute-api.ap-southeast-2.amazonaws.com/dev/', { // OLD
    // const response = await fetch('https://4g3f2iyfqc.execute-api.ap-southeast-2.amazonaws.com/api/', { // NEW DEV
    // const response = await fetch('https://0p4embf7w6.execute-api.ap-southeast-2.amazonaws.com/api/', { // NEW PROD

    console.log('mfk_api ➡️', api_endpoint)
    const response = await fetch(api_endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    })

    return pakoDecomp(await response.text())

}


export async function downloadPropertyKey(property_id) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/download-geojson/',
                p_dict: {
                    property_id: property_id,
                }
            }
        }
    )

    return resp.data[0]['geojson']

}


export async function removePlanAuthorizedClients(plan_id, owner_emails) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/remove-plan-authorised-clients/',
                p_dict: {
                    plan_id: plan_id,
                    owner_emails: [owner_emails]
                }
            }
        }
    )

    console.log('remove-plan-authorised-clients')
    console.log(resp)
    await getPlans(store.state.userDict.email_address)

}

export async function addPlanAuthorizedClients(plan_id, owner_emails) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/add-plan-authorised-clients/',
                p_dict: {
                    plan_id: plan_id,
                    owner_emails: owner_emails.split(','),
                    authorised_by: store.state.userDict.email_address
                }
            }
        }
    )

    console.log('add-plan-authorised-clients')
    console.log(resp)
    console.log('getting plans')
    console.log(store.state.userDict)
    console.log(store.state.userDict.email_address)
    await getPlans(store.state.userDict.email_address)

}


export async function createPlan(plan_name, emails, property_key_price, benchmark_report_price, carbon_report_price) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/create-plan/',
                p_dict: {
                    emails: emails.split(','),
                    property_key_price: parseFloat(property_key_price),
                    benchmark_report_price: parseFloat(benchmark_report_price),
                    carbon_report_price: parseFloat(carbon_report_price),
                    plan_name: plan_name
                }
            }
        }
    )


    console.log('create Plan')
    console.log(resp)

    await getAllPlans()
    await getPlans(store.state.userDict.email_address)

}


export async function editOwner(reqObj) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/edit-owner/',
                p_dict: reqObj
            }
        }
    )

    return await resp.data
}


export async function getUserMetaData(userEmail) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/owner/',
                p_dict: {email: userEmail}
            }
        }
    )

    console.log('in owner')
    console.log(resp)
    store.state.userDict = resp.data

}


export async function getOwnerInfo(userEmail) {
    // this is for agriprove
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/owner/',
                p_dict: {email: userEmail}
            }
        }
    )

    return resp.data

}


export async function doesOwnerExist(userEmail) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/does-owner-exist/',
                p_dict: {email: userEmail}
            }
        }
    )

    if (resp.data[0].does_owner_exist) {
        store.state.auth0_does_owner_exist = true
    } else {
        store.state.auth0_does_owner_exist = false
        if (router.currentRoute.name !== 'CreateAccount') {
            await router.push({path: 'create_account'})
        }

    }


}

export async function createOwner(user_name, user_business, user_phone, user_email) {
    store.state.auth0_create_owner_loading = true
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/create-owner/',
                p_dict: {
                    name: user_name,
                    business: user_business,
                    phone: user_phone,
                    email: user_email
                }
            }
        }
    )


    if (resp.data[0].create_owner === -1) {
        console.log()
        store.state.auth0_create_owner_loading = false
        store.state.auth0_create_owner_err = true
    } else {
        store.state.auth0_create_owner_err = false
        await doesOwnerExist(user_email)
        await router.push({path: 'settings'})
        store.state.auth0_create_owner_loading = false
        await getAllDataForUser(user_email)

    }


}

export async function createAgriProveProject(property_id, chosen_actions, project_name, project_status, project_attributes) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/create-agriprove-project/',
                p_dict: {
                    property_id,
                    chosen_actions,
                    project_name,
                    project_status,
                    project_attributes
                }
            }
        }
    )

    return resp.data

}

export async function updateAgriproveProjectProperties(project_id, property_id, project_attributes) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/update-agriprove-project-properties/',
                p_dict: {
                    project_id,
                    property_id,
                    project_attributes
                }
            }
        }
    )

    return resp

}

export async function updateAgriproveProjectStatus(project_id, project_status) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/agriprove_update_project_status/',
                p_dict: {
                    project_id,
                    project_status
                }
            }
        }
    )

    return resp

}


export async function getUploadUrls(bucket_key_pairs) {
    console.log('bucket_key_pairs')
    console.log(bucket_key_pairs)
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-upload-urls/',
                p_dict: {
                    bucket_key_pairs: bucket_key_pairs,
                }
            }
        }
    )
    console.log(resp)

    return resp

}

export async function getDownloadSignedUrl(aws_bucket, aws_key) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-download-signed-url/',
                p_dict: {
                    aws_bucket: aws_bucket,
                    aws_key: aws_key,
                }
            }
        }
    )

    return resp.data

}


export async function renameAgriProveProject(project_id, property_id, project_name) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/rename-agriprove-project/',
                p_dict: {
                    project_id, property_id, project_name
                }
            }
        }
    )

    return resp
}


export async function agriprove_update_project_status_and_attributes(project_id, project_status, project_attributes) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/agriprove_update_project_status_and_attributes/',
                p_dict: {
                    project_id, project_status, project_attributes
                }
            }
        }
    )

    return resp
}


export async function getClientSoilProjects(property_ids) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: 'getClientSoilProjects',
                p_dict: {
                    'property_ids': property_ids,
                }
            }
        }
    )
    console.log(resp)

    return resp.data

}

export async function client_save_agriprove_project(project_id, property_id, chosen_actions, project_name, project_status, project_attributes) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: 'client_save_agriprove_project',
                p_dict: {
                    project_id,
                    property_id,
                    chosen_actions,
                    project_name,
                    project_status,
                    project_attributes
                }
            }
        }
    )
    console.log(resp)
    project_id = resp.data
    return project_id
}

export async function getAgriProveSoilProjects() {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/soil-projects/',
                p_dict: {}
            }
        }
    )
    console.log('getAgriProveSoilProjects')
    console.log(resp.data)
    store.state.agriProve.admin.soilProjects = {}

    for (let item of resp.data) {
        let project_id = item.project_id;

        if (!(project_id in store.state.agriProve.admin.soilProjects)) {
            store.state.agriProve.admin.soilProjects[project_id] = []
        }
        store.state.agriProve.admin.soilProjects[project_id].push(item)

    }
    console.log('SOIL PROJECTS')
    console.log(store.state.agriProve.admin.soilProjects)

    store.state.agriProve.admin.soilProjectsList = []
    for (let project_id of Object.keys(store.state.agriProve.admin.soilProjects)) {
        if (store.state.agriProve.admin.soilProjects[project_id][0].property_id !== null) {
            store.state.agriProve.admin.soilProjectsList.push({
                project_id: project_id,
                property_id: store.state.agriProve.admin.soilProjects[project_id][0].property_id,
                property_name: store.state.agriProve.admin.soilProjects[project_id][0].property_name,
                project_name: store.state.agriProve.admin.soilProjects[project_id][0].project_name,
                email_address: store.state.agriProve.admin.soilProjects[project_id][0].email_address,
                no_of_cads: store.state.agriProve.admin.soilProjects[project_id].length,
                project_status: store.state.agriProve.admin.soilProjects[project_id][0].project_status,
                project_attributes: store.state.agriProve.admin.soilProjects[project_id][0].project_attributes,
                is_active: store.state.agriProve.admin.soilProjects[project_id][0].is_active,
                // submission_status: null,
                // submission_post_id: null,
                // submission_post_error: null,
            })
        }

    }
    console.log('store.state.agriProve.admin.soilProjectsList')
    console.log(store.state.agriProve.admin.soilProjectsList)


}


export async function getPresignedUrls(property_id, property_name, project_id, version) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-presigned-urls/',
                p_dict: {
                    property_id: property_id,
                    property_name: property_name,
                    project_id: project_id,
                    version: version
                }
            }
        }
    )

    console.log('in getPresignedUrls')
    console.log(resp)
    return await resp.data

}

export async function find_cads(property_id, km, area) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/find-cads-within-radius/',
                p_dict: {
                    property_id: property_id,
                    km: km,
                    area: area
                }
            }
        }
    )

    return resp.data

}

export async function find_cads_in_geojson(geo_json_list) {

    let cads_list = []
    for (let geo_json of geo_json_list) {
        const resp = await mfkLambdaPost(
            {
                app_action: 'mfk',
                data: {
                    url_path: '/api/private/find-cads-in-geojson/',
                    p_dict: {
                        geo_json: geo_json,
                    }
                }
            }
        )
        cads_list = cads_list.concat(resp.data)

    }


    return cads_list

}


export async function find_cads_in_kml(kml) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/find-cads-in-kml/',
                p_dict: {
                    kml: kml,
                }
            }
        }
    )

    return resp.data

}


export async function getProperties(userEmail) {
    store.state.initAllLoaded = false
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/properties/',
                p_dict: {email: userEmail}
            }
        }
    )

    console.log('get properties >>>>>>>>>>>>>>>>')
    console.log(resp)

    // store.state.properties = {}
    let property_id
    for (let index in await resp.data) {
        property_id = resp.data[index].property_id
        if (!(property_id in store.state.properties)) {
            store.state.hasProperties = true
            store.state.properties[property_id] = {
                inf: resp.data[index],
                bnd: null,
                lpa_verified: false,
            }
            check_LPA_verification(property_id)

        }

    }
    store.state.initAllLoaded = true
    return resp.data

}

export async function getAllPropertyBoundaries(userEmail) { // NOT USED

    if (Object.keys(store.state.properties).length === 0) {
        await getProperties(userEmail)
    }
    // let resp
    for (let property_id of Object.keys(store.state.properties)) {

        const resp = await mfkLambdaPost(
            {
                app_action: 'mfk',
                data: {
                    url_path: '/api/private/get-property-cads/',
                    p_dict: {
                        email: userEmail,
                        property_id: property_id,
                    }
                }
            }
        )

        store.state.properties[property_id].bnd = {}

        for (let bndItem of await resp.data) {

            store.state.properties[property_id].bnd[bndItem.cadastre_polygon_pid] = {
                bndObj: bndItem,
                mapObj: null,
            }
        }

    }
    store.state.allPropertiesLoaded = true

}


export async function getPropertyBoundary(userEmail, property_id) {
    let isLoaded = true

    let is_aus_farmkey = store.state.properties[property_id].inf.is_aus_farmkey
    let resp
    if (is_aus_farmkey) {
        resp = await mfkLambdaPost(
            {
                app_action: 'mfk',
                data: {
                    url_path: '/api/private/get-property-cads/',
                    p_dict: {
                        email: userEmail,
                        property_id: property_id,
                    }
                }
            }
        )

        store.state.properties[property_id].bnd = {}
        console.log('/api/private/get-property-cads/')
        console.log(resp)
        for (let bndItem of resp.data) {

            store.state.properties[property_id].bnd[bndItem.cadastre_polygon_pid] = {
                bndObj: bndItem,
            }

        }
    } else {
        resp = await mfkLambdaPost(
            {
                app_action: 'mfk',
                data: {
                    url_path: 'get_non_farmkey_boundary',
                    p_dict: {
                        property_id: property_id,
                    }
                }
            }
        )
        console.log('this is not a farmkey')
        console.log(resp)
        store.state.properties[property_id].bnd = {}
        for (let bndItem of resp.data) {
            console.log(bndItem.cadastre_polygon_pid)
            console.log(bndItem)
            store.state.properties[property_id].bnd[bndItem.cadastre_polygon_pid] = {
                bndObj: bndItem,
            }
        }
        console.log(store.state.properties[property_id])
        console.log(store.state.properties)

    }



    store.state.allPropertiesLoaded = true

    return isLoaded
}


export async function getPropertyBoundaryAtLatLon(lat, lng) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-cad-at-lat-lng/',
                p_dict: {"lat": lat, "lng": lng}
            }
        }
    )

    return await resp.data

}

export async function createProperty(reqObj) {

    // console.log('create property new ----------------------')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/create-property/',
                p_dict: reqObj
            }
        }
    )

    return await resp.data

}

export async function update_farm_geojson_boundary(property_id, geojson_obj) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: 'update_farm_geojson_boundary',
                p_dict: {'property_id': property_id, 'geojson': geojson_obj}
            }
        }
    )

    return await resp.data


}

export async function update_farm_info_nonfarmkey(req_obj){

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: 'update_property_info',
                p_dict: req_obj
            }
        }
    )

    return await resp.data


}


export async function editProperty(reqObj) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/edit-property/',
                p_dict: reqObj
            }
        }
    )


    return resp.data

}


export async function deleteProperty(propID) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/delete-property/',
                p_dict: {property_id: propID}
            }
        }
    )


    delete store.state.properties[propID]

    return await resp

}

export async function addCadsToProperty(reqObj) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/add-cads-to-property/',
                p_dict: reqObj
            }
        }
    )

    return resp.data


}


export async function checkReorderFree(property_id) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/check-reorder-free/',
                p_dict: {property_id: property_id}
            }
        }
    )
    console.log('checkReorderFree')
    console.log(resp)
    return resp.data[0].check_free_reorder
}

export async function getPlans(userEmail) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-plans/',
                p_dict: {
                    email: userEmail
                }
            }
        }
    )
    console.log('in getPlans')
    console.log(resp.data)
    if (resp.data.length > 0) {
        let isInNav = false
        for (let navItem of store.state.navItems) {
            if (navItem.title === 'Agent Admin') {
                isInNav = true
            }
        }
        if (!isInNav) {
            store.state.isAgentAdmin = true
            store.state.navItems.push({title: 'Agent Admin', icon: 'mdi-account-tie', link: '/agent-admin'},)
        }
    }
    console.log(store.state.navItems)

    store.state.userPlans = []
    store.state.userPlans = resp.data
    console.log('PLANS', store.state.userPlans)
    return resp.data

}

export async function getAuthorizedPlans(userEmail) {
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-authorised-plans/',
                p_dict: {
                    email: userEmail
                }
            }
        }
    )
    console.log('in getAuthorizedPlans')
    console.log(resp.data)

    store.state.authorizedPlans = []
    store.state.authorizedPlans = resp.data
    console.log('authorized PLANS', store.state.authorizedPlans)
    return resp.data

}


export async function checkUserPermission(userEmail) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/check-user-permission/',
                p_dict: {
                    email: userEmail,
                    permission: 'admin'
                }
            }
        }
    )
    console.log('checkUserPermission')
    console.log(resp)
    const isAdmin = resp.data[0].check_user_permission
    console.log(isAdmin)
    store.state.isAdmin = isAdmin
    if (isAdmin) {
        let isInNav = false
        for (let navItem of store.state.navItems) {
            if (navItem.title === 'Admin') {
                isInNav = true
            }
        }
        if (!isInNav) {
            store.state.navItems.push({title: 'Admin', icon: 'mdi-account-multiple', link: '/admin'})
        }
    }

    store.state.userPlans = []
    store.state.userPlans = resp
    return await resp

}


export async function getAllPlans() {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/get-all-plans/',
                p_dict: {}
            }
        }
    )

    store.state.allPlans = []
    store.state.allPlans = resp.data

    return resp.data

}


export async function recordExports(reqObj) {

    console.log('IN recordExports')
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/record-exports/',
                p_dict: reqObj
            }
        }
    )
    console.log(resp)

    return await resp

}


export async function checkKeyBeenPurchased(reqObj) {

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/check-key-been-purchased/',
                p_dict: reqObj
            }
        }
    )

    return await resp.data[0].has_property_key_been_purchased


}


export async function recordBenchmarks(reqObj) {
    console.log('in recordBenchmarks')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/record-benchmarks/',
                p_dict: reqObj
            }
        }
    )

    console.log('in recordBenchmarks')
    console.log(reqObj)
    return resp.data

}


export async function addPlanExport(reqObj) {
    console.log('in addPlanExport')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/add-plan-export/',
                p_dict: reqObj
            }
        }
    )

    console.log('addPlanExport')
    console.log(resp.data)
    return resp.data

}


export async function addPaypalPayment(reqObj) {
    console.log('in addPaypalPayment')
    console.log(reqObj)
    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/add-paypal-payment/',
                p_dict: reqObj
            }
        }
    )

    console.log(resp)
    return resp.data


}


export async function getAllDataForUser(userEmail) {
    console.log('GET ALL -----------------')
    await checkUserPermission(userEmail)
    await getPlans(userEmail)
    await getAuthorizedPlans(userEmail)
    await getUserMetaData(userEmail)
    await getProperties(userEmail)
    // await getAllPropertyBoundaries(userEmail)
    store.state.initAllLoaded = true

}


export function addToShoppingCart(property_id, property_name, item_type, comparison_cads, PropertyKeyIdForBenchmarkReport) {

    const pricesDict = {
        'Property Key': 75,
        'Benchmark Report': 175,
        'Carbon Report': 155,
    }
    const item_names = {
        'Property Key': 'Farm Key',
        'Benchmark Report': 'Woody Veg. & Ground Cover Report',
        'Carbon Report': 'Forest Carbon Report',
    }

    const currency = 'AUD'

    let recExists = false
    // check if record in table
    for (const item of store.state.shoppingCart) {

        if (item.property_id === property_id && item.item_type === item_type) {
            recExists = true
            console.log('rec Exist !!')
            showSnackBar(item_type + ' for this property is already in the shopping card')
        }
    }

    if (!recExists) {

        store.state.shoppingCart.push({
            property_id,
            property_name,
            item_type,
            item_name: item_names[item_type],
            price: pricesDict[item_type],
            currency,
            comparison_cads: comparison_cads,
            'PropertyKeyIdForBenchmarkReport': PropertyKeyIdForBenchmarkReport
        })
        store.state.shoppingCart.sort((a, b) => (a.property_id > b.property_id) ? 1 : -1)
        showSnackBar(item_type + ' is added to shopping card')
    }
}


export async function verifyLPA(property_id, pic_code, userid, pass) {
    console.log('in verifyLPA')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/lpa-verify/',
                p_dict: {
                    pic: pic_code,
                    userid: userid,
                    pass: pass
                }
            }
        }
    )

    console.log('verification resp', resp)
    if (resp.data !== 'Login Failure') {
        await update_LPA_verification(property_id)
    }

    console.log(status)
    return await resp

}

export async function update_LPA_verification(property_id) {
    console.log('in update_LPA_verification')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/update-verification/',
                p_dict: {
                    property_id: property_id,
                    verified: true,
                }
            }
        }
    )
    console.log('update_LPA_verification')
    console.log(resp)
    await check_LPA_verification(property_id)
    return resp
}

export async function check_LPA_verification(property_id) {
    // check verification for every property
    console.log('in check_LPA_verification')

    const resp = await mfkLambdaPost(
        {
            app_action: 'mfk',
            data: {
                url_path: '/api/private/check-verification/',
                p_dict: {property_id: property_id}
            }
        }
    )

    store.state.properties[property_id].lpa_verified = resp.data[0]['check_property_verified']

}


export function showSnackBar(text, timeOut = 2000, color = 'pink') {
    store.state.snackbar.show = true
    store.state.snackbar.text = text
    store.state.snackbar.color = color

    setTimeout(() => {
        store.state.snackbar.show = false
        store.state.snackbar.text = null
    }, timeOut)

}

