<template>
  <v-container v-if="!$store.state.isAdmin">
    <v-alert
        color="red"
        dense
        icon="mdi-alert"
        text
        type="warning"
    >
      You do not have access to this section.
    </v-alert>

  </v-container>
  <AdminComponent v-else></AdminComponent>

</template>

<script>

import AdminComponent from "../components/Admin/AdminComponent";

export default {
  name: "AdminView",
  components: {AdminComponent},
  data() {
    return {
      // isAdmin: false,
    }
  },
  methods: {
    // checkAccess() {
    //   const userEmail = this.$auth.user.email
    //   const userDomain = userEmail.split('@')[1]
    //   const authDomainsList = ['cibolabs.com.au']
    //
    //   if (authDomainsList.includes(userDomain)) {
    //     this.isAdmin = true
    //     let isInNav = false
    //     for (let navItem of this.$store.state.navItems) {
    //       if (navItem.title === 'Admin') {
    //         isInNav = true
    //       }
    //     }
    //
    //     if (!isInNav) {
    //       this.$store.state.navItems.push({title: 'Admin', icon: 'mdi-account-multiple', link: '/admin'})
    //     }
    //
    //   } else {
    //     this.isAdmin = false
    //   }
    //
    //
    // },
  },
  mounted() {
    // this.checkAccess()
  }
}
</script>

<style scoped>

</style>
