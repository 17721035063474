export const nrm_plans_list = [
    {
        'NRM Region Name': 'ACT',
        'State': 'ACT',
        'Plan Name': 'ACT NRM',
        'Plan Status': 'In development',
        'URL': 'https://www.environment.act.gov.au/act-nrm'
    },
    {
        'NRM Region Name': 'Central Tablelands',
        'State': 'NSW',
        'Plan Name': 'Local Land Services Central Tablelands Local Strategic Plan 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0007/619846/central-tablelands-local-strategic-plan.pdf'
    }, {
        'NRM Region Name': 'Central West',
        'State': 'NSW',
        'Plan Name': 'Local Land Services Central West Local Strategic Plan 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0007/658798/Central-West-Local-Land-Services-Local-Strategic-Plan-2016.pdf'
    }, {
        'NRM Region Name': 'Greater Sydney',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Greater Sydney 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0010/660493/Greater-Sydney-Local-Strategic-Plan-2016-2021.pdf'
    }, {
        'NRM Region Name': 'Hunter',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Hunter 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0008/659186/local-strategic-plan_web.pdf'
    }, {
        'NRM Region Name': 'Murray',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Murray 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0007/658870/Murray_local_strategic_plan_w.pdf '
    }, {
        'NRM Region Name': 'North Coast',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan North Coast 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0011/659774/north-coast-local-strategic-plan-final.pdf'
    }, {
        'NRM Region Name': 'North Coast - Lord Howe Island',
        'State': 'NSW',
        'Plan Name': 'Strategic Plan for Lord Howe Island Group World Heritage Property',
        'Plan Status': 'Due for update',
        'URL': 'https://www.environment.gov.au/system/files/pages/a7088999-c54e-4e80-9891-fba5de5acd77/files/lord-howe-strategic-management-plan-2010.pdf'
    }, {
        'NRM Region Name': 'North West NSW',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan North West 2-16-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0009/657873/North-West-Local-Strategic-Plan_LR.pdf'
    }, {
        'NRM Region Name': 'Northern Tablelands',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Northern Tablelands 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0005/659291/nt-lsp-0616.pdf'
    }, {
        'NRM Region Name': 'Riverina',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Riverina 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0008/658133/Strategicplanfinal.pdf'
    }, {
        'NRM Region Name': 'South East NSW',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan South East 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0005/662351/south-east-strategic-plan.pdf'
    }, {
        'NRM Region Name': 'Western',
        'State': 'NSW',
        'Plan Name': 'Local Strategic Plan Western 2016-2021',
        'Plan Status': 'Due for update',
        'URL': 'https://www.lls.nsw.gov.au/__data/assets/pdf_file/0005/657986/Western_local_strategic_plan.pdf'
    }, {
        'NRM Region Name': 'Northern Territory',
        'State': 'NT',
        'Plan Name': 'NT NRM Plan 2016-2020',
        'Plan Status': 'In development',
        'URL': 'https://www.territorynrm.org.au/the-nt-nrm-plan'
    }, {
        'NRM Region Name': 'Burnett Mary Regional Group',
        'State': 'QLD',
        'Plan Name': 'BMRG Strategic Plan 2020-2025',
        'Plan Status': 'Current',
        'URL': 'https://bmrg.org.au/wp-content/uploads/2020/10/BMRG-Strategic-Plan-2020-2025-v2.pdf'
    }, {
        'NRM Region Name': 'Cape York NRM',
        'State': 'QLD',
        'Plan Name': 'Cape York Living NRM Plan',
        'Plan Status': 'Due for update',
        'URL': 'https://capeyorknrm.com.au/sites/default/files/nrm-plan/Cape-York-Living-NRM-Plan-June-2016-FINAL.pdf'
    }, {
        'NRM Region Name': 'Desert Channels Group',
        'State': 'QLD',
        'Plan Name': 'DCQ NRM Plan 2021-2025',
        'Plan Status': 'Current',
        'URL': 'https://dcq-nrm-plan-2021---test-dcq.hub.arcgis.com/'
    }, {
        'NRM Region Name': 'Fitzroy Basin Association',
        'State': 'QLD',
        'Plan Name': 'FBA Strategic Plan 2016-2020',
        'Plan Status': 'Due for update',
        'URL': 'https://www.fba.org.au/wp-content/uploads/2018/08/2469476-FBA-Strategic-Plan-2015-FINAL.pdf'
    }, {
        'NRM Region Name': 'Healthy Land and Water (Brisbane)',
        'State': 'QLD',
        'Plan Name': 'South East Queensland NRM Plan 2009-2031',
        'Plan Status': 'In development',
        'URL': 'https://hlw.org.au/download/south-east-queensland-natural-resource-management-plan-2009-2031/'
    }, {
        'NRM Region Name': 'Northern Gulf Resource Management Group',
        'State': 'QLD',
        'Plan Name': 'Northern Gulf NRM Plan 2016-2021',
        'Plan Status': 'Current',
        'URL': 'http://plan.northerngulf.com.au/wp-content/uploads/2016/02/NGRMG-NRM-Plan-2016-2021.pdf'
    }, {
        'NRM Region Name': 'NQ Dry Tropics',
        'State': 'QLD',
        'Plan Name': 'NQ Dry Tropics Strategic Plan 2018-2022',
        'Plan Status': 'Current',
        'URL': 'https://drive.google.com/file/d/0B2eYGb5_l-adbTFNRFV5Y2xrX0F5blMxbHM0VHJXbHgwWWZB/view'
    }, {
        'NRM Region Name': 'Reef Catchments',
        'State': 'QLD',
        'Plan Name': 'NRM Plan Mackay Whitsunday Isaac 2014-2024',
        'Plan Status': 'Current',
        'URL': 'https://www.arcgis.com/apps/MapJournal/index.html?appid=b4dca9a0fa4047b68b372b39e9b0f21a&webmap=efc9549f96e94f64a0c01034cd6136f1#'
    }, {
        'NRM Region Name': 'Southern Queensland Landscapes',
        'State': 'QLD',
        'Plan Name': 'Flourishing Landscapes Plan - 2020-2021',
        'Plan Status': 'In Development',
        'URL': 'https://irp-cdn.multiscreensite.com/1018ad9f/files/uploaded/SQ%20Landscapes%20-%20Flourishing%20Landscapes%20Plan_01042020.pdf'
    }, {
        'NRM Region Name': 'Southern Gulf NRM',
        'State': 'QLD',
        'Plan Name': 'Southern Gulf NRM Plan 2016',
        'Plan Status': 'Due for update',
        'URL': 'https://www.southerngulf.com.au/wp-content/uploads/2017/01/SG-NRM-Plan_LOW-RES.pdf'
    }, {
        'NRM Region Name': 'Terrain NRM',
        'State': 'QLD',
        'Plan Name': 'Wet Tropics Plan for People and Country',
        'Plan Status': 'Current',
        'URL': 'https://www.wettropicsplan.org.au/'
    }, {
        'NRM Region Name': 'Torres Strait Regional Authority',
        'State': 'QLD',
        'Plan Name': 'Land and Sea Management Strategy for Torres Strais 2016-2036',
        'Plan Status': 'Current',
        'URL': 'https://www.tsra.gov.au/news-and-resources/publications/land-and-sea-management-strategy-for-torres-strait-2016-2036'
    }, {
        'NRM Region Name': 'Alinytjara Wilurara Landscape Board',
        'State': 'SA',
        'Plan Name': 'Alinytjara Wilurara (AW) Regional Natural Resources Management (NRM) Plan',
        'Plan Status': 'Due for update',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/alinytljara_wilurara/corporate/board/alinytjara_wilurara_regional_nrm_plan_altered_cover_1.pdf'
    }, {
        'NRM Region Name': 'Eyre Peninsula Landscape Board',
        'State': 'SA',
        'Plan Name': 'Strategic Plan for the Eyre Peninsula Natural Resources Management Region - 2017-2027',
        'Plan Status': 'Current',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/eyre_peninsula/corporate/2017-27-epnrm-strategic-full-plan.pdf'
    }, {
        'NRM Region Name': 'Hills and Fleurieu Landscape Board',
        'State': 'SA',
        'Plan Name': 'Adelaide and Mt Lofty Ranges Regional NRM Plan Strategic Plan part 1 and part 2, SA Murray-Darling Basin Regional NRM Plan Strategic Plan',
        'Plan Status': 'In Development',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/adelaide_and_mt_lofty_ranges/corporate/nrm_plan/amlr-nrm-plan-strategic-2014-15-2023-24-volume-1-part-1_2.pdf'
    }, {
        'NRM Region Name': 'Kangaroo Island Landscape Board',
        'State': 'SA',
        'Plan Name': 'Kangaroo Island NRM Plan 2021-2027',
        'Plan Status': 'In Development',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/kangaroo_island/about_us/our_regions_plan/climate_change_ready_ki_nrm_plan/ki-nrm-plan-2017-web-smm.pdf'
    }, {
        'NRM Region Name': 'Limestone Coast Landscape Board',
        'State': 'SA',
        'Plan Name': 'SE NRM Board’s Regional NRM Plan (Historic)',
        'Plan Status': 'In Development',
        'URL': 'https://www.landscape.sa.gov.au/lc/about-us/our-regions-plan'
    }, {
        'NRM Region Name': 'Murraylands and Riverlands Landscape Board',
        'State': 'SA',
        'Plan Name': 'Natural Resources SA Murray Darling Basin Natural resources management plan - Volume A Strategic Plan',
        'Plan Status': 'In Development',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/sa_murray-darling_basin/corporate/nrm_plan/92676_samdb_regional_nrm_plan_volume_a_-_fin_v8_web.pdf'
    }, {
        'NRM Region Name': 'Northern and Yorke Landscape Board',
        'State': 'SA',
        'Plan Name': 'Northern and Yorke Strategic Plan 2019-2029',
        'Plan Status': 'Current',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/northern_and_yorke/corporate/board/2019-29-strategic-plan-signed.pdf'
    }, {
        'NRM Region Name': 'South Australian Arid Lands Landscape Board',
        'State': 'SA',
        'Plan Name': 'SA Arid Lands NRM Board Regional NRM Plan (Volume 1)',
        'Plan Status': 'In Development',
        'URL': 'https://www.landscape.sa.gov.au/files/sharedassets/sa_arid_lands/corporate/nrm_plan/17072017_saalregionalplanv1_web-plan.pdf'
    }, {
        'NRM Region Name': 'Green Adelaide Landscape Board',
        'State': 'SA',
        'Plan Name': 'State Natural Resources Management Plan - South Australia 2012-2017',
        'Plan Status': 'In Development',
        'URL': 'https://www.environment.sa.gov.au/files/sharedassets/public/nrm/nrm-gen-statenrmplan.pdf'
    }, {
        'NRM Region Name': 'NRM North',
        'State': 'TAS',
        'Plan Name': 'NRM North Strategic Plan 2019-2023',
        'Plan Status': 'Current',
        'URL': 'https://api.nrmnorth.org.au/serve-resource/NRM_North_Strategic_Plan_2019-2023_v1/'
    }, {
        'NRM Region Name': 'Cradle Coast (North West) NRM',
        'State': 'TAS',
        'Plan Name': 'Cradle Coast NRM Strategy 2015-2020',
        'Plan Status': 'Due for update',
        'URL': 'https://issuu.com/cradlecoast01/docs/cradle_coast_regional_nrm_strategy_?e=34220803/64176333'
    }, {
        'NRM Region Name': 'NRM South',
        'State': 'TAS',
        'Plan Name': 'South NRM Strategy 2015-2020',
        'Plan Status': 'Due for update',
        'URL': 'https://nrmsouth.org.au/wp-content/uploads/2014/08/NRM-Strategy-SOUTH-2015.pdf'
    }, {
        'NRM Region Name': 'Corangamite CMA',
        'State': 'VIC',
        'Plan Name': 'Corangamite Regional Catchment Strategy 2013-2019',
        'Plan Status': 'Due for update',
        'URL': 'https://ccma.vic.gov.au/wp-content/uploads/2019/08/CCMA-RCS-FINAL-JUNE-2013.pdf'
    }, {
        'NRM Region Name': 'East Gippsland',
        'State': 'VIC',
        'Plan Name': '2013-2019 East Gippsland Regional Catchment Strategy',
        'Plan Status': 'Due for update',
        'URL': 'https://egcma.com.au/wp-content/uploads/2019/06/East-Gippsland-Regional-Catchment-Strategy-2013-2019.pdf'
    }, {
        'NRM Region Name': 'Glenelg Hopkins',
        'State': 'VIC',
        'Plan Name': 'Glenelg Hopkins Regional Catchment Strategy',
        'Plan Status': 'In development',
        'URL': 'http://info.ghcma.vic.gov.au/wp-content/uploads/2017/04/11259_GHCMA_RSC_WEB.pdf'
    }, {
        'NRM Region Name': 'Goulburn Broken',
        'State': 'VIC',
        'Plan Name': 'Golburn Broken Regional Catchment Strategy 2013-2019',
        'Plan Status': 'In development',
        'URL': 'https://www.gbcma.vic.gov.au/downloads/RegionalCatchmentStrategy/GBCMA_RCS_2013-19.pdf'
    }, {
        'NRM Region Name': 'Mallee',
        'State': 'VIC',
        'Plan Name': 'Mallee Regional Catchment Strategy 2013-2019',
        'Plan Status': 'Due for update',
        'URL': 'https://mk0malleecmacomvmcpd.kinstacdn.com/wp-content/uploads/2019/07/MCMA-RCS-13-to-19.pdf'
    }, {
        'NRM Region Name': 'North Central',
        'State': 'VIC',
        'Plan Name': 'North Central Regional Catchment Strategy 2013-2019',
        'Plan Status': 'In development',
        'URL': 'http://www.nccma.vic.gov.au/sites/default/files/publications/nccma-78628_north_central_cma_rcs_-_may_2013_web_0.pdf'
    }, {
        'NRM Region Name': 'North East',
        'State': 'VIC',
        'Plan Name': 'North East Regional Catchment Strategy 2013-2019',
        'Plan Status': 'In development',
        'URL': 'http://www.nccma.vic.gov.au/sites/default/files/publications/nccma-78628_north_central_cma_rcs_-_may_2013_web_0.pdf'
    }, {
        'NRM Region Name': 'Port Phillip and Western Port',
        'State': 'VIC',
        'Plan Name': 'The Regional Catchment Strategy for the Port Phillip & Western Port Region',
        'Plan Status': 'In development',
        'URL': 'https://www.ppwrcs.vic.gov.au/about/regional-overview/'
    }, {
        'NRM Region Name': 'West Gippsland',
        'State': 'VIC',
        'Plan Name': 'West Gippsland Regional Catchment Strategy',
        'Plan Status': 'In development',
        'URL': 'https://www.wgcma.vic.gov.au/wp-content/uploads/2015/01/RCSpart1.pdf'
    }, {
        'NRM Region Name': 'Wimmera',
        'State': 'VIC',
        'Plan Name': 'Wimmera Regional Catchment Strategy 2013-2019',
        'Plan Status': 'Due for update',
        'URL': 'https://wcma.vic.gov.au/docs/default-source/corporatedocs/RCS/wimmera-regional-catchment-strategy-(rcs)-2013-19.pdf'
    }, {
        'NRM Region Name': 'Wheatbelt NRM',
        'State': 'WA',
        'Plan Name': 'Wheatbelt NRM 3 Year Plan 2018-2021',
        'Plan Status': 'Current',
        'URL': 'http://wheatbeltnrm.org.au/sites/default/files/basic_page/files/3%20Year%20Plan%202018-2021%20-%20Final%20Version.pdf'
    }, {
        'NRM Region Name': 'Northern Agricultural Catchment Council',
        'State': 'WA',
        'Plan Name': 'Northern Agricultural Regional Vision (NARvis)',
        'Plan Status': 'Current',
        'URL': 'https://www.narvis.com.au/'
    }, {
        'NRM Region Name': 'Peel-Harvey Catchment Council',
        'State': 'WA',
        'Plan Name': 'Strategic Directions 2019-2026',
        'Plan Status': 'Current',
        'URL': 'https://peel-harvey.org.au/wp-content/uploads/2019/08/SD_website.pdf'
    }, {
        'NRM Region Name': 'Rangelands NRM',
        'State': 'WA',
        'Plan Name': 'Rangelands NRM Regional Plan',
        'Plan Status': 'In development',
        'URL': 'Website broken: https://rangelandswa.com.au/publications/regional-plan/'
    }, {
        'NRM Region Name': 'South Coast NRM',
        'State': 'WA',
        'Plan Name': 'Southern Prospects 2019-2024',
        'Plan Status': 'Current',
        'URL': 'https://southcoastnrm.com.au/wp-content/uploads/2018/11/SCNRM_Prospects2019-2024_web_Pages.pdf'
    }, {
        'NRM Region Name': 'South West Catchments Council',
        'State': 'WA',
        'Plan Name': 'South West Regional Natural Resource Management (NRM) Strategy 2012–20',
        'Plan Status': 'In development',
        'URL': 'https://strategy.swccnrm.org.au/'
    }, {
        'NRM Region Name': 'Perth NRM',
        'State': 'WA',
        'Plan Name': 'Swan Region Strategy 2015-2020',
        'Plan Status': 'In development',
        'URL': 'https://www.perthnrm.com/nrmwp/wp-content/uploads/2020/08/Swan-Region-Strategy-for-NRM.pdf'
    }]


export const activities_list = [
    { // Void - v3
        id: 0,
        "cer_id": "5de03f77-213c-ec11-8c62-0022481108a0",
        selected: false,
        display: 'Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency (for example, adding recycled organics)',
    },
    {// Void - v3
        id: 1,
        "cer_id": "6949251b-223c-ec11-8c62-0022481108a0",
        selected: false,
        display: 'Re-establishing or rejuvenating a pasture by seeding (for example, mixed species forage seeding)'
    },
    {// Void - v3
        id: 2,
        "cer_id": "bc230d36-223c-ec11-8c62-0022481108a0",
        selected: false,
        display: 'Establishing, and permanently maintaining, a pasture where there was previously no pasture, such as on cropland or bare fallow (for example, converting cropland to pasture)'
    },
    {// Void - v3
        id: 3,
        "cer_id": "b295ce4b-223c-ec11-8c62-0022481108a0",
        selected: false,
        display: 'Altering the stocking rate, duration or intensity of grazing (for example, "rotational grazing" or "emergent leaf grazing")'
    },

    {
        id: 4,
        "cer_id": "5de03f77-213c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency",
    }, {
        id: 5,
        "cer_id": "c72d2c8d-213c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Applying lime or other ameliorants to remediate acid soils"
    }, {
        id: 6,
        "cer_id": "a8d043b8-213c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Applying gypsum to remediate sodic or magnesic soils"
    }, {
        id: 7,
        "cer_id": "6d5c4207-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Undertaking new irrigation"
    }, {
        id: 8,
        "cer_id": "6949251b-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Re-establishing or rejuvenating a pasture by seeding or pasture cropping"
    }, {
        id: 9,
        "cer_id": "bc230d36-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Establishing, and permanently maintaining, a pasture where there was previously no or limited pasture, such as on cropland or fallow"
    }, {
        id: 10,
        "cer_id": "b295ce4b-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Altering the stocking rate, duration or intensity of grazing to promote soil vegetation cover or improve soil health, or both"
    }, {
        id: 11,
        "cer_id": "ea948983-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Retaining stubble after a crop is harvested"
    }, {
        id: 12,
        "cer_id": "abee629f-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Converting from intensive tillage practices to reduced or no tillage practices"
    }, {
        id: 13,
        "cer_id": "059d95b1-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Modifying landscape or landform features to remediate land"
    }, {
        id: 14,
        "cer_id": "e1a90dd1-223c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Using mechanical means to add or redistribute soil through the soil profile"
    }, {
        id: 15,
        "cer_id": "e004384a-233c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Using legume species in cropping or pasture systems"
    }, {
        id: 16,
        "cer_id": "0f2f6963-233c-ec11-8c62-0022481108a0",
        selected: false,
        "name": "Using a cover crop to promote soil vegetation cover or improve soil health, or both"
    }
]

// export const activities_list = [
//     {
//         "id": "134d7432-c712-e811-80ff-005056910e64",
//         "name": "Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency"
//     }, {
//         "id": "154d7432-c712-e811-80ff-005056910e64",
//         "name": "Applying lime to remediate acid soils"
//     }, {
//         "id": "174d7432-c712-e811-80ff-005056910e64",
//         "name": "Applying gypsum to remediate sodic or magnesic soils"
//     }, {
//         "id": "194d7432-c712-e811-80ff-005056910e64",
//         "name": "Installing new irrigation with water sourced from privately-funded farm water efficiency savings made after the start of the project"
//     }, {
//         "id": "1b4d7432-c712-e811-80ff-005056910e64",
//         "name": "Re-establishing or rejuvenating a pasture by seeding"
//     }, {
//         "id": "1d4d7432-c712-e811-80ff-005056910e64",
//         "name": "Establishing, and permanently maintaining, a pasture where there was previously no pasture, such as on cropland or bare fallow"
//     }, {
//         "id": "1f4d7432-c712-e811-80ff-005056910e64",
//         "name": "Altering the stocking rate, duration or intensity of grazing"
//     }, {
//         "id": "214d7432-c712-e811-80ff-005056910e64",
//         "name": "Retaining stubble after a crop is harvested"
//     }, {
//         "id": "234d7432-c712-e811-80ff-005056910e64",
//         "name": "Converting from intensive tillage practices to reduced or no tillage practices"
//     }, {
//         "id": "254d7432-c712-e811-80ff-005056910e64",
//         "name": "Modifying landscape or landform features to remediate land"
//     }, {
//         "id": "274d7432-c712-e811-80ff-005056910e64",
//         "name": "Using mechanical means to add or redistribute soil through the soil profile"
//     }]
