<template>
  <PropertiesList/>
</template>

<script>

import PropertiesList from "@/components/Properties/PropertiesList";

export default {
  name: "PropertiesView",
  components: {PropertiesList},
  methods: {

  }
}


</script>
