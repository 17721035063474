<template>
  <v-container>
    <CreateAccountComponent v-if="!$store.state.auth0_does_owner_exist"></CreateAccountComponent>

    <v-alert
        v-if="$store.state.auth0_does_owner_exist"
        color="red"
        dense
        icon="mdi-alert"
        text
        type="warning"
    >
      You already have an account. Please navigate away from this page
    </v-alert>

  </v-container>

</template>

<script>

import CreateAccountComponent from "../components/CeateAccount/CreateAccountComponent";

export default {
  name: "CreateAccountView",
  components: {CreateAccountComponent},
  data() {
    return {
    }
  },
  methods: {},
  mounted() {
  },
  updated() {
    if (this.$store.state.auth0_does_owner_exist){
      this.$router.push({path: 'map'})
    }
  }
}
</script>

<style scoped>

</style>
