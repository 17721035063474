<template>

  <UserProfile/>
</template>


<script>

import UserProfile from "@/components/UserProfile/UserProfile.vue";

export default {
  name: "SettingsView",
  components: {UserProfile},
  methods: {

  }
}
</script>

<style scoped>

</style>
