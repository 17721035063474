<template>

  <AgentAdminComponent v-if="$store.state.isAgentAdmin"></AgentAdminComponent>
  <v-container v-else>
    <v-alert
        color="red"
        dense
        icon="mdi-alert"
        text
        type="warning"
    >
      You do not have access to this section.
    </v-alert>

  </v-container>


</template>

<script>
import AgentAdminComponent from "../components/AgentAdmin/AgentAdminComponent";

export default {
  name: "AgentAdminView",
  components: {AgentAdminComponent}
}
</script>

<style scoped>

</style>
