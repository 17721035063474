<template>
  <div class="text-center">
    <v-dialog
        v-model="showEditProfileDialog"
        width="500"
    >

      <v-card>
        <v-card-title class="headline">
          Edit Profile
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="owner_name"
                      label="Name*"
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="business_name"
                      label="Business"
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="phone_number"
                      label="Phone Number"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <small>*indicates required field</small>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click.stop="showEditProfileDialog=false">Close</v-btn>
          <v-btn color="primary" text @click.stop="onFormSubmit">Update</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import {editOwner} from '@/services/mfk_api'
import _clone from 'lodash/clone'

export default {
  name: "EditUserProfileDialog",
  props: {value: Boolean, dataObj: Object},
  components: {},
  data() {
    return {
      owner_name: null,
      business_name: null,
      phone_number: null,
    }
  },
  methods: {

    async onFormSubmit() {

      const resp = await editOwner({
            business: this.business_name,
            email: this.$store.state.userDict.email_address,
            name: this.owner_name,
            phone: this.phone_number,
          })

      console.log('resp await', resp)
      this.showEditProfileDialog = false

    }
  },

  computed: {
    showEditProfileDialog: {
      get() {
        console.log(this.dataObj)
        console.log(typeof this.dataObj)
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      console.log(this.$store.state.userDict)
      this.owner_name = _clone(this.$store.state.userDict.owner_name)
      this.business_name = _clone(this.$store.state.userDict.business_name)
      this.phone_number = _clone(this.$store.state.userDict.phone_number)
    })

  }
}
</script>

<style scoped>

</style>

