<template>

  <v-app id="app">

    <Navbar/>
    <v-main>
      <router-view></router-view>

      <v-snackbar
          v-model="$store.state.snackbar.show"
          :color="$store.state.snackbar.color"
          :top="true"
          :right="true"
      >
        {{ $store.state.snackbar.text }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="black"
              text
              v-bind="attrs"
              @click="$store.state.snackbar.show = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
          v-model="$store.state.snackbarRefershPage.show"
          color="green"
          multi-line
          vertical
          top
          right
          :timeout="1000000"
      >
        <p style="color: black;">
          You are currently using version {{ $store.state.version }}<br>
          A new version {{ server_version }} is available.<br>
          Press the Refresh button or use Ctrl+F5 to load it.
        </p>


        <template v-slot:action="{ attrs }">
          <v-btn
              color="black"
              dark
              text
              v-bind="attrs"
              @click="$store.state.snackbarRefershPage.show = false"
          >
            Close
          </v-btn>
          <v-btn
              color="purple"
              dark
              v-bind="attrs"
              @click="doRefreshPage"
          >
            Refresh
          </v-btn>
        </template>
      </v-snackbar>

    </v-main>

  </v-app>
</template>

<script>

import Navbar from "@/components/Navbar";

import {get_version} from "@/services/cibo_api";
import {doesOwnerExist, getAllDataForUser} from "@/services/mfk_api";
import store from "./store";

export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {
    return {
      server_version: null,
      // in_maintenance_mode: false,
    }
  },
  methods: {

    async check_version() {
      const server_version = await get_version()
      console.log('VERSION', server_version)
      if (server_version !== this.$store.state.version) {
        console.log('Not current version')
        this.server_version = server_version
        this.$store.state.snackbarRefershPage.show = true
      } else {
        console.log('current version')
      }

    },
    doRefreshPage() {
      window.location.reload(true)
    },

  },
  mounted() {
    setTimeout(() => {
      this.check_version()
    }, 2000)


  },
  updated() {

    // if (this.in_maintenance_mode) {
    //   this.$router.push({path: 'maintenance'})
    // } else {
    doesOwnerExist(this.$auth.user.email)

    if (process.env.NODE_ENV === 'production') {
        store.state.stage = 'prod'
    }

    if (this.$auth.isAuthenticated && !this.$store.state.initAllLoaded && !this.$store.state.auth0_does_owner_exist) {
      getAllDataForUser(this.$auth.user.email)
    }
    // }


  },

};
</script>

