<template>

  <div>
    <v-progress-linear v-if="!$store.state.initAllLoaded" indeterminate color="orange"></v-progress-linear>

    <v-container fluid v-if="$store.state.initAllLoaded">

      <v-card v-if="!pic_form.in_edit_mode">
        <v-card-title>
          Farms
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="propertyItemsList"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="property_id"
            show-expand
            :search="search"
            class="elevation-1"
            loading="property_list_is_loading"
            loading-text="Loading Farm data ... Please wait"
        >

          <template v-slot:item.lpa_verified="{ item }">
            <v-icon v-if="item.lpa_verified" left color="green">mdi-check-circle</v-icon>
          </template>

          <template v-slot:expanded-item="{ headers, item }">

            <td :colspan="headers.length">

              <v-card style="margin: 0;">
                <v-card-text>
                  <v-col>
                    Farm id: {{ item.property_id }}
                  </v-col>
                </v-card-text>


                <v-card-actions>

                  <v-list>
                    <v-list-item
                        v-if="$store.state.properties[item.property_id].inf.is_aus_farmkey"
                        @click="propertyMenuActions('verifyWithLPA', item)">
                      <v-list-item-icon>
                        <v-icon color="black">mdi-bank-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Verify with LPA</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="propertyMenuActions('editProperty', item)">
                      <v-list-item-icon>
                        <v-icon color="blue">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Edit Farm</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="$store.state.properties[item.property_id].inf.is_aus_farmkey"
                        @click="propertyMenuActions('addPropertyToCart', item)">
                      <v-list-item-icon>
                        <v-icon color="orange">mdi-cart-arrow-down</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Add Farm Key to Cart</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="$store.state.properties[item.property_id].inf.is_aus_farmkey"
                        @click="propertyMenuActions('addCarbonReportToCart', item)">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-cart-arrow-down</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Add Carbon Report to Cart</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="propertyMenuActions('delete', item)">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Delete Farm</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                  </v-list>

                </v-card-actions>

              </v-card>


            </td>
          </template>
        </v-data-table>

      </v-card>

      <v-card v-if="pic_form.in_edit_mode" width="400">

        <v-card-title class="subtitle-2">
          Verify with LPA
        </v-card-title>

        <v-card-text>
          <v-col>
            <v-form>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    PIC Code: {{ pic_form.pic_code }}
                  </v-col>

                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="pic_form.LPA_user_id"
                        label="LPA User ID"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        type="password"
                        v-model="pic_form.LPA_password"
                        label="LPA Password"
                        required
                    ></v-text-field>
                  </v-col>


                </v-row>
              </v-container>
            </v-form>

            <small>*indicates required field</small>


          </v-col>
        </v-card-text>

        <v-divider></v-divider>
        <v-col v-if="pic_form.in_progress">
          <v-progress-linear
              indeterminate
              color="yellow darken-2"
          ></v-progress-linear>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text
                 :disabled="pic_form.in_progress"
                 @click="cancelVerifyWithLPA">Close
          </v-btn>
          <v-btn color="primary"
                 :disabled="pic_form.LPA_user_id === null && pic_form.LPA_password === null || pic_form.in_progress"
                 @click="submitVerifyWithLPA">Verify
          </v-btn>

        </v-card-actions>

      </v-card>

      <v-dialog
          v-model="deletePropertyDialog"
          width="500"
      >
        <v-card>
          <v-card-title>
            Delete Farm
          </v-card-title>

          <v-card-text>
            <b>Are you sure you want to delete this farm?</b><br>
            Please make sure this farm is not linked to Ceres Tag or other third-party accounts.
            Deleting a farm that is already linked to other services may cause problems accessing them.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deletePropertyDialog = false">
              Cancel
            </v-btn>
            <v-btn
                color="warning"
                @click="deleteProperty"
            >
              Permanently Delete Farm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

  </div>

</template>

<script>
import {addToShoppingCart, deleteProperty, verifyLPA} from "@/services/mfk_api";

export default {
  name: "PropertiesList",
  data() {
    return {

      // data table
      headers: [
        {text: 'Farm Name', value: 'property_name'},
        {text: 'PIC', value: 'pic_code'},
        {text: 'Gate Address', value: 'front_gate_address'},
        {text: 'is LPA verified', value: 'lpa_verified'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      singleExpand: true,
      search: '',
      propertyItemsList: [],
      property_list_is_loading: false,

      activePropertyItem: null,
      deletePropertyDialog: false,

      // end data table
      properties: {},
      property_actions: [
        {title: 'Verify with LPA', action: 'verifyWithLPA', icon: 'mdi-bank-check', color: 'black'},
        // {title: 'Add Owner Details', action: 'addOwnerDetails', icon: 'mdi-account-plus'},
        {title: 'Edit Farm', action: 'editProperty', icon: 'mdi-pencil', color: 'blue'},
        {title: 'Add Farm Key to Cart', action: 'addPropertyToCart', icon: 'mdi-cart-arrow-down', color: 'orange'},
        {
          title: 'Add Forest Carbon Report to Cart',
          action: 'addCarbonReportToCart',
          icon: 'mdi-cart-arrow-down',
          color: 'green'
        },
        {title: 'Delete', action: 'delete', icon: 'mdi-delete', color: 'red'},
      ],
      pic_form: {
        in_edit_mode: false,
        in_progress: false,
        pic_code: null,
        property_id: null,
        LPA_user_id: null,
        LPA_password: null,
      }
    }
  },
  methods: {
    propertyMenuActions(menu_item, item) {
      this.activePropertyItem = item
      console.log(menu_item)
      switch (menu_item) {
        case 'verifyWithLPA':
          this.startVerifyWithLPA(item)
          break;
          // case 'addOwnerDetails':
          //   break;
        case 'editProperty':
          this.$store.state.edit_property_map_actions = {
            property_id: item.property_id
          }
          this.$router.push({path: 'map'})
          break;
        case 'addPropertyToCart':
          addToShoppingCart(item.property_id, item.property_name, 'Property Key', {}, null)
          break;
        case 'addCarbonReportToCart':
          addToShoppingCart(item.property_id, item.property_name, 'Carbon Report', {}, null)
          break;
        case 'delete':
          this.deletePropertyDialog = true
          // this.deleteProperty(item.property_id)
          break;
        default:
          break;
      }
    },
    startVerifyWithLPA(item) {
      this.pic_form.in_edit_mode = true
      this.pic_form.in_progress = false
      this.pic_form.property_id = item.property_id
      this.pic_form.pic_code = item.pic_code
      this.pic_form.in_progress = false
      this.$forceUpdate()
    },
    cancelVerifyWithLPA() {
      this.pic_form.in_edit_mode = false
      this.pic_form.property_id = null
      this.pic_form.pic_code = null
      this.pic_form.in_progress = false
    },
    async submitVerifyWithLPA() {
      this.pic_form.in_progress = true
      await verifyLPA(this.pic_form.property_id, this.pic_form.pic_code, this.pic_form.LPA_user_id, this.pic_form.LPA_password)
      this.pic_form.in_edit_mode = false
      this.pic_form.property_id = null
      this.pic_form.pic_code = null
      this.pic_form.in_progress = false
      this.makePropertyList()
      this.$forceUpdate()
    },
    async deleteProperty() {
      const property_id = this.activePropertyItem.property_id
      this.deletePropertyDialog = false
      await deleteProperty(property_id)
      this.makePropertyList()
      this.$forceUpdate()

      this.activePropertyItem = null;
    },
    makePropertyList() {

      this.propertyItemsList = []
      for (let propId of Object.keys(this.$store.state.properties)) {
        this.propertyItemsList.push({
          property_id: propId,
          property_name: this.$store.state.properties[propId].inf.property_name,
          pic_code: this.$store.state.properties[propId].inf.pic_code,
          front_gate_address: this.$store.state.properties[propId].inf.front_gate_address,
          lpa_verified: this.$store.state.properties[propId].lpa_verified,
        })
      }
      this.propertyItemsList.sort((a, b) => a.property_name.localeCompare(b.property_name))

    },

  },
  mounted() {

    this.property_list_is_loading = true

    setTimeout(() => {
      let iid = setInterval(() => {
        if (this.$store.state.initAllLoaded) {
          this.makePropertyList()
          this.property_list_is_loading = false
          clearInterval(iid);
          this.$forceUpdate();
        }
      }, 500);
    }, 500)


  }
}
</script>

<style scoped>

</style>
