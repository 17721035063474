import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueCookies from 'vue-cookies';

Vue.use(Vuetify, {
    theme: {
        dark: true,
    }
});

Vue.use(VueCookies);


export default new Vuetify({
});
