<template>

  <v-container fluid>

    <v-col v-if="!submitToCER.isSelected">

      <v-col>
        <v-btn @click="refresh_projects">Refresh Projects</v-btn>
      </v-col>


      <v-card>
        <v-card-title>
          Soil Projects
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="$store.state.agriProve.admin.soilProjectsList"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="project_id"
            show-expand
            :search="search"
            class="elevation-1"
        >
          <template v-slot:expanded-item="{ headers, item }">


            <td :colspan="headers.length">

              <v-card style="margin: 0px;">
                <v-card-text>
                  <v-col>
                    Property id: {{ item.property_id }}
                  </v-col>
                  <v-col v-if="'title_docs' in $store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes">
                    Title Docs: {{ $store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes.title_docs.length }}
                  </v-col>

                  <v-col v-if="item.project_status === 'C4'">
                    <v-alert type="success" color="green" icon="mdi-information" dense text>
                      CER API id: {{ item.project_attributes.CER_API_logs.at(-1).post_cer_id }}
                    </v-alert>
                    <pre>{{ item.project_attributes.CER_API_logs.at(-1).response }}</pre>
                  </v-col>
                  <v-col v-if="item.project_status === 'C3'">
                    <v-alert type="warning" color="orange" icon="mdi-alert" dense text>
                      Error: {{ item.project_attributes.CER_API_logs.at(-1).response }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="item.project_status === 'B1'">
                    <v-alert type="info" color="purple" icon="mdi-information-variant" dense text>
                      Not Submitted
                    </v-alert>
                  </v-col>

                </v-card-text>

                <v-card-actions>

                  <v-btn @click="setRenameProject(item)" small dark class="primary">
                    Rename Project
                  </v-btn>

                  <v-btn
                      v-if="'title_docs' in $store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes"
                      @click="setViewTitleDocs(item)" small dark class="primary">View Title Docs
                  </v-btn>
                  <v-btn @click="setUploadTitleDocs(item)" small dark class="primary">Upload Title Docs</v-btn>

                  <v-btn @click="saveCSV(item.project_id)" small dark class="primary">
                    Download CSV
                  </v-btn>

<!--                  v-if="'title_docs' in $store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes"-->
                  <v-btn
                      @click="selectSubmitToCER(item.project_id)" small dark color="primary">
                    Submit to CER
                  </v-btn>


                </v-card-actions>

              </v-card>


            </td>
          </template>
        </v-data-table>

        <v-col style="color: darkgreen;">
          <h3>Project Status Codes</h3>
          <p>
            A1 - project form started and saved, but not signed<br>
            A2 - project form submitted.<br>
            A3 - Creating commitment PDF<br>
            A4 - Commitment PDF is ready to download<br>
            A5 - Signed PDF uploaded by client<br>
            A6 - Creating condition report PDF<br>
            A7 - Condition report PDF is ready<br>
            <br>
            B1 - project not approved - sent to client to change<br>
            B2 - project not approved - sent to CiboLabs to change<br>
            B3 - project approved by AgriProve<br>
            <br>
            C1 - sent to MyFarmKey backend for CER submission<br>
            C2 - In process by MyFarmKey backend<br>
            C3 - not successful - Error returned<br>
            C4 - CER submission successful - waiting for CER approval<br>
            C5 - CER not approved<br>
            C6 - CER approved<br>
          </p>
        </v-col>


      </v-card>

    </v-col>


    <v-card v-else :loading="!submitToCER.isLoaded">
      <v-card-text>

        <v-subheader>Authorizing Person</v-subheader>
        <v-card style="max-width: 500px;">
          <v-card-text>

            <v-select
                :items="delegation_authority_list"
                v-model="delegation_authority_person"
                item-text="name"
                item-value="name"
                label="Select AgriProve Authorizer"
                v-on:change="onChangeDelegationAuthorityPerson"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-subheader>Project Info</v-subheader>
        <v-card style="max-width: 800px;">
          <v-card-text>

            <v-text-field
                v-model="submitToCER.apiData.name"
                label="Project Name"
                required
            ></v-text-field>

            <v-text-field
                v-model="submitToCER.apiData.description"
                label="Project Description"
                required
            ></v-text-field>
          </v-card-text>

        </v-card>

        <v-subheader>NRM PLan</v-subheader>
        <v-card style="max-width: 800px;">
          <v-card-text>

            <v-select
                :items="nrm_plans_list"
                v-model="selected_nrm_plan_name"
                item-text="Plan Name"
                item-value="Plan Name"
                label="Select NRM Plan"
                v-on:change="onChangeNrmPlan"
            ></v-select>


            <v-text-field
                v-model="submitToCER.apiData.nrm_plans[0].published_date"
                label="NRM Plan Published Date"
                required
            ></v-text-field>

            <!--            <v-text-field-->
            <!--                v-model="submitToCER.apiData.nrm_plans[0].plan_name"-->
            <!--                label="NRM Plan Name"-->
            <!--                required-->
            <!--            ></v-text-field>-->

            <v-text-field
                v-model="submitToCER.apiData.nrm_plans[0].organisation_name"
                label="NRM Organization Name"
                required
            ></v-text-field>


          </v-card-text>
        </v-card>

        <v-subheader>Location</v-subheader>
        <v-card style="max-width: 800px;">
          <v-card-text>

            <v-alert
                color="green"
                dense
                icon="mdi-home"
                text
                type="success"
            >
              {{ submitToCER.frontGateAddress }}<br>
              {{ submitToCER.typedFrontGateAdderss }}
            </v-alert>

            <v-text-field
                @change="setLocationParams()"
                v-model="submitToCER.addressComponents.owner_name"
                label="Owner Name"
                required
            ></v-text-field>

            <v-text-field
                v-model="submitToCER.typedFrontGateAdderss"
                label="Front Gate Address"
                required
            ></v-text-field>


            <v-text-field
                @change="setLocationParams()"
                v-model="submitToCER.addressComponents.road_number"
                label="Road Number"
                required
            ></v-text-field>

            <v-text-field
                @change="setLocationParams()"
                v-model="submitToCER.addressComponents.road_name"
                label="Road Name"
                required
            ></v-text-field>

            <v-text-field
                @change="setLocationParams()"
                v-model="submitToCER.addressComponents.suburb"
                label="Suburb"
                required
            ></v-text-field>

<!--            <v-text-field-->
<!--                @change="setLocationParams()"-->
<!--                v-model="submitToCER.addressComponents.state"-->
<!--                label="State"-->
<!--                required-->
<!--            ></v-text-field>-->

            <v-select
                @change="setLocationParams()"
                :items="['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']"
                v-model="submitToCER.addressComponents.state"
                label="State"
            ></v-select>

            <v-text-field
                @change="setLocationParams()"
                v-model="submitToCER.addressComponents.local_government_area"
                label="Local Government Area"
                required
            ></v-text-field>


          </v-card-text>
        </v-card>

        <v-subheader>Location</v-subheader>
        <v-card style="max-width: 800px;">
          <v-card-text>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Lot
                  </th>
                  <th class="text-left">
                    Volume
                  </th>
                  <th class="text-left">
                    Folio
                  </th>
                  <th class="text-left">
                    Plan Number
                  </th>
                  <th class="text-left">
                    Plan Type
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in submitToCER.apiData.locations"
                    :key="index"
                >

                  <td>
                    <v-text-field
                        v-model="item.lot_number"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="item.volume"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="item.folio"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="item.plan_number"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="item.plan_type"
                    ></v-text-field>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p></p>
            <!--            <v-text-field-->
            <!--                @change="setLocationParams('owner_type')"-->
            <!--                v-model="submitToCER.apiData.locations[submitToCER.locationIndex - 1].owner_type"-->
            <!--                label="Owner Type"-->
            <!--                required-->
            <!--            ></v-text-field>-->

            <!--            <v-text-field-->
            <!--                @change="setLocationParams('local_government_area')"-->
            <!--                v-model="submitToCER.apiData.locations[submitToCER.locationIndex - 1].local_government_area"-->
            <!--                label="Local Government Area"-->
            <!--                required-->
            <!--            ></v-text-field>-->

            <!--            <v-checkbox style="padding-top: 0; padding-bottom: 0; "-->
            <!--                        label="Other Projects at Site"-->
            <!--                        @change="setLocationParams('other_projects_at_site')"-->
            <!--                        v-model="submitToCER.apiData.locations[submitToCER.locationIndex - 1].other_projects_at_site"-->
            <!--            ></v-checkbox>-->

          </v-card-text>
        </v-card>

        <v-subheader>Other Parameters</v-subheader>
        <v-card style="max-width: 800px;">
          <v-card-text>


            <!--            <v-checkbox style="padding-top: 0; padding-bottom: 0; "-->
            <!--                        label="Carbon Estimation Areas in Project Area"-->
            <!--                        v-model="submitToCER.apiData.carbon_estimation_areas_in_project_area"-->
            <!--            ></v-checkbox>-->

            <!--            <v-checkbox style="padding-top: 0; padding-bottom: 0; "-->
            <!--                        label="Land Management Strategy Declare"-->
            <!--                        v-model="submitToCER.apiData.land_management_strategy_declare"-->
            <!--            ></v-checkbox>-->

            <!--            <v-checkbox style="padding-top: 0; padding-bottom: 0; "-->
            <!--                        label="Native Title Determination Declare"-->
            <!--                        v-model="submitToCER.apiData.native_title_determination_declare"-->
            <!--            ></v-checkbox>-->

            <v-text-field
                v-model="submitToCER.apiData.forward_abatement_estimate"
                label="Forward Abatement Estimate"
                type="number"
                required
            ></v-text-field>

          </v-card-text>
        </v-card>

        <v-subheader>Activities</v-subheader>
        <v-card>
          <v-card-text>
            <v-list flat>
              <v-list-item
                  v-for="activity in selectedActivityNames" :key="activity"
              >
                <v-list-item-icon>
                  <v-icon>mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="activity"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-col v-show="submitToCER.hasError">
          <h3 style="color: red;">Errors</h3>
          <p style="color: red;">Please fix the following errors and press the submit button again</p>
          <ul style="color: red;">
            <li v-for="e in submitToCER.error_list" :key="e">
              {{ e }}
            </li>
          </ul>
          <br>
        </v-col>

        <v-col>
          <v-btn @click="show_in_json=!show_in_json" small>Show/Hide JSON</v-btn>
        </v-col>


        <v-col v-if="show_in_json">
          <pre>{{ submitToCER.apiData }}</pre>
        </v-col>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>

        <v-btn @click="doSubmitToCER">
          Submit
        </v-btn>
        <v-btn @click="cancelSubmitToCER">
          Cancel
        </v-btn>

      </v-card-actions>

    </v-card>


    <v-dialog
        v-model="view_title_docs_dialog.showDialog"
        width="500"
    >

      <v-card
          :loading="view_title_docs_dialog.loading"
      >
        <v-card-title>
          Title Documents
        </v-card-title>

        <v-card-text>
          <p v-for="file_item of view_title_docs_dialog.project_attributes.title_docs"
             :key="file_item.file_name">
            {{ file_item.file_name }}
          </p>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="view_title_docs_dialog.loading"
              @click="cancelViewTitleDocs">
            Cancel
          </v-btn>

        </v-card-actions>


      </v-card>


    </v-dialog>


    <v-dialog
        v-model="uploadTitleFiles.showDialog"
        width="500"
    >
      <v-card
          :loading="uploadTitleFiles.loading"
      >
        <v-card-title>
          Upload Title Documents
        </v-card-title>

        <v-card-text>
          <v-file-input
              dense
              outlined
              multiple
              show-size
              :clearable="true"
              color="primary"
              accept=".pdf"
              label="Select Title Documents (PDF)"
              v-model="uploadTitleFiles.title_doc_files"
          ></v-file-input>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="uploadTitleFiles.loading"
              @click="cancelUploadTitleDocs">
            Cancel
          </v-btn>
          <v-btn
              :disabled="uploadTitleFiles.loading || uploadTitleFiles.title_doc_files === null"
              color="primary"
              @click="do_upload_title_docs">
            Upload Title Documents
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>


    <v-dialog
        v-model="renameProjectForm.show_dialog"
        width="500"
    >
      <v-card
          :loading="renameProjectForm.loading"
      >
        <v-card-title>
          Rename Project
        </v-card-title>

        <v-card-text>
          <v-text-field
              v-model="renameProjectForm.project_name"
              :disabled="renameProjectForm.loading"
              label="Project Name"
              required
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="renameProjectForm.loading"
              @click="clearRenameProject">
            Cancel
          </v-btn>
          <v-btn
              :disabled="renameProjectForm.loading"
              color="primary"
              @click="renameProject"
          >
            Rename Project
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>


</template>

<script>

import {
  getPresignedUrls, renameAgriProveProject,
  getAgriProveSoilProjects,
  getUploadUrls,
  updateAgriproveProjectProperties,
} from '@/services/mfk_api'
import {nrm_plans_list, activities_list} from "@/assets/agriprove/nrm_plans";
import FileSaver from 'file-saver';
import _cloneDeep from 'lodash/cloneDeep';
import {agriprove_update_project_status_and_attributes, getOwnerInfo} from "../../services/mfk_api";

export default {
  name: "SoilProjectsAdmin",
  data() {

    return {
      CER_API_STAGE: 'dev',
      CER_API_STAGE_dict: {
        'dev': {
          contact_id: "", // Kieren Whittock - User,
          agent_id: "", // Agriprove Pty Ltd - Organisation
          signatory_id: "", // Kieren Whittock - User
          delegation_authority_docs: {
            'Kate Carmichael': {'id': 2818, 'name': 'delegation-of-authority-Kate.pdf'},
            'Kieren Whittock': {'id': 2816, 'name': 'delegation-of-authority-Kieren.pdf'},
          },
          eligible_interest_holders_document_id: [2818, 2816],
          participants: [
            {
              "contact_id": "d9350bdc-1e29-eb11-8179-000d3a9705d4",
              "type": "Organisation"
            }],
          land_management_strategy_document_id: [2824],
          appropriate_skills_and_expertise_document_id: [2822],
        },

        'prod': {

          contact_id: "4f9ec474-a148-ea11-811c-005056911aef", // Kieren Whittock - User,
          agent_id: "fafba10d-5903-4e0a-9f4f-a575748425a7", // Agriprove Pty Ltd - Organisation
          signatory_id: "4f9ec474-a148-ea11-811c-005056911aef", // Kieren Whittock - User

          delegation_authority_docs: {
            'Kate Carmichael': {'id': 14, 'name': 'delegation-of-authority-Kate.pdf'},
            'Kieren Whittock': {'id': 16, 'name': 'delegation-of-authority-Kieren.pdf'},
          },
          eligible_interest_holders_document_id: [14, 16],
          participants: [
            {
              "contact_id": "c2dd9856-929b-e811-8101-005056925df8", // "AGRIPROVE SOLUTIONS PTY LTD"
              "type": "Participant"
            }],

          land_management_strategy_document_id: [20],
          appropriate_skills_and_expertise_document_id: [18],
        }

      },
      renameProjectForm: {
        show_dialog: false,
        loading: false,
        project_id: null,
        property_id: null,
        project_name: null,
      },
      uploadTitleFiles: {
        showDialog: false,
        loading: false,
        project_id: null,
        property_id: null,
        project_attributes: null,
        title_doc_files: null,
      },
      view_title_docs_dialog: {
        showDialog: false,
        loading: false,
        project_id: null,
        property_id: null,
        project_attributes: {},
      },
      show_in_json: false,
      headers: [
        {text: 'Project Id', value: 'project_id'},
        {text: 'Project Name', value: 'project_name'},
        {text: 'Property Name', value: 'property_name'},
        {text: 'Email', value: 'email_address'},
        {text: 'No of Cads', value: 'no_of_cads'},
        {text: 'Status', value: 'project_status'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      singleExpand: true,
      search: '',

      selected_nrm_plan_name: null,
      selected_nrm_plan_item: null,
      nrm_plans_list: nrm_plans_list,
      // activities_dict: {},

      // activities_agriprove: {
      //   0: "Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency",
      //   1: "Re-establishing or rejuvenating a pasture by seeding",
      //   2: "Establishing, and permanently maintaining, a pasture where there was previously no pasture, such as on cropland or bare fallow",
      //   3: "Altering the stocking rate, duration or intensity of grazing",
      // },

      activities_list: activities_list,


      delegation_authority_list: [
        {'name': 'Kieren Whittock'},
        {'name': 'Kate Carmichael'},
      ],
      delegation_authority_docs: null,
      delegation_authority_person: 'Kieren Whittock',
      selectedActivityNames: [],
      projectData: null,
      submitToCER: {

        hasError: false,
        error_list: [],
        isSelected: false,
        isLoaded: true,
        auth_person: null,
        agent_person: null,
        signatory_person: null,
        locationIndex: 1,
        copyLocChange: false,

        frontGateAddress: '',
        typedFrontGateAdderss: '',
        addressComponents: {
          owner_name: null,
          road_number: null,
          road_name: null,
          suburb: null,
          state: null,
          local_government_area: null,
        },
        project_id: null,
        client_email: null,
        urls: {
          'commitment_report': null,
          'condition_report': null,
        },
        apiData: {},
        apiDataTemplate: {
          "name": null,
          "description": null,

          "contact_id": null,
          "agent_id": null,
          "signatory_id": null,

          "nrm_plans": [
            {
              "published_date": "2016-01-01",
              "plan_name": null,
              "organisation_name": null,
            }
          ],
          "nrm_declare": true,
          "eligible_interest_holders_document_id": null,

          "participants": null,
          "locations": [  // HOW TO separate fields? To start, ask user to enter data, later can auto-populate with google api
            // {
            //   "road_name": null,
            //   "suburb": null,
            //   "road_number": null,
            //   "plan_type": null,
            //   "plan_number": null,
            //   "owner_name": null,
            //   "owner_document_id": [],
            //   "nrm_plan_name": null,
            //   "state": null,
            //   "owner_type": "TheLandownerHasGivenMeTheLegalRightToRunTheProject",
            //   "lot_number": null,
            //   "local_government_area": null,
            //   "other_projects_at_site": false,
            // }
          ],
          "geospatial_document_id": [], // kml for farm key - upload and get key at API

          "authority_to_sign_document_id": [], // to get CER id of other staff that have access from Kieren  # required  ??? Kieren, Brendan or Kate (optional)
          // "carbon_estimation_areas_in_project_area": false,  // removed in v3 required     Carbon estimation areas (CEAs) have been identified within the project area.
          "land_management_strategy_declare": true,  // Confirm the Land management strategy meets the requirements of the method, in line with the confirmation checkboxes displayed in the Client Portal.
          "land_management_strategy_document_id": null, // PDF version of Agriprove startegy - to get it from Agriprove & CONDITION REPORT  # OPTIONAL ???? is this condition report?

          "evidence_of_legal_right_document_id": [],  // TO SET IN API - client consent form (upload, get id and
          "native_title_determination_declare": false,

          // "special_project_areas": [  // # OPTIONAL   # TESTING // removed in v3
          //   "Forest land, or contained forest at any time during the baseline period",
          //   "Building, including dwelling, roads and structures"
          // ],
          "inclusion_of_ineligible_land_in_cea_declare": true, // # OPTIONAL # TESTING
          "special_land_usage": [  // # TESTING
            "Pasture",
            "Bare Fallow"
          ],
          "permanence_period": "25 years",
          "forward_abatement_estimate": 500000,

          "lma_baseline_period": "Upload a separate document",  // consent to register document # required  ????
          "lma_baseline_period_document_id": [  // OPTIONAL

          ],
          // "agree_to_implement_lms": "Upload a separate document", // Removed in v3
          // "agree_to_implement_lms_document_id": [],
          "appropriate_skills_and_expertise": "Upload a separate document",
          "appropriate_skills_and_expertise_document_id": null,
          "activity_ids": [
            // "134d7432-c712-e811-80ff-005056910e64"
          ],
          "declare_activities_new": false,
          "agree_different_activities_in_lms": "This information is included in my Land Management Strategy",
          "legal_rights": [
            "Land with a registered, but not yet determined, claim for Native Title"
          ]

        },

      },

    }
  },
  methods: {


    async uploadFile(inputFile) {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve, reject) => {

        temporaryFileReader.onerror = () => {
          temporaryFileReader.abort();
          console.log(reject)
        };

        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsBinaryString(inputFile);
      });
    },

    setViewTitleDocs(item) {

      this.view_title_docs_dialog.showDialog = true
      this.view_title_docs_dialog.project_id = item.project_id
      this.view_title_docs_dialog.property_id = item.property_id
      this.view_title_docs_dialog.project_attributes = this.$store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes

    },

    cancelViewTitleDocs() {
      this.view_title_docs_dialog.loading = false
      this.view_title_docs_dialog.showDialog = false
      this.view_title_docs_dialog.project_id = null
      this.view_title_docs_dialog.property_id = null
      this.view_title_docs_dialog.project_attributes = {}
    },

    setUploadTitleDocs(item) {

      this.uploadTitleFiles.showDialog = true
      this.uploadTitleFiles.project_id = item.project_id
      this.uploadTitleFiles.property_id = item.property_id
      this.uploadTitleFiles.project_attributes = this.$store.state.agriProve.admin.soilProjects[item.project_id][0].project_attributes

      console.log(this.uploadTitleFiles.project_attributes)
    },
    cancelUploadTitleDocs() {
      this.uploadTitleFiles.loading = false
      this.uploadTitleFiles.showDialog = false
      this.uploadTitleFiles.title_doc_files = null
      this.uploadTitleFiles.project_id = null
      this.uploadTitleFiles.property_id = null
      this.uploadTitleFiles.project_attributes = null
    },
    async do_upload_title_docs() {
      this.uploadTitleFiles.loading = true
      const project_id = this.uploadTitleFiles.project_id;
      const property_id = this.uploadTitleFiles.property_id;

      let project_attributes = this.uploadTitleFiles.project_attributes;
      console.log(project_attributes)

      if (!('title_docs' in project_attributes)) {
        project_attributes.title_docs = []
      }

      console.log(this.uploadTitleFiles.title_doc_files)

      let bucket = 'agriprove-dev'
      if (this.$store.state.stage === 'prod') bucket = 'cibo-cer-applications'

      let bucket_key_pairs = []
      for (let file_item of this.uploadTitleFiles.title_doc_files) {
        let file_name = file_item.name
        let key_name = `${project_id}-${property_id}-title_doc-${file_name}`
        bucket_key_pairs.push({
          // bucket_name: 'cibo-temp-uploads',
          bucket_name: bucket,
          key_name: `${project_id}-${property_id}-title_doc-${file_name}`,
          file_type: file_item.type
        })

        project_attributes.title_docs.push({
          'file_name': file_name,
          'key_name': key_name
        })

      }

      const resp = await getUploadUrls(bucket_key_pairs)
      const upload_urls = resp.data

      let cnt = -1
      for (let upload_url of upload_urls) {
        cnt += 1

        console.log(upload_url)

        const response = await fetch(upload_url,
            {
              method: "PUT",
              headers: {'Content-Type': this.uploadTitleFiles.title_doc_files[cnt].type},
              body: this.uploadTitleFiles.title_doc_files[cnt]
            });
        console.log(response)

      }
      await updateAgriproveProjectProperties(project_id, property_id, project_attributes)
      this.cancelUploadTitleDocs()
      console.log(project_attributes)
      await getAgriProveSoilProjects()
      this.$forceUpdate()

    },

    async refresh_projects() {
      await getAgriProveSoilProjects()
      this.$forceUpdate()
    },


    async saveCSV(project_id) {
      console.log('this.$store.state.agriProve.admin.soilProjects')
      console.log(this.$store.state.agriProve.admin.soilProjects)
      let project_data = this.$store.state.agriProve.admin.soilProjects[project_id];
      let property_id = project_data[0].property_id
      let version = project_data[0].project_attributes.version
      console.log(project_data)

      const project_name = project_data[0].project_name
      const property_name = project_data[0].property_name

      let hectares_total = 0;
      project_data.forEach(d => {
        hectares_total += d.hectares;
      });

      let data = await getPresignedUrls(property_id, property_name, project_id, version)


      let csv =
          'Project Creation Date,MyFarmKey Property Owner,FAE TCO2E,' +
          'Front Gate Address,Typed Front Gate Address,Cadastre Hectares,' +
          'Property Hectares,Local Government Area,Lot Number,NRM Region,' +
          'Parcel Type,Plan Number,Plan Type,Project Name, Property Name,' +
          'State,NRM Plan,NRM Plan URL,Condition Report,Confirmation of Commitments,' +
          'Delegation of Authority - Kieren, Delegation of Authority - Brendan,' +
          'Delegation of Authority - Kate\n';
      project_data.forEach(d => {
        csv = csv.concat(
            `${d.creation_date.toString().substring(0, 10)},"${d.email_address}",` +
            `${d.fae_tco2e},"${d.front_gate_address}","${d.typed_front_gate_address}",` +
            `${d.hectares},${hectares_total},"${d.local_government_area}",` +
            `"${d.lot_number}","${d.nrm_region}","${d.parcel_type}","${d.plan_number}",` +
            `"${d.plan_type}","${d.project_name}","${d.property_name}","${d.state}",` +
            `"${d.nrm_plan}","${d.nrm_plan_url}",` +
            `"${data[1]}","${data[0]}","${data[3]}","${data[4]}","${data[2]}"\n`
        );
      });

      let blob = new Blob([csv], {type: "text/csv"});
      FileSaver.saveAs(blob,
          `${property_id}-${project_name}-soil-project-registration-data.csv`
      );
    },
    findNrmRecord(project_data) {
      let plan_name;
      for (let locIndex in project_data) {

        if (project_data[locIndex].nrm_plan !== null) {
          plan_name = project_data[locIndex].nrm_plan
        }

      }

      let nrm_plan = null
      for (let item of this.nrm_plans_list) {
        if (item['Plan Name'] === plan_name) {
          nrm_plan = item
        }
      }

      return nrm_plan

    },
    onChangeNrmPlan() {
      console.log(this.selected_nrm_plan_name)

      for (let item of this.nrm_plans_list) {
        if (item['Plan Name'] === this.selected_nrm_plan_name) {
          this.selected_nrm_plan_item = item
        }
      }

      // this.submitToCER.apiData.nrm_plans[0].published_date = "2016-01-01"
      this.submitToCER.apiData.nrm_plans[0].plan_name = this.selected_nrm_plan_item['Plan Name']
      this.submitToCER.apiData.nrm_plans[0].organisation_name = this.selected_nrm_plan_item['NRM Region Name']

      // set it for all locations
      for (let locIndex in this.submitToCER.apiData.locations) {
        this.submitToCER.apiData.locations[locIndex].nrm_plan_name = this.selected_nrm_plan_item['Plan Name']
      }


    },
    async resetCERform() {
      this.selected_nrm_plan_item = null
      this.selected_nrm_plan_item = null
      this.selectedActivityNames = []
      this.projectData = null

      this.submitToCER.isSelected = false
      this.submitToCER.auth_person = null
      this.submitToCER.agent_person = null
      this.submitToCER.signatory_person = null
      this.submitToCER.locationIndex = 1
      this.submitToCER.copyLocChange = false
      this.submitToCER.frontGateAddress = null
      this.submitToCER.typedFrontGateAdderss = ''

      this.submitToCER.hasError = false
      this.submitToCER.error_list = []

      this.submitToCER.addressComponents = {
        owner_name: null,
        road_number: null,
        road_name: null,
        suburb: null,
        state: null,
        local_government_area: null,
      }
      this.submitToCER.property_id = null
      this.submitToCER.client_email = null
      this.submitToCER.urls = {
        'commitment_report': null,
        'condition_report': null,
      }

      this.submitToCER.apiData = {}

      return true
    },
    async selectSubmitToCER(project_id) {

      await this.resetCERform()

      this.delegation_authority_docs = this.CER_API_STAGE_dict[this.CER_API_STAGE].delegation_authority_docs
      this.submitToCER.apiDataTemplate.eligible_interest_holders_document_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].eligible_interest_holders_document_id
      this.submitToCER.apiDataTemplate.participants = this.CER_API_STAGE_dict[this.CER_API_STAGE].participants
      this.submitToCER.apiDataTemplate.land_management_strategy_document_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].land_management_strategy_document_id
      this.submitToCER.apiDataTemplate.appropriate_skills_and_expertise_document_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].appropriate_skills_and_expertise_document_id


      this.submitToCER.project_id = project_id

      this.submitToCER.apiData = _cloneDeep(this.submitToCER.apiDataTemplate)

      let project_data = this.$store.state.agriProve.admin.soilProjects[project_id];
      let property_id = project_data[0].property_id
      this.submitToCER.client_email = project_data[0].email_address
      const project_name = project_data[0].project_name
      const property_name = project_data[0].property_name
      this.submitToCER.property_name = property_name
      let hectares_total = 0;
      project_data.forEach(d => {
        hectares_total += d.hectares;
      });
      console.log('999999999999999')
      console.log(project_data)
      console.log(hectares_total)
      let version = project_data[0].project_attributes.version
      const urlList = await getPresignedUrls(property_id, property_name, project_id, version)
      console.log(urlList)
      this.submitToCER.urls.commitment_report = urlList[0]
      this.submitToCER.urls.condition_report = urlList[1]
      this.submitToCER.isLoaded = true
      this.submitToCER.isSelected = true
      this.projectData = project_data

      this.submitToCER.apiData.name = `${project_name}`
      this.submitToCER.apiData.description = `Soil Registration for ${project_name}`

      this.submitToCER.apiData.contact_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].contact_id
      this.submitToCER.apiData.agent_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].agent_id
      this.submitToCER.apiData.signatory_id = this.CER_API_STAGE_dict[this.CER_API_STAGE].signatory_id

      this.selected_nrm_plan_item = this.findNrmRecord(project_data)
      if (this.selected_nrm_plan_item !== null) {
        this.selected_nrm_plan_name = this.selected_nrm_plan_item['Plan Name']
        this.submitToCER.apiData.nrm_plans[0].published_date = "2016-01-01"
        this.submitToCER.apiData.nrm_plans[0].plan_name = this.selected_nrm_plan_item['Plan Name']
        this.submitToCER.apiData.nrm_plans[0].organisation_name = this.selected_nrm_plan_item['NRM Region Name']
      }

      this.submitToCER.frontGateAddress = project_data[0].front_gate_address
      this.submitToCER.typedFrontGateAdderss = project_data[0].typed_front_gate_address


      const owner_info = await getOwnerInfo(this.submitToCER.client_email)

      this.submitToCER.addressComponents.owner_name = owner_info.owner_name
      for (let locIndex in project_data) {
        this.submitToCER.apiData.locations.push({})
        this.submitToCER.apiData.locations[locIndex].owner_name = owner_info.owner_name

        this.submitToCER.apiData.locations[locIndex].road_name = null
        this.submitToCER.apiData.locations[locIndex].suburb = null
        this.submitToCER.apiData.locations[locIndex].road_number = null
        this.submitToCER.apiData.locations[locIndex].plan_type = project_data[locIndex].plan_type
        this.submitToCER.apiData.locations[locIndex].plan_number = project_data[locIndex].plan_number


        this.submitToCER.apiData.locations[locIndex].volume = project_data[locIndex].volume
        this.submitToCER.apiData.locations[locIndex].folio = project_data[locIndex].folio

        this.submitToCER.apiData.locations[locIndex].owner_document_id = [this.delegation_authority_docs[this.delegation_authority_person]['id']] // TO make it dynamic

        this.submitToCER.apiData.locations[locIndex].state = project_data[locIndex].state
        this.submitToCER.addressComponents.state = project_data[locIndex].state
        this.submitToCER.apiData.locations[locIndex].lot_number = project_data[locIndex].lot_number
        this.submitToCER.apiData.locations[locIndex].local_government_area = project_data[locIndex].local_government_area
        this.submitToCER.addressComponents.local_government_area = project_data[locIndex].local_government_area
        this.submitToCER.apiData.locations[locIndex].other_projects_at_site = false

        if (this.selected_nrm_plan_item !== null) {
          this.submitToCER.apiData.locations[locIndex].nrm_plan_name = this.selected_nrm_plan_item['Plan Name']
        }

        this.submitToCER.apiData.locations[locIndex].owner_type = "TheLandownerHasGivenMeTheLegalRightToRunTheProject"


        if (this.submitToCER.addressComponents.state === 'TAS') {
          console.log('TAS ---------------------------')
          if (this.submitToCER.apiData.locations[locIndex].volume === undefined) {
            this.submitToCER.apiData.locations[locIndex].volume = this.submitToCER.apiData.locations[locIndex].plan_number
            this.submitToCER.apiData.locations[locIndex].plan_number = null
          }
          if (this.submitToCER.apiData.locations[locIndex].folio === undefined) {
            this.submitToCER.apiData.locations[locIndex].folio = this.submitToCER.apiData.locations[locIndex].lot_number
            this.submitToCER.apiData.locations[locIndex].lot_number = null
          }
        }


      }

      this.submitToCER.apiData.authority_to_sign_document_id = [this.delegation_authority_docs[this.delegation_authority_person]['id']] // TO make it dynamic
      // this.submitToCER.apiData.carbon_estimation_areas_in_project_area = false // removed in v3
      this.submitToCER.apiData.land_management_strategy_declare = true
      this.submitToCER.apiData.native_title_determination_declare = false

      this.submitToCER.apiData.forward_abatement_estimate = Math.floor(project_data[0].fae_tco2e)

      this.setActivityIds(project_data[0].chosen_actions)

    },
    setActivityIds(chosen_actions) {
      this.submitToCER.apiData.activity_ids = []
      this.selectedActivityNames = []
      for (let chosen_action of chosen_actions) {

        let act_item = activities_list[chosen_action]

        this.submitToCER.apiData.activity_ids.push(act_item.cer_id)
        let activity_name = act_item.name
        this.selectedActivityNames.push(activity_name)
        // for (let act_item of this.activities_list) {
        //   if (act_item.name === activity_name) {
        //     let act_id = act_item.id
        //     this.submitToCER.apiData.activity_ids.push(act_id)
        //   }
        // }

      }
    },
    setLocationParams() {
      for (let locIndex in this.submitToCER.apiData.locations) {
        this.submitToCER.apiData.locations[locIndex].owner_name = this.submitToCER.addressComponents.owner_name
        this.submitToCER.apiData.locations[locIndex].road_number = this.submitToCER.addressComponents.road_number
        this.submitToCER.apiData.locations[locIndex].road_name = this.submitToCER.addressComponents.road_name
        this.submitToCER.apiData.locations[locIndex].suburb = this.submitToCER.addressComponents.suburb
        this.submitToCER.apiData.locations[locIndex].state = this.submitToCER.addressComponents.state
        this.submitToCER.apiData.locations[locIndex].local_government_area = this.submitToCER.addressComponents.local_government_area
      }
      console.log(this.submitToCER.apiData.locations)
    },
    onChangeDelegationAuthorityPerson() {
      for (let locIndex in this.submitToCER.apiData.locations) {
        this.submitToCER.apiData.locations[locIndex].owner_document_id = [this.delegation_authority_docs[this.delegation_authority_person]['id']]
      }

      this.submitToCER.apiData.authority_to_sign_document_id = [this.delegation_authority_docs[this.delegation_authority_person]['id']]

      console.log(this.submitToCER.apiData)

    },

    async validateCERform() {

      this.submitToCER.hasError = false
      this.submitToCER.error_list = []

      if (this.submitToCER.typedFrontGateAdderss === null || this.submitToCER.typedFrontGateAdderss.length < 4) {
        this.submitToCER.hasError = true
        this.submitToCER.error_list.push('Front gate address is missing')
      }
      if (this.submitToCER.addressComponents.road_number === null || this.submitToCER.addressComponents.road_number.length < 1) {
        this.submitToCER.hasError = true
        this.submitToCER.error_list.push('Road number is missing')
      }
      if (this.submitToCER.addressComponents.road_name === null || this.submitToCER.addressComponents.road_name.length < 1) {
        this.submitToCER.hasError = true
        this.submitToCER.error_list.push('Road name is missing')
      }
      if (this.submitToCER.addressComponents.suburb === null || this.submitToCER.addressComponents.suburb.length < 1) {
        this.submitToCER.hasError = true
        this.submitToCER.error_list.push('Suburb name is missing')
      }

      const project_id = this.submitToCER.project_id
      let project_attributes = this.$store.state.agriProve.admin.soilProjects[project_id][0].project_attributes;
      if (!('title_docs' in project_attributes)) {
        this.submitToCER.hasError = true
        this.submitToCER.error_list.push('Project has no title document. Please upload title documents and return to this form.')
      }

    },

    async doSubmitToCER() {

      await this.validateCERform()
      this.$forceUpdate()

      if (!this.submitToCER.hasError) {
        const project_id = this.submitToCER.project_id
        // const property_id = this.submitToCER.property_id
        // const client_email = this.submitToCER.client_email

        let project_attributes = this.$store.state.agriProve.admin.soilProjects[project_id][0].project_attributes;
        console.log(project_id)
        console.log(this.$store.state.agriProve.admin.soilProjects[project_id])
        console.log(project_attributes)

        if (!('CER_API_logs' in project_attributes)) {
          project_attributes.CER_API_logs = []
        }

        project_attributes.CER_API_logs.push({
          'request': {
            stage: this.CER_API_STAGE,
            property_name: this.submitToCER.property_name,
            front_gate_address: this.submitToCER.typedFrontGateAdderss,
            form_data: this.submitToCER.apiData
          },
          'request_dt': new Date(),
          'response': null,
          'response_dt': null,
          'post_cer_id': null,

        })
        const project_status = 'C1'
        console.log(project_attributes)
        await agriprove_update_project_status_and_attributes(project_id, project_status, project_attributes)

        // const data = {
        //   stage: this.CER_API_STAGE,
        //   front_gate_address: this.submitToCER.typedFrontGateAdderss,
        //   urls: this.submitToCER.urls,
        //   form_data: this.submitToCER.apiData
        // }
        // console.log(property_id, client_email, data)


        // await c_agriprove_soil_project_task(property_id, client_email, data)
        await getAgriProveSoilProjects()
        this.cancelSubmitToCER()

        // await this.getSoilProjectTasks()
      }

    },
    cancelSubmitToCER() {
      this.submitToCER.isSelected = false
      this.resetCERform()
    },
    // async getSoilProjectTasks() {
    //   await r_agriprove_soil_project_task()
    //   this.$forceUpdate()
    // },
    // Rename Project
    setRenameProject(item) {
      this.renameProjectForm.show_dialog = true

      this.renameProjectForm.project_id = item.project_id
      this.renameProjectForm.property_id = item.property_id
      this.renameProjectForm.project_name = item.project_name
    },
    clearRenameProject() {
      console.log('in cancel rename')
      this.renameProjectForm.show_dialog = false
      this.renameProjectForm.loading = false
      this.renameProjectForm.project_id = null
      this.renameProjectForm.property_id = null
      this.renameProjectForm.project_name = null
      this.$forceUpdate()
    },
    async renameProject() {
      this.renameProjectForm.loading = true
      await renameAgriProveProject(
          this.renameProjectForm.project_id,
          this.renameProjectForm.property_id,
          this.renameProjectForm.project_name,
      )
      await getAgriProveSoilProjects()
      this.clearRenameProject()
    }

  },
  mounted() {
    // this.getSoilProjectTasks()

    if (this.$store.state.stage === 'prod') this.CER_API_STAGE = 'prod'

    // for (let act_item of activities_list){
    //   this.activities_dict[act_item.id] = act_item.name
    // }
  }

}
</script>

<style scoped>

</style>
