<template>
  <v-container>

    <v-btn class="primary" dark @click="onAddPlan" v-if="!addPlan.isActive">
        <v-icon left>mdi-plus</v-icon>
        Create New Plan
      </v-btn>

    <div v-show="$store.state.allPlans.length > 0">
      <v-col
          v-if="!addPlan.isActive"
          style="max-width: 400px;"
          class="d-flex"
          cols="12"
          sm="6"
      >
        <v-select

            :items="$store.state.allPlans"
            v-model="selectedPlanId"
            item-text="plan_name"
            item-value="plan_id"
            label="Select Plan"
            v-on:change="onChangePlan"
        ></v-select>
      </v-col>

      <v-card v-if="selectedPlan !== null && !addPlan.isActive" max-width="500">

        <v-card-text>

          <p>
            Plan Name: {{ selectedPlan.plan_name }}<br>
            Property Key Price: ${{ selectedPlan.property_key_price }}<br>
            Benchmark Report Price: ${{ selectedPlan.benchmark_report_price }}<br>
            Forest Carbon Report Price: ${{ selectedPlan.carbon_report_price }}<br>
          </p>

          <v-list dense>
            <v-subheader>Plan Owners</v-subheader>
            <v-list-item-group
                v-model="selectedItem1"
                color="primary"
            >
              <v-list-item
                  v-for="owner_email in selectedPlan.owner_emails"
                  :key="owner_email"
              >
                <v-list-item-icon>
                  <v-icon color="green">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="owner_email"></v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <!--                <v-btn icon>-->
                <!--                  <v-icon>mdi-delete</v-icon>-->
                <!--                </v-btn>-->
              </v-list-item>
            </v-list-item-group>

            <v-subheader>Authorized Clients</v-subheader>
            <v-list-item-group
                v-model="selectedItem2"
                color="primary"
            >
              <v-list-item
                  v-for="(authorised_client_email, i) in selectedPlan.authorised_client_emails"
                  :key="`${authorised_client_email}_${i}`"
              >
                <v-list-item-icon>
                  <v-icon color="purple">mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="authorised_client_email"></v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <!--                <v-btn icon-->
                <!--                       @click="onDeleteClient(authorised_client_email)">-->
                <!--                  <v-icon>mdi-delete</v-icon>-->
                <!--                </v-btn>-->
              </v-list-item>
            </v-list-item-group>

          </v-list>

        </v-card-text>
        <!--        <v-divider></v-divider>-->

        <!--        <v-card-actions>-->
        <!--          <v-btn class="primary" dark @click="onAddClient">-->
        <!--            <v-icon left>mdi-plus</v-icon>-->
        <!--            Add Authorized Clients-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->

      </v-card>





      <v-card max-width="400" v-if="addPlan.isActive">

        <v-card-text>
          Please enter the details of the new plan.

            <v-text-field
                v-model="addPlan.plan_name"
                label="Plan Name"
                required
            ></v-text-field>
          <v-text-field
                v-model="addPlan.property_key_price"
                label="Property Key Price"
                required
            ></v-text-field>
          <v-text-field
                v-model="addPlan.benchmark_report_price"
                label="Benchmark Report Price"
                required
            ></v-text-field>
          <v-text-field
                v-model="addPlan.carbon_report_price"
                label="Forest Carbon Report Price"
                required
            ></v-text-field>
          <v-text-field
                v-model="addPlan.emails"
                label="Owner Emails (Comma Separated)"
                required
            ></v-text-field>


          <v-progress-linear v-if="addPlan.inProgress" color="orange" indeterminate>

          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary" @click="addNewPlan" :disabled="addPlan.inProgress">
            <v-icon left>mdi-plus</v-icon>
            Create Plan
          </v-btn>
          <v-btn text @click="cancelAddNewPlan" :disabled="addPlan.inProgress">
            <v-icon left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>

      </v-card>

    </div>
  </v-container>
</template>

<script>
import {getAllPlans, createPlan} from '@/services/mfk_api'

console.log(createPlan)

export default {
  name: "AdminComponent",
  data() {
    return {
      selectedPlanId: null,
      selectedPlanIndex: null,
      selectedPlan: null,

      selectedItem1: null,
      selectedItem2: null,

      addPlan: {
        isActive: false,
        plan_name: null,
        emails: null,
        property_key_price: null,
        benchmark_report_price: null,
        carbon_report_price: null,
        inProgress: false,
      },


      deleteClient: {
        isActive: false,
        clientEmail: null,
        inProgress: false,
      },
      addClient: {
        isActive: false,
        clientEmails: null,
        inProgress: false,
      },

    }
  },
  methods: {
    onChangePlan() {

      for (let planIndex in this.$store.state.allPlans) {
        console.log(planIndex)
        if (this.$store.state.allPlans[planIndex].plan_id === this.selectedPlanId) {
          this.selectedPlanIndex = planIndex
          this.selectedPlan = this.$store.state.allPlans[this.selectedPlanIndex]
        }
      }

      console.log(this.selectedPlanId)
      console.log(this.selectedPlanIndex)
      console.log(this.$store.state.allPlans[this.selectedPlanIndex])
      console.log(this.$store.state.allPlans)
    },
    onAddPlan() {
      this.addPlan.isActive = true
      this.addPlan.plan_name = null
      this.addPlan.emails = null
      this.addPlan.property_key_price = null
      this.addPlan.benchmark_report_price = null
      this.addPlan.carbon_report_price = null
      this.addPlan.inProgress = false

    },
    cancelAddNewPlan() {
      this.addPlan.isActive = false
      this.addPlan.plan_name = null
      this.addPlan.emails = null
      this.addPlan.property_key_price = null
      this.addPlan.benchmark_report_price = null
      this.addPlan.carbon_report_price = null
      this.addPlan.inProgress = false

    },
    async addNewPlan() {
      console.log('add new plan')
      this.addPlan.inProgress = true
      await createPlan(
          this.addPlan.plan_name,
          this.addPlan.emails,
          this.addPlan.property_key_price,
          this.addPlan.benchmark_report_price,
          this.addPlan.carbon_report_price)

      this.cancelAddNewPlan()

    },


    async deleteAuthorizedClient() {
      this.deleteClient.inProgress = true
      // await removePlanAuthorizedClients(this.selectedPlanId, this.deleteClient.clientEmail)

      this.deleteClient.isActive = false
      this.deleteClient.clientEmail = null
      this.onChangePlan()
      this.$forceUpdate()
      this.deleteClient.inProgress = false
    },

    onAddClient() {
      this.addClient.isActive = true

    },
    cancelAddAuthorizedClient() {
      this.addClient.isActive = false
      this.addClient.clientEmail = null
      this.addClient.inProgress = false
    },
    async addAuthorizedClient() {
      console.log('add client')
      this.addClient.inProgress = true
      // await addPlanAuthorizedClients(this.selectedPlanId, this.addClient.clientEmails)

      this.addClient.isActive = false
      this.addClient.clientEmails = null
      this.onChangePlan()
      this.$forceUpdate()
      this.addClient.inProgress = false
    }
  },
  mounted() {
    getAllPlans()
  }
}
</script>

<style scoped>

</style>
