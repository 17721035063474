import Vue from 'vue'
import router from "../router";
// Import the Auth0 configuration
import {domain, clientId, audience} from "/auth_config.json";

// Import the plugin here
import {Auth0Plugin, getInstance} from "./index";

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});


export const authInstance = getInstance();

// authInstance.$watch("loading", async loading => {
//     if (!loading && authInstance.isAuthenticated) {
//         store.state.authAccessToken = await authInstance.getTokenSilently();
//     }
// });

export async function getToken() {
    return await authInstance.getTokenSilently();
}
