<template>
  <div>
    <v-progress-linear
        v-if="!$store.state.initAllLoaded"
        indeterminate
        color="orange"
    ></v-progress-linear>

    <v-navigation-drawer
        hide-overlay absolute height="100%"
        style="z-index: 10000;"
        ref="leftDrawer" :width="leftDrawer.width"
        v-model="leftDrawer.show">

      <v-app-bar
          flat dense
          color="blue"
      >
        <v-btn icon @click="decreaseDrawerLeft">
          <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="increaseDrawerLeft">
          <v-icon color="white">mdi-chevron-right</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="switchDrawerLeft">
          <v-icon color="white">mdi-window-minimize</v-icon>
        </v-btn>

      </v-app-bar>

      <v-col style="min-height: 50px;">
        <div id="geocoder"></div>
      </v-col>
      <p></p>
      <p></p>

      <v-col>
        <v-card>
          <v-card-title class="subtitle-2">
            Farm Reports
          </v-card-title>

          <v-card-text>

            <v-select
                :items="makePropertyListForBenchmark()"
                v-model="property_id"
                item-text="property_name"
                item-value="property_id"
                label="Select Farm"
                v-on:change="onSelectBenchmarkProperty"
            ></v-select>
            <v-progress-linear color="orange" indeterminate v-if="property_is_loading"></v-progress-linear>

            <!--            v-if="Object.keys(this.newPropertiesDict).length < 1 && property_id !== null"-->
            <v-alert
                v-if="property_id !== null"
                color="green"
                dense
                text
                type="info"
            >
              Select comparison parcels on the map
            </v-alert>

            <div v-if="property_id !== null">
              <v-switch
                  v-model="use_area_lookup_tool"
                  label="Select Comparison Buffer"
              ></v-switch>
              <v-col v-if="use_area_lookup_tool">
                <p>
                  This tool selects reference land parcels which have at least 50% of their area within a desired distance from your farm and larger than a desired minimum area. Note that some types of land parcels are never returned by this tool such as roads or marine land parcels.
                </p>
                <v-select
                    :items="km_items"
                    v-model="selected_km"
                    item-text="t"
                    item-value="v"
                    label="Radius"
                ></v-select>
                <v-select
                    :items="ha_items"
                    v-model="selected_ha"
                    item-text="t"
                    item-value="v"
                    label="Minimum Area"
                ></v-select>

                <v-btn
                    @click="findCads"
                    :disabled="selected_km === null || selected_ha === null"
                >Find Parcels
                </v-btn>
                <p></p>

                <v-progress-linear color="purple" indeterminate v-if="area_lookup_in_progress"></v-progress-linear>


              </v-col>


              <v-switch
                  v-model="use_geojson_lookup_tool"
                  label="Upload Comparison Areas (GeoJSON, KML)"
              ></v-switch>

              <v-col v-if="use_geojson_lookup_tool">
                <v-file-input
                    dense
                    outlined
                    show-size
                    :clearable="true"
                    color="primary"
                    accept=".json, .geojson, .kml"
                    label="Upload Geojson or KML"
                    v-model="geojson_file"
                    @change="on_change_upload_geojson"
                ></v-file-input>

                <p>
                  {{ geojson_error }}
                </p>

                <v-btn
                    v-if="geojson_geometry !== null"
                    @click="find_by_geojson">
                  Find Parcels for GeoJSON/KML
                </v-btn>
                <p></p>
                <v-progress-linear color="purple" indeterminate v-if="geojson__lookup_in_progress"></v-progress-linear>

              </v-col>

            </div>

            <br>


            <p v-if="showMsgPropertyKeyPurchase && Object.keys(this.newPropertiesDict).length > 0">
              <b style="color: red;">Benchmark Report Requires Farm Key</b><br>
              Benchmark reports can only be purchased if the corresponding farm key is also being purchased or has been purchased previously. By adding this report to the cart, you will also be adding the farm key to the cart.
            </p>
            <!--            <v-row v-for="(p, k) in newPropertiesDict"-->
            <!--                   :key="k">-->
            <!--              <v-chip-->
            <!--                  class="ma-2"-->
            <!--                  close-->
            <!--                  color="purple"-->
            <!--                  label-->
            <!--                  outlined-->
            <!--                  @click:close="deleteNewPropertyFromListAndMap(p.cibo_id)"-->
            <!--              >-->
            <!--                {{ p.jurisdictional_id }}-->
            <!--              </v-chip>-->
            <!--            </v-row>-->

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="property_id !== null">
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="cancelDoBenchmark">Cancel</v-btn>
            <v-btn color="primary"
                   :disabled="Object.keys(this.newPropertiesDict).length < 1"
                   @click="submitBenchmark">Add to Cart
            </v-btn>

          </v-card-actions>
        </v-card>


      </v-col>

      <p></p>
      <p></p>
      <v-divider></v-divider>
      <v-subheader align="left">WMS Layers</v-subheader>
      <v-expansion-panels accordion>


        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox dense
                        v-model="wmsLayers.ciboPSMA.show"
                        :label="`PSMA Australia`"
                        @change="ciboPSMACheckbox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>

          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox
                v-model="wmsLayers.woodyCoverChange.show"
                :label="`Woody Cover Change`"
                @change="woodyCoverChangeCheckBox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-slider
                v-model="wmsLayers.woodyCoverChange.year"
                :min="1991"
                :max="2019"
                @change="woodyCoverChangeChangeYear"
                thumb-label="always"
                ticks
                @keydown.native="woodyCoverChangeChangeYearKeyDown"
                @keyup.native="woodyCoverChangeChangeYearKeyUp"
            ></v-slider>

            <v-img
                contain
                aspect-ratio="1"
                width="300"
                max-height="600"
                src="@/assets/legends/WoodyCoverChangeLegend.png"
            ></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox
                v-model="wmsLayers.SeasonalGroundCover.show"
                :label="`Seasonal Ground Cover (%)`"
                @change="SeasonalGroundCoverCheckbox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ wmsLayers.SeasonalGroundCover.date_list[wmsLayers.SeasonalGroundCover.date_index] }}<br>

            <v-slider
                v-model="wmsLayers.SeasonalGroundCover.date_index"
                min="0"
                max="122"
                @change="SeasonalGroundCoverChangeDate"
                ticks
                @keydown.native="SeasonalGroundCoverChangeDateKeyDown"
                @keyup.native="SeasonalGroundCoverChangeDateKeyUp"
            ></v-slider>

            <v-img
                contain
                aspect-ratio="1"
                width="200"
                max-height="400"
                src="@/assets/legends/SeasonalGroundCoverLegend.png"
            ></v-img>

          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>


      <p></p>
      <p></p>
      <v-divider></v-divider>
      <v-subheader align="left">Base Layer</v-subheader>
      <v-list dense>

        <v-list-item-group
            color="primary"
        >
          <v-list-item>
            <v-list-item-content align="left">
              <v-select
                  v-model="selectedBaseLayer"
                  :items="baseLayersList"
                  @change="setBaseLayer($event)"
              ></v-select>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>


    </v-navigation-drawer>
    <v-system-bar
        style="z-index: 1000; height: 1px;"
        flat
        dense
    >
      <v-btn fab color="pink" style="z-index: 1000; margin-top: 100px;" @click="switchDrawerLeft">
        <v-icon color="white">mdi-page-layout-sidebar-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-system-bar>
    <v-main id="map" v-bind:style="mapStyle" style="z-index: 0;">
    </v-main>


  </div>
</template>

<script>

import "leaflet/dist/leaflet.css";
import L from "leaflet/dist/leaflet.js";
import toGeoJSON from '@mapbox/togeojson';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import '@/assets/scripts/Leaflet.MakiMarkers'

import 'leaflet-draw/dist/leaflet.draw.js'
import 'leaflet-draw/dist/leaflet.draw.css'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
  getPropertyBoundary, find_cads, find_cads_in_geojson,
  getPropertyBoundaryAtLatLon, addToShoppingCart,
  checkKeyBeenPurchased, showSnackBar
} from '@/services/mfk_api'

export default {
  name: "BenchmarkComponent",
  data() {
    return {
      leftDrawer: {
        show: true,
        width: '30%',
        borderSize: 3
      },

      mapStyle: {
        padding: 0,
        cursor: 'grab',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIindex: 0,
        margin: 0,

      },

      map: null,
      mapBoxAccessToken: 'pk.eyJ1IjoiYWxpZXJzaGFkaSIsImEiOiJjazg2dDJnNGIwbGFiM2ZtY3ExdjQ3Z3BvIn0.UlrwVCrd5ZPJ68BU2Z0yYA',
      mapboxGeocoderObj: null,

      baseLayers: {},

      tools: null,

      currentBaseLayer: 'Satellite',
      selectedBaseLayer: 'Satellite',
      baseLayersList: ['Streets', 'Satellite'],

      property_id: null,
      isPropertyKeyPurchased: false,
      showMsgPropertyKeyPurchase: false,
      property_is_loading: false,
      property_obj_list: [],

      selectPropertyOnMap: false,
      newPropertiesDict: {},
      newPropertiesMapObj: {},

      // benchmarking
      doBenchmarkOn: false,

      compareBenchmarkPropertiesDict: {},
      compareBenchmarkPropertiesMapObj: {},

      // area lookup
      use_area_lookup_tool: false,
      area_lookup_in_progress: false,
      km_items: [
        {'t': '0.5 km', 'v': 0.5},
        {'t': '1 km', 'v': 1},
        {'t': '2 km', 'v': 2},
        {'t': '5 km', 'v': 5},
        {'t': '10 km', 'v': 10},
        {'t': '20 km', 'v': 20},
        {'t': '50 km', 'v': 50},
      ],
      selected_km: null,

      ha_items: [
        {'t': '1 ha', 'v': 1},
        {'t': '2 ha', 'v': 2},
        {'t': '5 ha', 'v': 5},
        {'t': '10 ha', 'v': 10},
      ],
      selected_ha: null,

      use_geojson_lookup_tool: false,
      geojson_file: null,
      geojson_geometry: [],
      geojson_leaflet_obj: [],
      geojson_error: null,
      geojson__lookup_in_progress: false,


      wmsLayers: {
        ciboPSMA: {
          obj: null,
          show: false
        },
        woodyCoverChange: {
          obj: null,
          show: false,
          year: 2019
        },
        SeasonalGroundCover: {
          obj: null,
          show: false,
          // date: '2017-12',
          date_index: 122,
          date_list: ['1989-12',
            '1990-03',
            '1990-06',
            '1990-09',
            '1990-12',
            '1991-03',
            '1991-06',
            '1991-09',
            '1991-12',
            '1992-03',
            '1992-06',
            '1992-09',
            '1992-12',
            '1993-03',
            '1993-06',
            '1993-09',
            '1993-12',
            '1994-03',
            '1994-06',
            '1994-09',
            '1994-12',
            '1995-03',
            '1995-06',
            '1995-09',
            '1995-12',
            '1996-03',
            '1996-06',
            '1996-09',
            '1996-12',
            '1997-03',
            '1997-06',
            '1997-09',
            '1997-12',
            '1998-03',
            '1998-06',
            '1998-09',
            '1998-12',
            '1999-03',
            '1999-06',
            '1999-09',
            '1999-12',
            '2000-03',
            '2000-06',
            '2000-09',
            '2000-12',
            '2001-03',
            '2001-06',
            '2001-09',
            '2001-12',
            '2002-03',
            '2002-06',
            '2002-09',
            '2002-12',
            '2003-03',
            '2003-06',
            '2003-09',
            '2003-12',
            '2004-03',
            '2004-06',
            '2004-09',
            '2004-12',
            '2005-03',
            '2005-06',
            '2005-09',
            '2005-12',
            '2006-03',
            '2006-06',
            '2006-09',
            '2006-12',
            '2007-03',
            '2007-06',
            '2007-09',
            '2007-12',
            '2008-03',
            '2008-06',
            '2008-09',
            '2008-12',
            '2009-03',
            '2009-06',
            '2009-09',
            '2009-12',
            '2010-03',
            '2010-06',
            '2010-09',
            '2010-12',
            '2011-03',
            '2011-06',
            '2011-09',
            '2011-12',
            '2012-03',
            '2012-06',
            '2012-09',
            '2012-12',
            '2013-03',
            '2013-06',
            '2013-09',
            '2013-12',
            '2014-03',
            '2014-06',
            '2014-09',
            '2014-12',
            '2015-03',
            '2015-06',
            '2015-09',
            '2015-12',
            '2016-03',
            '2016-06',
            '2016-09',
            '2016-12',
            '2017-03',
            '2017-06',
            '2017-09',
            '2017-12',
            '2018-03',
            '2018-06',
            '2018-09',
            '2018-12',
            '2019-03',
            '2019-06',
            '2019-09',
            '2019-12',
            '2020-03',
            '2020-06',
            '2020-09',
            '2020-12',
            '2021-03',
          ],
        },
        S2: {
          obj: null,
          show: false,
        },

      },

    }
  },
  methods: {

    setLeftDrawerBorderWidth() {
      let i = this.$refs.leftDrawer.$el.querySelector(
          ".v-navigation-drawer__border"
      );
      i.style.width = this.leftDrawer.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const minSize = this.leftDrawer.borderSize;
      const el = this.$refs.leftDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
          ? "right"
          : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
          "mousedown",
          function (e) {
            if (e.offsetX < minSize) {
              e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
      );

      document.addEventListener(
          "mouseup",
          function () {
            el.style.transition = '';
            vm.leftDrawer.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
      );
    },
    switchDrawerLeft() {
      this.leftDrawer.show = !this.leftDrawer.show
    },
    increaseDrawerLeft() {
      let width = parseInt(this.leftDrawer.width.replace('px', ''))
      this.leftDrawer.width = width + 10 + 'px'
    },
    decreaseDrawerLeft() {
      let width = parseInt(this.leftDrawer.width.replace('px', ''))
      this.leftDrawer.width = width - 10 + 'px'
    },
    switchDrawerRight() {
      this.mapDrawerRight = !this.mapDrawerRight
    },

    initMap: function () {

      L.MakiMarkers.accessToken = this.mapBoxAccessToken;

      this.map = L.map("map", {
        editable: true,
        zoomControl: false,
        attributionControl: true,
        boxZoom: true,
        touchZoom: true,
        continuousWorld: false,
        noWrap: true,

      }).setView([-25, 133], 5);
      this.map.scrollWheelZoom = true
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      // this.map.scrollWheelZoom.enable();
      this.map.keyboard.enable();
      L.control.scale({maxWidth: 150, position: 'bottomright'}).addTo(this.map);
      this.baseLayers = {
        Streets: L.tileLayer(
            'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + this.mapBoxAccessToken),
        Satellite: L.tileLayer(
            'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' + this.mapBoxAccessToken),
      };

      this.baseLayers[this.currentBaseLayer].addTo(this.map).bringToBack()


      this.wmsLayers.ciboPSMA.obj = L.vectorGrid.protobuf(
          "https://cibo-mvt.s3-ap-southeast-2.amazonaws.com/{z}/{x}/{y}.pbf",
          {
            rendererFactory: L.svg.tile,
            interactive: true,
            attribution: '&copy; PSMA, Cibolabs',
            vectorTileLayerStyles: {
              cad: {
                weight: 0.5,
                color: '#FFFF00',
                fillColor: '#000000',
                fillOpacity: 0.1,
                fill: true
              }
            },
            maxNativeZoom: 15,
            minZoom: 11,
          })

    },

    readGeoJsonFile() {
      this.geojson_geometry = []
      this.geojson_leaflet_obj = []

      if (this.geojson_file !== null) {

        let reader = new FileReader();

        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        console.log(this.geojson_file);
        console.log(this.geojson_file.type);
        reader.readAsText(this.geojson_file);
        reader.onload = () => {
          let geoJsonIn;
          if (this.geojson_file.type === 'application/vnd.google-earth.kml+xml' || this.geojson_file.name.includes('.kml')) {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(reader.result, "text/xml");
            geoJsonIn = toGeoJSON.kml(xmlDoc)
          } else {
            geoJsonIn = JSON.parse(reader.result)
          }

          console.log('geoJsonIn')
          console.log(geoJsonIn)
          if ('geometry' in geoJsonIn) {
            this.geojson_geometry.push(geoJsonIn.geometry)
          } else {
            if ('features' in geoJsonIn) {
              for (let f of geoJsonIn.features) {
                this.geojson_geometry.push(f.geometry)
              }
            } else {
              this.geojson_error = 'GeoJSON format is not valid - it does not have geometry or features field.'
            }
          }

          if (this.geojson_geometry.length > 0) {
            for (let g of this.geojson_geometry) {
              this.geojson_leaflet_obj.push(L.geoJSON(g, {
                style: {
                  "color": 'yellow',
                  "weight": 5,
                  "opacity": 0.95
                },
              }))
            }

            for (let g_obj of this.geojson_leaflet_obj) {
              g_obj.addTo(this.map)

              this.map.fitBounds(L.geoJson(this.geojson_geometry).getBounds(),
                  {paddingTopLeft: [200, 50]})
            }

          }

        }
      }


    },

    async find_by_geojson() {
      this.geojson__lookup_in_progress = true
      let cads_list = await find_cads_in_geojson(this.geojson_geometry)
      console.log(cads_list.length)
      for (let propData of cads_list) {
        // const cibo_id = propData['cibo_id']
        const cibo_id = propData['cadastre_polygon_pid']

        if (!(cibo_id in this.newPropertiesDict)) {
          this.newPropertiesDict[cibo_id] = propData
          this.newPropertiesMapObj[cibo_id] = L.geoJSON(propData.geo_json, {
            style: {
              "color": 'purple',
              "weight": 5,
              "opacity": 0.65
            },
          })
          this.newPropertiesMapObj[cibo_id].on('click', () => {
            this.newPropertiesMapObj[cibo_id].remove()
            delete this.newPropertiesMapObj[cibo_id]
            this.map.off('click');
            setTimeout(() => {
              delete this.newPropertiesDict[cibo_id]
              this.addPropertyOnMapClick()
            }, 100)

          })
          this.newPropertiesMapObj[cibo_id].addTo(this.map)

        }

        this.$forceUpdate();

      }

      this.geojson__lookup_in_progress = false

    },
    on_change_upload_geojson() {
      this.geojson_error = null

      if (this.geojson_leaflet_obj.length > 0) {
        for (let g_obj of this.geojson_leaflet_obj) {
          g_obj.removeFrom(this.map)
        }
      }
      this.geojson_geometry = []
      this.geojson_leaflet_obj = []
      setTimeout(() => {
        if (this.geojson_file !== null) {
          this.readGeoJsonFile()
        }

      }, 500)

    },

    async findCads() {
      this.area_lookup_in_progress = true
      let cads_list = await find_cads(this.property_id, this.selected_km, this.selected_ha)
      console.log(cads_list.length)
      for (let propData of cads_list) {
        // const cibo_id = propData['cibo_id']
        const cibo_id = propData['cadastre_polygon_pid']

        if (!(cibo_id in this.newPropertiesDict)) {
          this.newPropertiesDict[cibo_id] = propData
          this.newPropertiesMapObj[cibo_id] = L.geoJSON(propData.geo_json, {
            style: {
              "color": 'purple',
              "weight": 5,
              "opacity": 0.65
            },
          })
          this.newPropertiesMapObj[cibo_id].on('click', () => {
            this.newPropertiesMapObj[cibo_id].remove()
            delete this.newPropertiesMapObj[cibo_id]
            this.map.off('click');
            setTimeout(() => {
              delete this.newPropertiesDict[cibo_id]
              this.addPropertyOnMapClick()
            }, 100)

          })
          this.newPropertiesMapObj[cibo_id].addTo(this.map)

        }

        this.$forceUpdate();


      }

      this.area_lookup_in_progress = false
    },

    setBaseLayer(baseLayerName) {
      this.map.removeLayer(this.baseLayers[this.currentBaseLayer])
      this.baseLayers[baseLayerName].addTo(this.map).bringToBack()
      this.currentBaseLayer = baseLayerName
    },

    addMapboxGeocoder() {
      this.mapboxGeocoderObj = new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        placeholder: 'Search for address',
        limit: 5,
      })
      this.mapboxGeocoderObj.addTo('#geocoder')
      this.mapboxGeocoderObj.on('result', (ev) => {
        if ('bbox' in ev.result) {
          var bbox = ev.result.bbox; //   [minX,minY,maxX,maxY]

          var corner1 = L.latLng(bbox[1], bbox[0]),
              corner2 = L.latLng(bbox[3], bbox[2]);
          this.map.fitBounds(L.latLngBounds(corner1, corner2));
        } else if ('center' in ev.result) {
          var center = ev.result.center; //   [Lon, Lat]

          // this.map.panTo(new L.LatLng(center[1], center[0]));
          this.map.setView(new L.LatLng(center[1], center[0]), 16);
        }
      })
    },

    show_PSMA() {
      this.wmsLayers.ciboPSMA.show = true
      this.map.removeLayer(this.wmsLayers.ciboPSMA.obj)
      this.wmsLayers.ciboPSMA.obj.addTo(this.map)
    },
    ciboPSMACheckbox() {
      if (this.wmsLayers.ciboPSMA.show) {
        this.wmsLayers.ciboPSMA.obj.addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.ciboPSMA.obj)
      }
    },

    woodyCoverChangeCheckBox() {
      if (this.wmsLayers.woodyCoverChange.show) {
        this.wmsLayers.woodyCoverChange.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/DOEE2019/${this.wmsLayers.woodyCoverChange.year}/{z}/{x}/{y}.png`,
            {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.woodyCoverChange.obj)
      }
    },
    woodyCoverChangeChangeYear() {
      this.map.removeLayer(this.wmsLayers.woodyCoverChange.obj)
      this.wmsLayers.woodyCoverChange.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/DOEE2019/${this.wmsLayers.woodyCoverChange.year}/{z}/{x}/{y}.png`,
          {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
    },
    woodyCoverChangeChangeYearKeyDown() {
      this.wmsLayers.woodyCoverChange.year -= 1
      console.log(this.wmsLayers.woodyCoverChange.year)
      this.woodyCoverChangeChangeYear()
    },
    woodyCoverChangeChangeYearKeyUp() {
      this.wmsLayers.woodyCoverChange.year += 1
      console.log(this.wmsLayers.woodyCoverChange.year)
      this.woodyCoverChangeChangeYear()
    },

    SeasonalGroundCoverCheckbox() {
      if (this.wmsLayers.SeasonalGroundCover.show) {
        let yyyymm = this.wmsLayers.SeasonalGroundCover.date_list[this.wmsLayers.SeasonalGroundCover.date_index].replace('-', '')
        console.log(yyyymm)
        this.wmsLayers.SeasonalGroundCover.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/GC/${yyyymm}/{z}/{x}/{y}.png`,
            {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.SeasonalGroundCover.obj)
      }
    },
    SeasonalGroundCoverChangeDate() {
      this.map.removeLayer(this.wmsLayers.SeasonalGroundCover.obj)
      let yyyymm = this.wmsLayers.SeasonalGroundCover.date_list[this.wmsLayers.SeasonalGroundCover.date_index].replace('-', '')
      console.log(yyyymm)
      this.wmsLayers.SeasonalGroundCover.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/GC/${yyyymm}/{z}/{x}/{y}.png`,
          {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
    },
    SeasonalGroundCoverChangeDateKeyDown() {
      this.wmsLayers.SeasonalGroundCover.date_index -= 1
      this.SeasonalGroundCoverChangeDate()
    },
    SeasonalGroundCoverChangeDateKeyUp() {
      this.wmsLayers.SeasonalGroundCover.date_index += 1
      this.SeasonalGroundCoverChangeDate()
    },

    async addPropertyToMap() {
      this.property_obj_list = []
      let bounds_arr = []
      for (let cibo_id in this.$store.state.properties[this.property_id].bnd) {
        bounds_arr.push(this.$store.state.properties[this.property_id].bnd[cibo_id].bndObj.geo_json)
        this.property_obj_list.push(L.geoJSON(this.$store.state.properties[this.property_id].bnd[cibo_id].bndObj.geo_json, {
          style: {
            "color": "#ff7800",
            "weight": 5,
            "opacity": 0.65
          }
        }))
      }

      for (let item of this.property_obj_list) {
        item.addTo(this.map)
      }
      this.map.fitBounds(L.geoJson(bounds_arr).getBounds(), {paddingTopLeft: [200, 50]})

    },

    async removePropertyFromMap() {

      for (let item of this.property_obj_list) {
        this.map.removeLayer(item)
      }
      this.property_obj_list = []

    },

    async onSelectBenchmarkProperty() {
      this.isPropertyKeyPurchased = true
      this.showMsgPropertyKeyPurchase = false

      this.deleteAllNewPropertiesFromListAndMap()
      const property_id = this.property_id
      // remove every other property from the map
      await this.removePropertyFromMap()

      // Load property and add to map
      if (this.$store.state.properties[property_id].bnd === null) {
        this.property_is_loading = true
        await getPropertyBoundary(this.$auth.user.email, property_id)
        this.property_is_loading = false
      }

      await this.addPropertyToMap()

      // showSnackBar(`Click on the map to select properties to compare with`, 60000, 'purple')
      this.doBenchmark()
      const isKeyPurchased = await checkKeyBeenPurchased({
        "property_id": this.property_id,
        "owner_email": this.$store.state.userDict.email_address
      })
      console.log('isKeyPurchased', isKeyPurchased)
      if (!isKeyPurchased) {
        this.isPropertyKeyPurchased = false
        this.showMsgPropertyKeyPurchase = true
        showSnackBar('The key is not purchased')
      } else {
        this.isPropertyKeyPurchased = true
        this.showMsgPropertyKeyPurchase = false
      }
      this.show_PSMA()

    },

    // BENCHMARK PROPERTY
    doBenchmark() {
      console.log('do benchmark')
      this.doBenchmarkOn = true
      this.mapStyle.cursor = 'crosshair'
      this.selectPropertyOnMap = true
      this.addPropertyOnMapClick()
      // this.show_cibo_cad_gt_1ha()
      showSnackBar(`Please select a farm from the list, then click on the map to select parcels to compare with`, 60000, 'purple')
      this.$forceUpdate()
    },

    async submitBenchmark() {
      console.log('submit benchmark report')
      this.mapStyle.cursor = 'grab'

      if (!this.isPropertyKeyPurchased) {

        this.addItemToShoppingCart(
            this.property_id,
            this.$store.state.properties[this.property_id].inf.property_name,
            'Property Key',
            {},
            null
        )

        this.addItemToShoppingCart(
            this.property_id,
            this.$store.state.properties[this.property_id].inf.property_name,
            'Benchmark Report',
            Object.keys(this.newPropertiesDict),
            this.property_id
        )
      } else {
        this.addItemToShoppingCart(
            this.property_id,
            this.$store.state.properties[this.property_id].inf.property_name,
            'Benchmark Report',
            Object.keys(this.newPropertiesDict),
            null
        )

      }

      console.log('shopping cart')
      console.log(this.$store.state.shoppingCart)
      this.cancelDoBenchmark()
    },
    async cancelDoBenchmark() {
      this.doBenchmarkOn = false
      this.isPropertyKeyPurchased = false
      this.showMsgPropertyKeyPurchase = false

      this.map.off('click');
      this.mapStyle.cursor = 'grab'
      this.deleteAllNewPropertiesFromListAndMap()
      await this.removePropertyFromMap()
      this.property_id = null

      this.geojson_file = null
      this.geojson_error = null
      this.geojson_geometry = null
      if (this.geojson_leaflet_obj.length > 0) {
        for (let g_obj of this.geojson_leaflet_obj) {
          g_obj.removeFrom(this.map)
        }
      }
      this.geojson_geometry = []
      this.geojson_leaflet_obj = []


      this.$forceUpdate();
    },

    makePropertyListForBenchmark() {

      let propList = []
      for (let propId of Object.keys(this.$store.state.properties)) {
        if (this.$store.state.properties[propId].inf.is_aus_farmkey){
          propList.push({property_id: propId, property_name: this.$store.state.properties[propId].inf.property_name})
        }
      }

      return propList.sort((a, b) => a.property_name.localeCompare(b.property_name))

    },

    addItemToShoppingCart(property_id, property_name, item_type, comparison_cads, PropertyKeyIdForBenchmarkReport) {
      addToShoppingCart(property_id, property_name, item_type, comparison_cads, PropertyKeyIdForBenchmarkReport)
      addToShoppingCart(property_id, property_name, 'Carbon Report', {}, null)
      this.$forceUpdate();
    },

    // SELECT BY CLICK ON MAP
    deleteNewPropertyFromListAndMap(cibo_id) {
      this.map.removeLayer(this.newPropertiesMapObj[cibo_id])
      delete this.newPropertiesMapObj[cibo_id]
      delete this.newPropertiesDict[cibo_id]
      this.$forceUpdate();
    },
    deleteAllNewPropertiesFromListAndMap() {
      for (let cibo_id in this.newPropertiesDict) {
        this.deleteNewPropertyFromListAndMap(cibo_id)
      }
    },
    async getPropertyBoundaryOnMapClick(e) {

      showSnackBar('Loading property boundary for clicked location ...', 10000, 'purple')
      const propResp = await getPropertyBoundaryAtLatLon(e.latlng.lat, e.latlng.lng)
      this.$store.state.snackbar.show = false
      this.$store.state.snackbar.text = null
      console.log(propResp)
      if (propResp.length > 0) {

        const propData = propResp[0]
        // const cibo_id = propData['cibo_id']
        const cibo_id = propData['cadastre_polygon_pid']

        if (!(cibo_id in this.newPropertiesDict)) {
          this.newPropertiesDict[cibo_id] = propData
          this.newPropertiesMapObj[cibo_id] = L.geoJSON(propData.geometry, {
            style: {
              "color": 'purple',
              "weight": 5,
              "opacity": 0.65
            },
            // onEachFeature: (feature, layer) => {
            //   console.log('***********************')
            //   console.log(feature)
            //   console.log(layer)
            // }
          })
          // this.newPropertiesMapObj[cibo_id].bindTooltip(propData.jurisdictional_id, {permanent: true, opacity: 0.7}).openTooltip()
          this.newPropertiesMapObj[cibo_id].on('click', () => {
            this.newPropertiesMapObj[cibo_id].remove()
            delete this.newPropertiesMapObj[cibo_id]
            this.map.off('click');
            setTimeout(() => {
              delete this.newPropertiesDict[cibo_id]
              this.addPropertyOnMapClick()
            }, 100)

          })
          this.newPropertiesMapObj[cibo_id].addTo(this.map)

        }

        this.$forceUpdate();

      }

    },
    addPropertyOnMapClick() {
      console.log(' in addPropertyOnMapClick')
      if (this.selectPropertyOnMap) {
        this.mapStyle.cursor = 'crosshair'
        this.map.on('click', this.getPropertyBoundaryOnMapClick)
      } else {
        this.map.off('click');
        this.mapStyle.cursor = 'grab'
      }
      this.$forceUpdate();


    },
    setPanelWidth(){
      if (screen.width < 500){
        this.leftDrawer.width = 300
      }
    },


  },
  mounted() {
    this.setPanelWidth()
    this.initMap()
    this.addMapboxGeocoder()
    this.setLeftDrawerBorderWidth();
    this.setEvents();
  },
}
</script>

<style scoped>

</style>
