<template>

  <v-container fluid>

    <v-card class="mx-auto my-12" max-width="800"
            v-show="$store.state.shoppingCart.length < 1"
    >
      <v-card-text>
        <p>
          Shopping cart is empty
        </p>
      </v-card-text>

    </v-card>


    <v-card class="mx-auto my-12" max-width="800"
            v-show="$store.state.shoppingCart.length > 0 && !showRemoveBenchmarkWarning">
      <v-card-title>Checkout</v-card-title>

      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Item
              </th>
              <th class="text-left">
                Farm
              </th>
              <th class="text-left">
                Price (inc. GST - AUD)
              </th>
              <th class="text-left">
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, key) in $store.state.shoppingCart"
                :key="key"
            >
<!--              <td>{{ item.item_type }}</td>-->
              <td>{{ item.item_name }}</td>
              <td>{{ $store.state.properties[item.property_id].inf.property_name }}</td>
              <td>{{ item.price }}</td>
              <td>
                <v-btn icon title="Delete" @click="removeFromShoppingCard(key)">
                  <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

<!--      {{total_price}}-->

      <v-col align="center">

        <v-radio-group align="center"
                       v-model="payMethod"
                       v-on:change="updatePrices"
                       column
        >
          <template v-slot:label>
            <div>Payment Method</div>
          </template>
          <v-radio
              label="Credit/Debit Card or PayPal"
              value="CC"
          ></v-radio>
          <v-radio
              label="Subscription Plan"
              value="Plan"
          ></v-radio>
        </v-radio-group>

        <v-col v-show="payMethod === 'CC'">

          <v-btn v-show="has_free_reorder && total_price === 0"
                 :disabled="isPayBtnDisabled"
                 color="primary"
                 @click="freeReOrder"
          >
            Order
          </v-btn>

          <div v-show="total_price > 0" ref="paypal" style="max-width: 300px;"></div>
        </v-col>

        <v-col v-show="payMethod === 'Plan'">

          <div v-if="all_user_plans.length > 0">
            <v-col
                class="d-flex"
                cols="12"
                sm="6"
            >
              <v-select
                  :disabled="isPayBtnDisabled"
                  :items="all_user_plans"
                  v-model="selectedPlanId"
                  item-text="plan_name"
                  item-value="plan_id"
                  label="Select Plan"
                  v-on:change="updatePrices"
              ></v-select>
            </v-col>

            <v-btn
                color="primary"
                :disabled="isPayBtnDisabled || $store.state.shoppingCart.length < 1 || selectedPlanId === null"
                @click="payByPlan"
            >
              Pay by Plan
            </v-btn>

          </div>

          <div v-show="all_user_plans.length < 1">

            <v-banner
                max-width="300"
                single-line
            >
              <v-icon
                  slot="icon"
                  color="warning"
                  size="36"
              >
                mdi-alert-octagram
              </v-icon>
              You do not have any plan
            </v-banner>

          </div>


        </v-col>
      </v-col>


    </v-card>

    <v-card class="mx-auto my-12" max-width="800" v-if="showRemoveBenchmarkWarning">
      <v-card-title>Warning</v-card-title>
      <v-card-text>
        <p>
          <b>Are you sure you want to remove this Farm Key order?</b><br>
          Note that benchmark reports can only be purchased for a farm if its farm key is also in the cart (unless it's been previously purchased).
        </p>

      </v-card-text>

      <v-card-actions>
        <v-btn class="warning" @click="doRemoveFromShoppingCard">Delete</v-btn>
        <v-btn @click="selectedItemsToRemoveIndex = []; showRemoveBenchmarkWarning=false;">Cancel</v-btn>
      </v-card-actions>
    </v-card>

  </v-container>

</template>

<script>


import {
  recordExports, addPlanExport, checkReorderFree, // generateAndSendFiles
  addPaypalPayment, recordBenchmarks, showSnackBar
} from "@/services/mfk_api";

import {c_mfk_task} from "@/services/cibo_api";


export default {
  name: "ShoppingCart",

  data() {
    return {
      all_user_plans: [],
      pricesDict: {
        'Property Key': 75,
        'Benchmark Report': 175,
        'Carbon Report': 155,
      },
      showRemoveBenchmarkWarning: false,
      selectedItemsToRemoveIndex: [],
      payMethod: 'CC', // CC, Plan
      has_free_reorder: false,
      total_price: 0,
      selectedPlanId: null,
      isPayBtnDisabled: false,
      // payPalClientId: 'Acl1Ikr1KZbWqdYe-ss4i_cpyJ1W_gwrNNhYgdB8xsX8kKFM32DObsrlqL1zk7oSQxk55zK5jh-J5q2k', // live cibolabs
      payPalClientId: 'AWJfVckce08pscMg-JDzlIONRMglKZ-POGVVWoccjQFJ05xHK6PWwh2S30WtBHOvOFHhOLNtL_GP17Ui', // my sandbox
    }
  },
  methods: {
    emptyCart() {
      this.$store.state.shoppingCart = []
      this.payMethod = 'CC'
      this.selectedPlanId = null
    },
    async removeFromShoppingCard(i) {

      this.selectedItemsToRemoveIndex.push(i)

      let item_type = this.$store.state.shoppingCart[i].item_type

      if (item_type === "Property Key") {
        let property_id = this.$store.state.shoppingCart[i].property_id
        for (let idx in this.$store.state.shoppingCart) {
          if (this.$store.state.shoppingCart[idx].PropertyKeyIdForBenchmarkReport === property_id) {
            this.selectedItemsToRemoveIndex.push(parseInt(idx))
            this.showRemoveBenchmarkWarning = true
          }
        }

      }

      if (!this.showRemoveBenchmarkWarning) {
        await this.doRemoveFromShoppingCard()
      }

      await this.updatePrices()


    },
    async doRemoveFromShoppingCard() {

      let new_card_list = []
      for (let idx in this.$store.state.shoppingCart) {

        if (!this.selectedItemsToRemoveIndex.includes(parseInt(idx))) {
          new_card_list.push(this.$store.state.shoppingCart[parseInt(idx)])
          console.log('just pushed ', idx)
        }
      }

      this.$store.state.shoppingCart = new_card_list

      this.selectedItemsToRemoveIndex = []
      this.showRemoveBenchmarkWarning = false
      await this.updatePrices()
      this.$forceUpdate()

    },
    async updatePrices() {

      let selectedPlan = null
      for (let plan of this.all_user_plans) {
        if (plan.plan_id === this.selectedPlanId) {
          selectedPlan = plan
        }
      }
      console.log('SELECTED_PLAN', selectedPlan)

      for (let cartItem of this.$store.state.shoppingCart) {

        if (this.payMethod === 'CC') {

          if (cartItem.item_type === 'Property Key') {
            cartItem.price = this.pricesDict['Property Key']
          }
          if (cartItem.item_type === 'Benchmark Report') {
            cartItem.price = this.pricesDict['Benchmark Report']
          }

          if (cartItem.item_type === 'Carbon Report') {
            cartItem.price = this.pricesDict['Carbon Report']
          }

        } else if (this.payMethod === 'Plan') {
          console.log('IN PLAN ---<<<<<<')
          if (selectedPlan !== null) {
            if (cartItem.item_type === 'Property Key') {
              cartItem.price = selectedPlan.property_key_price
            }
            if (cartItem.item_type === 'Benchmark Report') {
              cartItem.price = selectedPlan.benchmark_report_price
            }
            if (cartItem.item_type === 'Carbon Report') {
              cartItem.price = selectedPlan.carbon_report_price
            }
          }


        }

      }
      await this.checkFreeReorders()

      this.total_price = 0
      for (let cartItem of this.$store.state.shoppingCart) {
        console.log(cartItem)
        this.total_price += cartItem.price
      }

      this.$forceUpdate();

    },
    async getPurchaseVars() {
      console.log('in getPurchaseVars')
      await this.updatePrices()

      let selectedPlan;
      for (let plan of this.all_user_plans) {
        if (plan.plan_id === this.selectedPlanId) {
          selectedPlan = plan
        }
      }

      let property_idsList = []
      let typesList = []
      let priceList = []

      for (let item of this.$store.state.shoppingCart) {

        property_idsList.push(item.property_id)
        typesList.push(item.item_type)
        if (item.item_type === 'Property Key') {
          if (this.payMethod === 'Plan') {
            priceList.push(selectedPlan.property_key_price)
          } else {
            priceList.push(item.price)
          }
        }
        if (item.item_type === 'Benchmark Report') {
          if (this.payMethod === 'Plan') {
            priceList.push(selectedPlan.benchmark_report_price)
          } else {
            priceList.push(item.price)
          }
        }

        if (item.item_type === 'Free Benchmark Reorder') {
          priceList.push(0)
        }

        if (item.item_type === 'Carbon Report') {
          if (this.payMethod === 'Plan') {
            priceList.push(selectedPlan.carbon_report_price)
          } else {
            priceList.push(item.price)
          }
        }

        if (item.item_type === 'Free Carbon Reorder') {
          priceList.push(0)
        }

      }

      return {
        selectedPlan: selectedPlan,
        property_idsList: property_idsList,
        typesList: typesList,
        priceList: priceList
      }

    },
    async freeReOrder() {
      this.isPayBtnDisabled = true
      const purchaseVarsDict = await this.getPurchaseVars()

      const property_idsList = purchaseVarsDict.property_idsList
      const typesList = purchaseVarsDict.typesList
      // const priceList = purchaseVarsDict.priceList
      console.log('freeReOrder ----------------------')
      console.log('purchaseVarsDict')
      console.log(purchaseVarsDict)
      console.log(property_idsList)
      console.log(typesList)
      await recordExports({
        "property_ids": property_idsList,
        "types": typesList
      })

      let benchmarkItems = []
      for (let cartItem of this.$store.state.shoppingCart) {
        if (cartItem.item_type === 'Benchmark Report') {
          benchmarkItems.push(cartItem)
        }
      }

      if (benchmarkItems.length > 0) {
        let property_ids = benchmarkItems.map(item => item.property_id)

        let reference_cibo_ids_text_array = benchmarkItems.map(item => {
          console.log(item.comparison_cads)
          return `{${item.comparison_cads.map(c => `"${c}"`)}}`
        })
        console.log(reference_cibo_ids_text_array)


        let recordBenchmarksData = {
          owner_email: this.$store.state.userDict.email_address,
          property_ids: property_ids,
          reference_cibo_ids_text_array: reference_cibo_ids_text_array
        }

        await recordBenchmarks(recordBenchmarksData)

      }

      const recObjGenFiles = {
        items: this.$store.state.shoppingCart,
        user_email: this.$store.state.userDict.email_address,
        plan_owners: []
      }

      c_mfk_task(
          null,
          this.$store.state.userDict.email_address,
          null,
          'generateAndSendFiles',
          'submitted',
          recObjGenFiles)
      this.emptyCart()
      this.isPayBtnDisabled = false
      const msg = 'Re-Order Succeeded - Generating Files.\n' +
          'Our server has begun generating the files that you\'ve ordered. This will take around 2 to 10 minutes. We will send you the files via email upon completion. You do not need to keep this window open. If you believe there\'s been an error processing your purchase, please contact support@cibolabs.com.au.'

      showSnackBar(msg, 20000)

    },
    async payByPlan() {
      this.isPayBtnDisabled = true
      const purchaseVarsDict = await this.getPurchaseVars()

      const selectedPlan = purchaseVarsDict.selectedPlan
      const property_idsList = purchaseVarsDict.property_idsList
      const typesList = purchaseVarsDict.typesList
      const priceList = purchaseVarsDict.priceList


      await recordExports({
        "property_ids": property_idsList,
        "types": typesList
      })

      await addPlanExport({
        "plan_id": this.selectedPlanId,
        "owner_email": this.$store.state.userDict.email_address,
        "item_types": typesList,
        "item_costs": priceList,
        "property_ids": property_idsList
      })
      console.log('1')
      const recObjGenFiles = {
        items: this.$store.state.shoppingCart,
        user_email: this.$store.state.userDict.email_address,
        plan_owners: selectedPlan.owner_emails,
      }
      console.log('2')
      console.log(recObjGenFiles)

      c_mfk_task(
          null,
          this.$store.state.userDict.email_address,
          null,
          'generateAndSendFiles',
          'submitted',
          recObjGenFiles)

      console.log('4')
      this.emptyCart()

      console.log(recObjGenFiles)
      this.isPayBtnDisabled = false
      const msg = 'Order Succeeded - Generating Files.\n' +
          'Our server has begun generating the files that you\'ve ordered. This will take around 2 to 10 minutes. We will send you the files via email upon completion. You do not need to keep this window open. If you believe there\'s been an error processing your purchase, please contact support@cibolabs.com.au.'

      showSnackBar(msg, 20000)

    },
    async createPayPalOrder() {
      console.log('in createPayPalOrder');

      const purchaseVarsDict = await this.getPurchaseVars()

      const property_idsList = purchaseVarsDict.property_idsList
      const typesList = purchaseVarsDict.typesList
      const priceList = purchaseVarsDict.priceList

      console.log(property_idsList, typesList, priceList);
      const sumPrice = priceList.reduce((a, b) => a + b)

      let orderItemsList = []

      for (let i in property_idsList) {
        orderItemsList.push({
          "unit_amount": {
            "currency_code": "AUD",
            "value": priceList[i].toFixed(2)
          },
          "quantity": "1",
          "name": `${typesList[i]} : ${this.$store.state.properties[property_idsList[i]].inf.property_name}`,

        })
      }

      const payPalOrder = {
        "intent": "CAPTURE",
        "purchase_units": [{
          "description": "My Farm Key Cart",
          "amount": {
            "value": sumPrice.toFixed(2),
            "currency_code": "AUD",
            "breakdown": {
              "item_total": {
                "currency_code": "AUD",
                "value": sumPrice.toFixed(2)
              },
            }
          },
          "items": orderItemsList
        }],
        'application_context': {
          'shipping_preference': 'NO_SHIPPING'
        }
      }

      console.log(payPalOrder)


      return payPalOrder;

    },
    async payByPayPal() {

      let purchaseVarsDict = await this.getPurchaseVars()

      let property_idsList = purchaseVarsDict.property_idsList
      let typesList = purchaseVarsDict.typesList
      let priceList = purchaseVarsDict.priceList

      console.log('IN CREATE ORDER')
      let payPalOrder = await this.createPayPalOrder()
      console.log('payPalOrder')
      console.log(payPalOrder)

      window.paypal.Buttons({
        async createOrder(data, actions) {
          console.log('in createOrder for PayPal')
          console.log('purchaseVarsDict')
          console.log(purchaseVarsDict)
          // console.log('IN CREATE ORDER')
          // const payPalOrder = this.createPayPalOrder()
          console.log('payPalOrder')
          console.log(payPalOrder)


          return actions.order.create(payPalOrder);
        },
        onApprove: async (data, actions) => {
          console.log('in onApprove')
          const order = await actions.order.capture();
          console.log('order')
          console.log(order)

          purchaseVarsDict = await this.getPurchaseVars()

          property_idsList = purchaseVarsDict.property_idsList
          typesList = purchaseVarsDict.typesList
          priceList = purchaseVarsDict.priceList

          await addPaypalPayment({
            owner_email: this.$store.state.userDict.email_address,
            property_ids: property_idsList,
            item_types: typesList,
            item_prices: priceList,
            paypal_response: JSON.stringify(order, null, 4)
          })

          await recordExports({
            "property_ids": property_idsList,
            "types": typesList
          })

          let benchmarkItems = []
          for (let cartItem of this.$store.state.shoppingCart) {
            if (cartItem.item_type === 'Benchmark Report') {
              benchmarkItems.push(cartItem)
            }
          }

          if (benchmarkItems.length > 0) {
            let property_ids = benchmarkItems.map(item => item.property_id)

            let reference_cibo_ids_text_array = benchmarkItems.map(item => {
              console.log(item.comparison_cads)
              return `{${item.comparison_cads.map(c => `"${c}"`)}}`
            })
            console.log(reference_cibo_ids_text_array)


            let recordBenchmarksData = {
              owner_email: this.$store.state.userDict.email_address,
              property_ids: property_ids,
              reference_cibo_ids_text_array: reference_cibo_ids_text_array
            }

            await recordBenchmarks(recordBenchmarksData)

          }

          const recObjGenFiles = {
            items: this.$store.state.shoppingCart,
            user_email: this.$store.state.userDict.email_address,
            plan_owners: []
          }

          c_mfk_task(
              null,
              this.$store.state.userDict.email_address,
              null,
              'generateAndSendFiles',
              'submitted',
              recObjGenFiles)

          console.log('do emptyCart')
          this.emptyCart()
          this.isPayBtnDisabled = false
        },
        onError: err => {
          console.log(err);
        }
      })
          .render(this.$refs.paypal);
    },
    async checkFreeReorders() {
      for (let cartItem of this.$store.state.shoppingCart) {
        this.has_free_reorder = false

        if (cartItem.item_type === 'Benchmark Report') {
          this.has_free_reorder = await checkReorderFree(cartItem.property_id)

          if (this.has_free_reorder) {
            cartItem.item_type = 'Free Benchmark Reorder'
            cartItem.item_name = 'Free Woody Veg. & Ground Cover Report Reorder'
            cartItem.price = 0
          }
        } else if (cartItem.item_type === 'Free Benchmark Reorder') {
          this.has_free_reorder = true
          cartItem.price = 0
        }
      }
    },

  },
  mounted() {

    // for (let cartItem of this.$store.state.shoppingCart) {
    //
    //   if (cartItem.item_type === 'Property Key') {
    //     cartItem.price = this.pricesDict['Property Key']
    //   }
    //   if (cartItem.item_type === 'Benchmark Report') {
    //     cartItem.price = this.pricesDict['Benchmark Report']
    //   }
    //
    // }

    if (this.$store.state.shoppingCart.length > 0) {
      this.payByPayPal()
      // const script = document.createElement("script");
      // script.src = `https://www.paypal.com/sdk/js?client-id=${this.payPalClientId}&currency=AUD`;
      // script.addEventListener("load", this.payByPayPal);
      // document.body.appendChild(script);
      // this.$forceUpdate();
    }

    if (this.$store.state.shoppingCart.length > 0) {
      console.log('STARTING TO CHECK FREE RE_ORDERS')
      this.checkFreeReorders()
    }

    this.all_user_plans = this.$store.state.userPlans.concat(this.$store.state.authorizedPlans)


  }
}
</script>

<style scoped>

</style>
