import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        version: '2022.6',
        cibo_apis: {
            dev: 'https://3jvmt7yjpb.execute-api.ap-southeast-2.amazonaws.com/api/',
            prod: 'https://f4nutg7rj8.execute-api.ap-southeast-2.amazonaws.com/api/',
        },
        stage: 'dev',

        auth0_does_owner_exist: false,
        auth0_create_owner_loading: false,
        auth0_create_owner_err: false,
        db: {
            cibo: {
                geos: {},
                farms: {},
                properties: {},
                property_polygons: {}
            },
            cerestag: {
                tag_data: {},
            }
        },
        // authAccessToken: null,
        initAllLoaded: false,

        userDict: {
            business_name: null,
            email_address: null,
            num_properties: null,
            owner_name: null,
            phone_number: null
        },
        agriProve: {
            userAuthorized: false,
            pdfUploaded: false,
            admin: {
                soilProjects: {},
                soilProjects2: {},
                soilProjectTasks: {},
            }
        },
        properties: {},
        hasProperties: false,

        edit_property_map_actions: null,
        // [property_id] { bnd: , info: }
        shoppingCart: [],
        snackbar: {
            show: false,
            text: null,
            color: 'pink'
        },
        snackbarRefershPage: {
            show: false,
        },

        isAdmin: false,
        isAgentAdmin: false,

        authorizedPlans: [],
        userPlans: [],
        allPlans: [],
        allPropertiesLoaded: false,
        navItems: [
            {title: 'Map', icon: 'mdi-map', link: '/map'},
            {title: 'Benchmark', icon: 'mdi-compare', link: '/benchmark'},
            {title: 'Farms', icon: 'mdi-map-marker', link: '/farms'},
            // {title: 'AgriProve', icon: 'mdi-file-certificate', link: '/agriprove'},
            // {title: 'Agent Admin', icon: 'mdi-account-tie', link: '/agent-admin'},
            // {title: 'Admin', icon: 'mdi-account-multiple', link: '/admin'},
            {title: 'Shop', icon: 'mdi-cart', link: '/shop'},
            {title: 'Settings', icon: 'mdi-account-cog', link: '/settings'},
        ],
    },
    mutations: {
        // async getAuthAccessToken(state) {
        //     state.authAccessToken = await authInstance.getTokenSilently();
        // },
    }
})

export default store;
