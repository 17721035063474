<template>
  <div v-if="$store.state.auth0_does_owner_exist">
    <v-container fluid>

      <v-row dense wrap>
        <v-card max-width="400">
          <v-app-bar
              text dense
              color="orange"
          >

            <v-toolbar-title class="title white--text pl-0">
              {{ $store.state.userDict.owner_name }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon text
                   @click.stop="showEditProfileDialog=true"
                   :disabled="$store.state.userDict.email_address === null"
            >
              <v-icon color="white">mdi-pencil</v-icon>
              <EditUserProfileDialog
                  v-if="showEditProfileDialog"
                  v-model="showEditProfileDialog"
                  data="null"
                  v-bind:dataObj="{items}"
                  @input="getUserMetaData"
              />
            </v-btn>
          </v-app-bar>


          <v-card-text>

            <v-list>
<!--              <v-subheader>Actions</v-subheader>-->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userDict.business_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userDict.phone_number }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userDict.email_address }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userDict.num_properties }}

                    <span v-if="$store.state.userDict.num_properties === 1">farm</span>
                    <span v-else-if="$store.state.userDict.num_properties > 1">farms</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>


            </v-list>

<!--            <v-divider class="mx-4"></v-divider>-->
<!--            <v-card-actions>-->

<!--              <v-btn color="lighten-2"-->
<!--                     @click.stop="showEditProfileDialog=true"-->
<!--                     :disabled="$store.state.userDict.email_address === null"-->
<!--              >-->
<!--                <span class="hidden-xs-only">Edit Profile 1</span>-->
<!--                <v-icon right>mdi-pencil</v-icon>-->
<!--                <EditUserProfileDialog-->
<!--                    v-if="showEditProfileDialog"-->
<!--                    v-model="showEditProfileDialog"-->
<!--                    data="null"-->
<!--                    v-bind:dataObj="{items}"-->
<!--                    @input="getUserMetaData"/>-->
<!--              </v-btn>-->

<!--            </v-card-actions>-->
          </v-card-text>
        </v-card>
      </v-row>

    </v-container>

    <small style="color: gray; font-size: 0.7em;">   v {{$store.state.version}}</small>


  </div>
</template>

<script>
import {getUserMetaData} from "@/services/mfk_api";
import EditUserProfileDialog from "@/components/UserProfile/EditUserProfileDialog";

export default {
  name: "UserProfile",
  components: {EditUserProfileDialog},
  data() {
    return {
      // authAccessToken: null,
      showEditProfileDialog: false,
      items: []

    }
  },
  methods: {
    getUserMetaData() {
      getUserMetaData(this.$auth.user.email)
    }

  },
  mounted() {
    if (this.$auth.isAuthenticated && this.$store.state.userDict.email_address === null) {
      getUserMetaData(this.$auth.user.email)
    }
  },

}
</script>

<style scoped>

</style>
