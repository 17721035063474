<template>

  <v-container fluid v-if="!$store.state.auth0_does_owner_exist">

    <div v-if="!$store.state.auth0_create_owner_err">
      <p>
        You must complete the sign-up process below to use MyFarmKey.
      </p>

      <div style="max-width: 400px;">

        <v-text-field
            :disabled="$store.state.auth0_create_owner_loading"
            v-model="user_name"
            label="Name"
            required
        ></v-text-field>
        <v-text-field
            :disabled="$store.state.auth0_create_owner_loading"
            v-model="user_business"
            label="Business"
            required
        ></v-text-field>
        <v-text-field
            :disabled="$store.state.auth0_create_owner_loading"
            v-model="user_phone"
            label="Phone Number"
            required
        ></v-text-field>

      </div>


      <a href="https://cibolabs.atlassian.net/l/c/zq3YKPjy"
         style="color: blue; text-decoration: underline;"
         target="_blank"> Privacy Policy </a>
      <br/>
      <a href="https://cibolabs.atlassian.net/l/c/yDqBbJzQ"
         style="color: blue; text-decoration: underline;"
         target="_blank"> Data License Agreement </a>

      <v-checkbox
          v-model="acceptAgreement"
          :disabled="$store.state.auth0_create_owner_loading"
          label="I have read and agree to the conditions of the EULA, Privacy Policy and Data Licence Agreement linked above."
      >
      </v-checkbox>

      <v-btn class="primary"
             @click="createAccount"
             :disabled="user_name === null || !acceptAgreement">
        Create Account
      </v-btn>
      <p></p>
      <v-progress-linear color="orange" indeterminate
                         v-if="$store.state.auth0_create_owner_loading"></v-progress-linear>

    </div>
    <div v-else>
      <h3 style="color: darkorange;">You account is not created. Please try again later.</h3>
    </div>

  </v-container>

</template>

<script>

import {createOwner} from "@/services/mfk_api";

export default {
  name: "CreateAccountComponent",
  data() {
    return {
      user_name: null,
      user_business: null,
      user_phone: null,

      acceptAgreement: false,

    }
  },
  methods: {
    async createAccount() {
      createOwner(this.user_name, this.user_business, this.user_phone, this.$auth.user.email)
    },
  },
}
</script>

<style scoped>

</style>
