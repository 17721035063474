<template>
  <v-container>
    <!--    <v-btn @click="getPropertyKey">Check get prop key</v-btn>-->

    <v-progress-linear indeterminate v-if="!$store.state.initAllLoaded">

    </v-progress-linear>

    <v-alert
        v-if="!$store.state.hasProperties && $store.state.initAllLoaded"
        color="orange"
        dense
        icon="mdi-alert"
        text
        type="warning"
    >
      You don't have any farm. Please add a farm in the map section first.
    </v-alert>

    <v-btn @click="getClientSoilProjects" v-if="$store.state.hasProperties && $store.state.initAllLoaded">
      Load My Soil Projects
    </v-btn>

    <v-col v-if="soil_projects_list.length > 0">

      <v-subheader>Soil Projects</v-subheader>
      <p>
        Click on a row to select its project and restore the form.<br>
      </p>
      <v-data-table
          :headers="projects_table_headers"
          :items="soil_projects_list"
          item-key="project_id"
          class="elevation-1"
          @click:row="on_click_row"
      ></v-data-table>
    </v-col>
    <br>

    <div v-if="$store.state.hasProperties && $store.state.initAllLoaded">
      <v-btn @click="initSoilForm"
             color="green"
             dense
             dark
             v-if="!soilForm.actions.formStarted">
        <v-icon left>mdi-playlist-edit</v-icon>
        Start A New Soil Project Registration Form
      </v-btn>
      <v-alert
          v-if="soilForm.actions.formStarted"
          color="green"
          dense
          icon="mdi-information-variant"
          text
          type="success"
      >
        The following steps will allow you to initiate the registration of a soil carbon project for your farm.
      </v-alert>
      <p></p>
      <v-stepper
          v-if="soilForm.actions.formStarted"
          v-model="soilForm.step"
          vertical
      >
        <v-stepper-step
            :complete="soilForm.step > 1"
            step="1"
        >
          <v-btn text dense
                 :disabled="soilForm.step < 2"
                 @click="soilForm.step = 1">
            Farm
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>

              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="max-width: 500px;"
              >
                <v-select
                    dense
                    filled
                    solo
                    v-on:change="setPropertyName"
                    v-model="soilForm.property_id"
                    :items="propertyItems"
                    item-text="propertyName"
                    item-value="property_id"
                    label="Select Farm"
                    required
                >
                </v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="max-width: 500px;"
              >
                <v-text-field
                    v-model="soilForm.nature_of_farm"
                    label="Nature of farm"
                    required
                ></v-text-field>
                <small>Nature of the major farming enterprise in terms of area, e.g. Grazing, Cropping, Dairy
                  Farming</small>

              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <!--            :disabled="soilForm.property_id === null || soilForm.nature_of_farm === null"-->
              <v-btn
                  :disabled="soilForm.property_id === null"
                  color="primary"
                  @click="soilForm.step = 2"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>


        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 2"
            step="2"
        >
          <v-btn text dense
                 :disabled="soilForm.step < 3"
                 @click="soilForm.step = 2">
            Existing Actions - Part I
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="2">

          <v-card>
            <v-card-text>

              <p style="max-width: 500px;">
                There are a range of carbon related farming practices that may be currently in operation on the farm.
                This does not make the farm ineligible to participate, however it is important to identify all current
                and historic activities.
                Please complete the following table which outlines these
                activities on your farm.
              </p>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Farm Management Activity
                    </th>
                    <th class="text-left">
                      In Use / Has Been Used
                    </th>
                    <th class="text-left">
                      Extent of Farm (Area or %) and Other Details or Comments
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in soilForm.existing_actions"
                      :key="item.action"
                  >
                    <td>{{ item.display }}</td>
                    <td>
                      <v-checkbox style="padding-top: 0; padding-bottom: 0; "
                                  v-model="item.used"
                      >
                      </v-checkbox>
                    </td>
                    <td>
                      <v-text-field
                          v-model="item.extent"
                      ></v-text-field>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>


            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 1"
              >
                Back
              </v-btn>
              <v-btn
                  color="primary"
                  @click="soilForm.step = 3"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>

        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 3"
            step="3"
        >
          <v-btn text dense
                 :disabled="soilForm.step < 4"
                 @click="soilForm.step = 3">
            Existing Actions - Part II
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-textarea
                    name="existing actions"
                    label="Current or historic land management activities"
                    v-model="soilForm.existingActionsMoreDetails"
                    hint="Please provide details on any other current or historic land management activities on the farm,
              especially highlighting any novel practices that will continue within the soil carbon project area"
                ></v-textarea>
              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 2"
              >
                Back
              </v-btn>
              <v-btn
                  color="primary"
                  @click="soilForm.step = 4"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 4"
            step="4">
          <v-btn text dense
                 :disabled="soilForm.step < 5"
                 @click="soilForm.step = 4">
            Planned Actions
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card>
            <v-card-text>
              <v-col
                  cols="12"
                  md="6"
              >
                <p>Select the actions that you will commit to in order to improve ground cover on your farm</p>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr
                        v-for="item in soilForm.planned_actions"
                        :key="item.id"
                    >
                      <td>
                        <v-checkbox style="padding-top: 0; padding-bottom: 0;"
                                    v-model="item.selected"
                                    @change="set_chosen_activities"
                        >
                        </v-checkbox>
                      </td>
                      <td>{{ item.name }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 3"
              >
                Back
              </v-btn>

              <!--            :disabled="soilForm.chosen_activities.length < 1"-->
              <v-btn

                  color="primary"
                  @click="soilForm.step = 5"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 5"
            step="5">
          <v-btn text dense
                 :disabled="soilForm.step < 6"
                 @click="soilForm.step = 5">
            Grazing Management
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="5">
          <v-card>
            <v-card-text>
              <v-col
                  cols="12"
                  md="6"
              >
                <p style="max-width: 800px;">Please complete the farm management overview tables for permanent pasture
                  and for cropping rotations.
                  This provides a starting record for grazing with indicative stocking levels and the area of farmland
                  under permanent pasture (percentage or area),
                  in addition to the area of farmland cropped, the types of crops grown and the length of time under
                  crop.
                  The first table (shown below) is for grazing management and the following page includes a table for
                  cropping management.<br>
                  Note that for the purposes of the Emissions Reduction Fund, land that is continuously under any
                  combination of perennial grasses,
                  legumes, herbs or fodder plants, and on which production livestock is raised is defined as permanent
                  pasture.
                  Fields can be left blank if not applicable to your farm.</p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        year
                      </th>
                      <th class="text-left">
                        ha (or %)
                      </th>
                      <th class="text-left">
                        Cattle
                      </th>
                      <th class="text-left">
                        Sheep
                      </th>
                      <th class="text-left">
                        Other
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in soilForm.grazing_data"
                        :key="item.year"
                    >
                      <td>
                        {{ item.year }}
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.ha_or_pcnt"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.cattle"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.sheep"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.other"
                        ></v-text-field>
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 4"
              >
                Back
              </v-btn>

              <!--            :disabled="soilForm.chosen_activities.length < 1"-->
              <v-btn

                  color="primary"
                  @click="soilForm.step = 6"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 5"
            step="6">
          <v-btn text dense
                 :disabled="soilForm.step < 7"
                 @click="soilForm.step = 6">
            Cropping Management
          </v-btn>

        </v-stepper-step>
        <v-stepper-content step="6">
          <v-card>
            <v-card-text>
              <v-col
                  cols="12"
                  md="6"
              >
                <p style="max-width: 800px;">
                  Similar to the previous table, please enter the details regarding cropping management.<br>
                  Note that for the purposes of the Emissions Reduction Fund, land that is continuously under any
                  combination of perennial grasses, legumes, herbs or fodder plants, and on which production
                  livestock is raised is defined as permanent pasture. Fields can be left blank if not applicable to
                  your farm.</p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Year
                      </th>
                      <th class="text-left">
                        ha (or %)
                      </th>
                      <th class="text-left">
                        Cropping Details
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in soilForm.cropping_data"
                        :key="item.year"
                    >
                      <td>
                        {{ item.year }}
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.ha_or_pcnt"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            v-model="item.details"
                        ></v-text-field>
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 5"
              >
                Back
              </v-btn>

              <!--            :disabled="soilForm.chosen_activities.length < 1"-->
              <v-btn

                  color="primary"
                  @click="soilForm.step = 7"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 6"
            step="7">
          <v-btn text dense
                 :disabled="soilForm.step < 8"
                 @click="soilForm.step = 7">
            Grazing Methods - Part I
          </v-btn>
        </v-stepper-step>
        <v-stepper-content step="7">
          <v-card>
            <v-card-text>
              <v-col
                  cols="12"
                  md="6"
              >
                <p style="max-width: 800px;">
                  It is important to determine current or historic practices in use on the farm that relate to altering
                  grazing patterns
                  in response to pasture availability, and in particular how stocking rates and the duration or
                  intensity of grazing have been managed both historically and currently. Please indicate
                  whether any of the practices below have been implemented on your farm in the last ten years, and to
                  what extent.
                </p>

                <div>
                  <v-subheader>
                    Do you assess pastures and manage grazing on your farm by eye?
                  </v-subheader>

                  <v-radio-group
                      row
                      v-model="soilForm.assessByEye"
                  >
                    <v-radio
                        label="Yes"
                        value="Yes"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="No"
                    ></v-radio>
                  </v-radio-group>
                </div>

                <div>
                  <v-subheader>
                    Do you use any form of pasture feed budgeting tool on your farm to manage grazing?
                  </v-subheader>

                  <v-radio-group
                      row
                      v-model="soilForm.useBudgetTool"
                  >
                    <v-radio
                        label="Yes"
                        value="Yes"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="No"
                    ></v-radio>
                  </v-radio-group>

                  <v-card v-if="soilForm.useBudgetTool === 'Yes'">
                    <v-card-text>

                      <p>On a scale from 1 (rarely) to 5 (routinely), how often do you use the feed budgeting tool?</p>

                      <v-radio-group
                          row
                          dense
                          v-model="soilForm.budgetToolUsage"
                      >
                        <div v-for="rate in [1, 2, 3, 4, 5]" :key="rate">
                          <v-radio
                              :label="`${rate}`"
                              :value="rate"
                          ></v-radio>
                        </div>
                      </v-radio-group>

                      <p>Which of the following best describes your feed budgeting tool?</p>

                      <v-radio-group
                          dense
                          v-model="soilForm.budgetToolType"
                      >
                        <div v-for="item in soilForm.budgetToolItems" :key="item">
                          <v-radio
                              :label="item"
                              :value="item"
                          ></v-radio>
                        </div>
                      </v-radio-group>

                      <p>If you use a formal feed budgeting tool, developed by others, what is it called?</p>
                      <v-text-field
                          dense
                          v-model="soilForm.budgetToolName"
                      ></v-text-field>


                    </v-card-text>

                  </v-card>

                </div>

                <div>
                  <v-subheader>
                    Do you use a minimum residual pasture benchmark to guide grazing management practices?
                  </v-subheader>

                  <v-radio-group
                      row
                      v-model="soilForm.useMinBenchmark"
                  >
                    <v-radio
                        label="Yes"
                        value="Yes"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="No"
                    ></v-radio>
                  </v-radio-group>

                  <v-card v-if="soilForm.useMinBenchmark === 'Yes'">
                    <v-card-text>
                      <p>What is your target for the minimum residual benchmark (expressed in kg DM/ha)?</p>
                      <v-text-field
                          dense
                          v-model="soilForm.benchmarkTarget"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>

                </div>


              </v-col>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="soilForm.step = 6"
              >
                Back
              </v-btn>

              <!--            :disabled="soilForm.chosen_activities.length < 1"-->
              <v-btn

                  color="primary"
                  @click="soilForm.step = 8"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 7"
            step="8">
          <v-btn text dense
                 :disabled="soilForm.step < 9"
                 @click="soilForm.step = 8">
            Grazing Methods - Part II
          </v-btn>
        </v-stepper-step>
        <v-stepper-content step="8">
          <v-card>
            <v-card-text>

              <p style="max-width: 1000px;">
                Please indicate whether any of the tools / methods in the following form have been used at some point to
                manage grazing on your farm.
              </p>

              <v-card v-for="gz2Item in soilForm.grazing2items" :key="gz2Item.title" style="margin-bottom: 10px;">
                <v-card-title class="body-1">

                  <v-checkbox style="padding-top: 0; padding-bottom: 0;"
                              :label="gz2Item.title"
                              v-model="gz2Item.selected">
                  </v-checkbox>

                </v-card-title>
                <v-card-text v-if="gz2Item.selected">


                  <p>Frequency of use:</p>
                  <v-radio-group

                      dense
                      row
                      v-model="soilForm[gz2Item.value]"
                  >
                    <div v-for="item in soilForm.grazing2frequencies" :key="item">
                      <v-radio
                          :label="`${item}`"
                          :value="item"
                      ></v-radio>
                    </div>
                  </v-radio-group>

                </v-card-text>

              </v-card>


            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="secondary"
                  @click="soilForm.step = 7"
              >
                Back
              </v-btn>

              <!--            :disabled="soilForm.chosen_activities.length < 1"-->
              <v-btn

                  color="primary"
                  @click="soilForm.step = 9"
              >
                Next
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 8"
            step="9">
          <v-btn text dense
                 :disabled="soilForm.step < 10"
                 @click="soilForm.step = 9">
            Grazing Methods - Part III
          </v-btn>
        </v-stepper-step>
        <v-stepper-content step="9">
          <v-card>
            <v-card-text>

              <p style="max-width: 1000px;">
                Please provide a brief description on both current and historic grazing management practices on your
                farm.
              </p>

              <v-col v-if="soilForm.actions.generatePDFpressed">
                <v-progress-linear
                    indeterminate
                    color="yellow darken-2"
                >
                </v-progress-linear>
              </v-col>

              <v-text-field
                  v-model="soilForm.grazingPracticesMoreDetails"
              ></v-text-field>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  :disabled="soilForm.actions.generatePDFpressed"
                  color="secondary"
                  @click="soilForm.step = 8"
              >
                Back
              </v-btn>

              <v-btn
                  :disabled="soilForm.actions.generatePDFpressed"
                  color="primary"
                  @click="createCommitmentReportPDF"
              >
                Create PDF Report
              </v-btn>
              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">
                Save
              </v-btn>
              <v-btn
                  :disabled="soilForm.actions.generatePDFpressed"
                  text @click="cancelSoilForm">
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="soilForm.step > 9"
            step="10">
          <v-btn text dense
                 :disabled="soilForm.step < 11"
                 @click="soilForm.step = 10">
            Send Registration to AgriProve for Review
          </v-btn>
        </v-stepper-step>

        <v-stepper-content step="10">
          <v-card>
            <v-card-text>

              <!--              <p style="max-width: 1000px;">-->
              <!--                By clicking 'Send for Review' you are providing AgriProve the consent to submit your soil carbon project application to the Clean Energy Regulator. We will process your application to create and send the necessary reports to AgriProve. Finally, AgriProve will need to review the-->
              <!--                information provided to ensure that all necessary detail has been collected prior to submission. A PDF version of the Consent to Register - Commitment to Implement document will be emailed to you.-->
              <!--              </p>-->

              <p>
                Great! Now that the PDF has been generated, click the Download PDF button below to access it.
                Your next step is to add the current date and your signature to the final page.
                Then, upload the signed and dated PDF.
              </p>
              <v-btn
                  target="_blank"
                  :href="soilForm.actions.pdfUrl">Download PDF
              </v-btn>
              <p></p>

              <v-file-input
                  dense
                  outlined
                  show-size
                  :clearable="true"
                  color="primary"
                  accept=".pdf"
                  label="Select Signed Commitment Report (PDF)"
                  v-model="soilForm.actions.pdfFile"
              ></v-file-input>

              <v-col v-if="soilForm.actions.fileUploadInProgress">
                <v-progress-linear
                    indeterminate
                    color="yellow darken-2"
                >
                </v-progress-linear>
              </v-col>
              <p></p>

              <p style="max-width: 1000px;" v-if="soilForm.actions.pdfFile !== null">
                Finally, AgriProve will need to review your soil project
                registration to ensure that every necessary detail has been
                captured. By clicking "Send to AgriProve for Review", you are
                providing consent to AgriProve to submit your application
                to the Clean Energy Regulator.
              </p>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>

              <v-btn
                  :disabled="soilForm.actions.fileUploadInProgress"
                  color="secondary"
                  @click="soilForm.step = 9"
              >
                Back
              </v-btn>
              <v-btn
                  :disabled="soilForm.actions.pdfFile === null || soilForm.actions.fileUploadInProgress"
                  color="primary"
                  @click="uploadSignedCommitmentReportPDF"
              >
                Send to AgriProve for Review
              </v-btn>
              <!--              <v-btn color="green" dark @click="saveSoilForm" :disabled="soilForm.is_disable">-->
              <!--                Save-->
              <!--              </v-btn>-->

              <v-btn
                  :disabled="soilForm.actions.fileUploadInProgress"
                  text @click="cancelSoilForm">
                Cancel
              </v-btn>

            </v-card-actions>

          </v-card>
        </v-stepper-content>

      </v-stepper>

    </div>


    <v-dialog
        persistent
        v-model="show_no_edit_dialog"
        max-width="500"
    >

      <v-card>
        <v-card-title class="text-h5">
          Soil Project Status
        </v-card-title>
        <v-card-text>
          This soil project is already submitted to AgriProve and is not editable.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="show_no_edit_dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

    <v-col style="color: darkgreen;" v-if="soil_projects_list.length > 0">
      <h3>Project Status Codes</h3>
      <p>
        A1 - project form started and saved, but not signed<br>
        A2 - project form submitted.<br>
        A3 - Creating commitment PDF<br>
        A4 - Commitment PDF is ready to download<br>
        A5 - Signed PDF uploaded by client<br>
        A6 - Creating condition report PDF<br>
        A7 - Condition report PDF is ready<br>
      </p>
    </v-col>

    <!--    <pre>-->
    <!--      {{ soil_projects }}-->
    <!--    </pre>-->


  </v-container>

</template>

<script>
import {
  // createAgriProveProject,
  getDownloadSignedUrl,
  getUploadUrls,
  updateAgriproveProjectStatus,
  getClientSoilProjects,
  client_save_agriprove_project
} from '@/services/mfk_api'

export default {
  name: "RegistrationPDF",
  data() {
    return {
      show_no_edit_dialog: false,
      soil_projects: [],
      active_project_list_index: null,
      soil_projects_list: [],
      propertyItems: [],
      projects_table_headers: [
        {text: 'Project ID', value: 'project_id'},
        // {text: 'Property ID', value: 'property_id'},
        {text: 'Farm Name', value: 'property_name'},
        {text: 'Project Status', value: 'project_status'}
      ],
      soilForm: {
        is_disable: false,
        project_id: null,
        project_status: 'A1',
        project_name: null,
        actions: {
          formStarted: false,
          generatePDFpressed: false,
          pdfUrlInProgress: false,
          pdfUrlReceived: false,
          unsigned_pdf_file_name: null,
          signed_pdf_file_name: null,
          pdfUrl: '',
          pdfFile: null,
          fileUploadInProgress: false,
        },

        step: 0,
        property_id: null,
        property_name: null,
        nature_of_farm: null,
        existing_actions: [
          {action: 'cultivation', display: 'Cultivation of farmland', used: false, extent: null},
          {action: 'fire', display: 'Use of fire to burn stubble', used: false, extent: null},
          {
            action: 'synthetic-fertilisers',
            display: 'Use of synthetic fertilisers containing nitrogen',
            used: false,
            extent: null
          },
          {
            action: 'organic-fertilisers',
            display: 'Use of organic fertilisers such as guano',
            used: false,
            extent: null
          },
          {action: 'mineral-additives', display: 'Mineral additives', used: false, extent: null},
          {
            action: 'compost',
            display: 'Compost (if yes, please specify type of compost used and whether liquid extracts have been used)',
            used: false,
            extent: null
          },
          {
            action: 'renovation',
            display: 'Pasture renovation (spray out and reseed) - if yes please specify plant species',
            used: false,
            extent: null
          },
          {
            action: 'direct-drilling',
            display: 'Direct drilling of seed into existing pasture (if yes, please specify plant species sown and whether any microbial inoculants were used)',
            used: false,
            extent: null
          },
          {
            action: 'other-soil-additives',
            display: 'Other soil additives or amendments (if yes, please specify types and use)',
            used: false,
            extent: null
          },
          {
            action: 'grazing-management',
            display: 'Grazing management for soil carbon sequestration',
            used: false,
            extent: null
          },
        ],
        existingActionsMoreDetails: null,
        chosen_activities: [],

        planned_actions: [
          // { // Void - v3
          //   id: 0,
          //   selected: false,
          //   display: 'Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency (for example, adding recycled organics)',
          // },
          // {// Void - v3
          //   id: 1,
          //   selected: false,
          //   display: 'Re-establishing or rejuvenating a pasture by seeding (for example, mixed species forage seeding)'
          // },
          // {// Void - v3
          //   id: 2,
          //   selected: false,
          //   display: 'Establishing, and permanently maintaining, a pasture where there was previously no pasture, such as on cropland or bare fallow (for example, converting cropland to pasture)'
          // },
          // {// Void - v3
          //   id: 3,
          //   selected: false,
          //   display: 'Altering the stocking rate, duration or intensity of grazing (for example, "rotational grazing" or "emergent leaf grazing")'
          // },

          {
            id: 4,
            "cer_id": "5de03f77-213c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency",
          }, {
            id: 5,
            "cer_id": "c72d2c8d-213c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Applying lime or other ameliorants to remediate acid soils"
          }, {
            id: 6,
            "cer_id": "a8d043b8-213c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Applying gypsum to remediate sodic or magnesic soils"
          }, {
            id: 7,
            "cer_id": "6d5c4207-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Undertaking new irrigation"
          }, {
            id: 8,
            "cer_id": "6949251b-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Re-establishing or rejuvenating a pasture by seeding or pasture cropping"
          }, {
            id: 9,
            "cer_id": "bc230d36-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Establishing, and permanently maintaining, a pasture where there was previously no or limited pasture, such as on cropland or fallow"
          }, {
            id: 10,
            "cer_id": "b295ce4b-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Altering the stocking rate, duration or intensity of grazing to promote soil vegetation cover or improve soil health, or both"
          }, {
            id: 11,
            "cer_id": "ea948983-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Retaining stubble after a crop is harvested"
          }, {
            id: 12,
            "cer_id": "abee629f-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Converting from intensive tillage practices to reduced or no tillage practices"
          }, {
            id: 13,
            "cer_id": "059d95b1-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Modifying landscape or landform features to remediate land"
          }, {
            id: 14,
            "cer_id": "e1a90dd1-223c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Using mechanical means to add or redistribute soil through the soil profile"
          }, {
            id: 15,
            "cer_id": "e004384a-233c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Using legume species in cropping or pasture systems"
          }, {
            id: 16,
            "cer_id": "0f2f6963-233c-ec11-8c62-0022481108a0",
            selected: false,
            "name": "Using a cover crop to promote soil vegetation cover or improve soil health, or both"
          }
        ],
        grazing_data: {
          2010: {ha_or_pcnt: null, cattle: null, sheep: null, other: null, year: 2010},
        },
        cropping_data: {
          2010: {ha_or_pcnt: null, details: null, year: 2010},
        },
        assessByEye: 'No',
        useBudgetTool: 'No',
        useMinBenchmark: 'No',
        budgetToolUsage: null,
        budgetToolType: null,
        budgetToolItems: [
          'Paper Based Notes',
          'Paper Based Template',
          'Spreadsheet',
          'Other Digital Tool',
          'Not Applicable',
        ],
        budgetToolName: null,
        benchmarkTarget: null,

        frequencyOfRainfallModel: 'N/A',
        frequencyOfMoistureModel: 'N/A',
        frequencyOfWeatherForecasts: 'N/A',
        frequencyOfAnyPractice: 'N/A',
        frequencyOfManagementSoftware: 'N/A',
        frequencyOfForecastUpdate: 'N/A',

        grazing2items: {
          frequencyOfRainfallModel: {
            selected: false,
            value: 'frequencyOfRainfallModel',
            title: 'Use of predictive rainfall models to forecast pasture biomass availability.'
          },
          frequencyOfMoistureModel: {
            selected: false,
            value: 'frequencyOfMoistureModel',
            title: 'Use of predictive soil moisture models to forecast pasture biomass availability'
          },
          frequencyOfWeatherForecasts: {
            selected: false,
            value: 'frequencyOfWeatherForecasts',
            title: 'Use of regular weather forecasts incorporating both historic data and predictive models to refine predictions of pasture availability.'
          },
          frequencyOfAnyPractice: {
            selected: false,
            value: 'frequencyOfAnyPractice',
            title: 'Use of any of the practices outlined above to adjust stocking rates to optimise pasture availability and animal health.'
          },
          frequencyOfManagementSoftware: {
            selected: false,
            value: 'frequencyOfManagementSoftware',
            title: 'Use of agricultural management tools or software to assist in making farm management decisions.'
          },
          frequencyOfForecastUpdate: {
            selected: false,
            value: 'frequencyOfForecastUpdate',
            title: 'Use of forecast models to assist in grazing management'
          },
        },
        grazing2frequencies: [
          'N/A',
          'Daily',
          'Weekly',
          'Monthly',
          'Bi-Monthly',
          'Quarterly',
          'Yearly',
        ],
        grazingPracticesMoreDetails: null,
      }
    }
  },
  methods: {

    async saveSoilForm() {
      console.log('soil form in STEP')
      let project_attributes;
      let project_id
      if (this.soilForm.project_id === null) {
        project_id = null
        project_attributes = {
          version: 3,
          accepted_terms: true,
          owner_name: this.$store.state.userDict.owner_name,
          owner_email: this.$store.state.userDict.email_address
        }
      } else {
        project_id = this.soilForm.project_id
        project_attributes = this.soil_projects[this.active_project_list_index].project_attributes
      }

      project_attributes.step = this.soilForm.step
      project_attributes.submitted_form = {
        property_id: this.soilForm.property_id,
        property_name: this.soilForm.property_name,
        chosen_activities: this.soilForm.chosen_activities,
        name: this.$store.state.userDict.owner_name,
        nature_of_farm: this.soilForm.nature_of_farm,
        cropping_data: this.soilForm.cropping_data,
        grazing_data: this.soilForm.grazing_data,
        existing_actions: this.soilForm.existing_actions,
        existingActionsMoreDetails: this.soilForm.existingActionsMoreDetails,
        assessByEye: this.soilForm.assessByEye,
        useBudgetTool: this.soilForm.useBudgetTool,
        budgetToolUsage: this.soilForm.budgetToolUsage,
        budgetToolType: this.soilForm.budgetToolType,
        budgetToolName: this.soilForm.budgetToolName,
        useMinBenchmark: this.soilForm.useMinBenchmark,
        benchmarkTarget: this.soilForm.benchmarkTarget,
        frequencyOfRainfallModel: this.soilForm.frequencyOfRainfallModel,
        frequencyOfMoistureModel: this.soilForm.frequencyOfMoistureModel,
        frequencyOfWeatherForecasts: this.soilForm.frequencyOfWeatherForecasts,
        frequencyOfAnyPractice: this.soilForm.frequencyOfAnyPractice,
        frequencyOfManagementSoftware: this.soilForm.frequencyOfManagementSoftware,
        frequencyOfForecastUpdate: this.soilForm.frequencyOfForecastUpdate,
        grazingPracticesMoreDetails: this.soilForm.grazingPracticesMoreDetails
      }

      const property_id = this.soilForm.property_id
      const chosen_actions = this.soilForm.chosen_activities
      const project_name = this.soilForm.property_name
      const project_status = this.soilForm.project_status

      console.log('before saving')
      console.log(project_id, property_id, chosen_actions, project_name, project_status, project_attributes)
      this.soilForm.project_id = await client_save_agriprove_project(project_id, property_id, chosen_actions, project_name, project_status, project_attributes)
      console.log(this.soilForm.project_id)
      await this.getClientSoilProjects()

      for (let project_list_item of this.soil_projects_list) {
        if (project_list_item.project_id === project_id) {
          this.on_click_row(project_list_item)
          break;
        }
      }

    },
    on_click_row(item) {

      this.active_project_list_index = item.list_index

      const project_item = this.soil_projects[item.list_index]

      if ([
        'A1', 'A2', 'A3',
        'A4'
      ].includes(project_item.project_status)) {
        const project_attributes = project_item.project_attributes
        const submitted_form = project_item.project_attributes.submitted_form

        this.soilForm.step = 1
        // let step = 11
        if ('step' in project_attributes) {
          this.soilForm.step = project_attributes.step
        }

        this.soilForm.project_id = project_item.project_id
        this.soilForm.chosen_activities = project_item.chosen_actions

        for (let item_key of Object.keys(submitted_form)) {
          if (item_key in this.soilForm) {
            this.soilForm[item_key] = submitted_form[item_key]
          }
        }

        for (let chosen_action of project_item.chosen_actions) {
          for (let planned_action of this.soilForm.planned_actions) {
            if (planned_action.id === parseInt(chosen_action)) {
              planned_action.selected = true
            }
          }
        }

        for (let item of ['frequencyOfRainfallModel', 'frequencyOfMoistureModel', 'frequencyOfWeatherForecasts', 'frequencyOfAnyPractice', 'frequencyOfManagementSoftware', 'frequencyOfForecastUpdate']) {
          if (this.soilForm[item] !== "N/A") {
            this.soilForm.grazing2items[item].selected = true
          }
        }

        this.soilForm.actions.formStarted = true

        this.propertyItems = []
        for (const property_id of Object.keys(this.$store.state.properties)) {
          this.propertyItems.push({
            'propertyName': this.$store.state.properties[property_id].inf.property_name, 'property_id': property_id
          })
        }
      } else {
        this.show_no_edit_dialog = true
      }


    },

    async getClientSoilProjects() {

      let property_ids = []
      for (let propId of Object.keys(this.$store.state.properties)) {
        property_ids.push(propId)
      }

      this.soil_projects = await getClientSoilProjects(property_ids)

      let cnt = -1
      this.soil_projects_list = []
      for (let project_item of this.soil_projects) {
        cnt += 1
        this.soil_projects_list.push({
          list_index: cnt,
          project_id: project_item.project_id,
          property_id: project_item.property_id,
          property_name: this.$store.state.properties[project_item.property_id].inf.property_name,
          project_status: project_item.project_status
        })
      }

      console.log('this.soil_projects')
      console.log(this.soil_projects)
      console.log('this.soil_projects_list')
      console.log(this.soil_projects_list)
      console.log('-------------------')


    },

    initSoilForm() {
      this.soilForm.actions.formStarted = true
      this.soilForm.step = 1
      // this.soilForm.step = 10 // CHANGED
      const now = new Date()
      let cur_year = now.getFullYear()
      this.soilForm.grazing_data = {}
      // for (let year = 2010; year < now.getFullYear(); year++) {
      //   this.soilForm.grazing_data[year] = {ha_or_pcnt: null, cattle: null, sheep: null, other: null, year: year}
      // }
      for (let inc of [0, 1, 2, 3, 4]) {
        let this_year = cur_year - inc
        this.soilForm.grazing_data[this_year] = {
          ha_or_pcnt: null,
          cattle: null,
          sheep: null,
          other: null,
          year: this_year
        }
      }

      this.soilForm.cropping_data = {}
      // for (let year = 2010; year < now.getFullYear(); year++) {
      //   this.soilForm.cropping_data[year] = {ha_or_pcnt: null, details: null, year: year}
      // }
      for (let inc of [0, 1, 2, 3, 4]) {
        let this_year = cur_year - inc
        this.soilForm.cropping_data[this_year] = {ha_or_pcnt: null, details: null, year: this_year}
      }

      this.propertyItems = []
      for (const property_id of Object.keys(this.$store.state.properties)) {
        console.log('property_id')
        console.log(property_id)
        console.log(this.$store.state.properties[property_id].inf.property_name)

        this.propertyItems.push({
          'propertyName': this.$store.state.properties[property_id].inf.property_name, 'property_id': property_id
        })
      }

    },
    cancelSoilForm() {
      this.soilForm.actions.formStarted = false
      this.soilForm.actions.project_id = null
      this.soilForm.step = 0
      this.soilForm.grazing_data = {}
      this.soilForm.cropping_data = {}
      this.propertyItems = []
      this.soilForm.actions = {
        formStarted: false,
        generatePDFpressed: false,
        pdfUrlInProgress: false,
        pdfUrlReceived: false,
        unsigned_pdf_file_name: null,
        signed_pdf_file_name: null,
        pdfUrl: '',
        pdfFile: null,
        fileUploadInProgress: false,

      }

      this.soilForm.property_id = null
      this.soilForm.property_name = null
      this.soilForm.nature_of_farm = null
      this.soilForm.existing_actions = [
        {action: 'cultivation', display: 'Cultivation of farmland', used: false, extent: null},
        {action: 'fire', display: 'Use of fire to burn stubble', used: false, extent: null},
        {
          action: 'synthetic-fertilisers',
          display: 'Use of synthetic fertilisers containing nitrogen',
          used: false,
          extent: null
        },
        {action: 'organic-fertilisers', display: 'Use of organic fertilisers such as guano', used: false, extent: null},
        {action: 'mineral-additives', display: 'Mineral additives', used: false, extent: null},
        {
          action: 'compost',
          display: 'Compost (if yes, please specify type of compost used and whether liquid extracts have been used)',
          used: false,
          extent: null
        },
        {
          action: 'renovation',
          display: 'Pasture renovation (spray out and reseed) - if yes please specify plant species',
          used: false,
          extent: null
        },
        {
          action: 'direct-drilling',
          display: 'Direct drilling of seed into existing pasture (if yes, please specify plant species sown and whether any microbial inoculants were used)',
          used: false,
          extent: null
        },
        {
          action: 'other-soil-additives',
          display: 'Other soil additives or amendments (if yes, please specify types and use)',
          used: false,
          extent: null
        },
        {
          action: 'grazing-management',
          display: 'Grazing management for soil carbon sequestration',
          used: false,
          extent: null
        },
      ]
      this.soilForm.existingActionsMoreDetails = null
      this.soilForm.chosen_activities = null
      this.soilForm.planned_actions = [
        {
          id: 0,
          selected: false,
          display: 'Applying nutrients to the land in the form of a synthetic or non-synthetic fertiliser to address a material deficiency (for example, adding recycled organics)'
        },
        {
          id: 1,
          selected: false,
          display: 'Re-establishing or rejuvenating a pasture by seeding (for example, mixed species forage seeding)'
        },
        {
          id: 2,
          selected: false,
          display: 'Establishing, and permanently maintaining, a pasture where there was previously no pasture, such as on cropland or bare fallow (for example, converting cropland to pasture)'
        },
        {
          id: 3,
          selected: false,
          display: 'Altering the stocking rate, duration or intensity of grazing (for example, "rotational grazing" or "emergent leaf grazing")'
        },
      ]
      this.soilForm.grazing_data = {
        2010: {ha_or_pcnt: null, cattle: null, sheep: null, other: null, year: 2010},
      }
      this.soilForm.cropping_data = {
        2010: {ha_or_pcnt: null, details: null, year: 2010},
      }
      this.soilForm.assessByEye = 'No'
      this.soilForm.useBudgetTool = 'No'
      this.soilForm.useMinBenchmark = 'No'
      this.soilForm.budgetToolUsage = null
      this.soilForm.budgetToolType = null

      this.soilForm.budgetToolName = null
      this.soilForm.benchmarkTarget = null

      this.soilForm.frequencyOfRainfallModel = 'N/A'
      this.soilForm.frequencyOfMoistureModel = 'N/A'
      this.soilForm.frequencyOfWeatherForecasts = 'N/A'
      this.soilForm.frequencyOfAnyPractice = 'N/A'
      this.soilForm.frequencyOfManagementSoftware = 'N/A'
      this.soilForm.frequencyOfForecastUpdate = 'N/A'
      this.soilForm.grazing2items = {
        frequencyOfRainfallModel: {
          selected: false,
          value: 'frequencyOfRainfallModel',
          title: 'Use of predictive rainfall models to forecast pasture biomass availability.'
        },
        frequencyOfMoistureModel: {
          selected: false,
          value: 'frequencyOfMoistureModel',
          title: 'Use of predictive soil moisture models to forecast pasture biomass availability'
        },
        frequencyOfWeatherForecasts: {
          selected: false,
          value: 'frequencyOfWeatherForecasts',
          title: 'Use of regular weather forecasts incorporating both historic data and predictive models to refine predictions of pasture availability.'
        },
        frequencyOfAnyPractice: {
          selected: false,
          value: 'frequencyOfAnyPractice',
          title: 'Use of any of the practices outlined above to adjust stocking rates to optimise pasture availability and animal health.'
        },
        frequencyOfManagementSoftware: {
          selected: false,
          value: 'frequencyOfManagementSoftware',
          title: 'Use of agricultural management tools or software to assist in making farm management decisions.'
        },
        frequencyOfForecastUpdate: {
          selected: false,
          value: 'frequencyOfForecastUpdate',
          title: 'Use of forecast models to assist in grazing management'
        },
      }
      this.grazingPracticesMoreDetails = null
    },

    setPropertyName() {
      this.soilForm.property_name = this.$store.state.properties[this.soilForm.property_id].inf.property_name
    },
    set_chosen_activities() {
      this.soilForm.chosen_activities = []
      for (let item of this.soilForm.planned_actions) {
        if (item.selected) {
          this.soilForm.chosen_activities.push(item.id)
        }
      }
      console.log(this.soilForm.chosen_activities)
    },
    async createCommitmentReportPDF() {
      this.soilForm.actions.generatePDFpressed = true
      this.soilForm.project_status = 'A2'
      await this.saveSoilForm()
      // const property_id = this.soilForm.property_id
      // const chosen_actions = this.soilForm.chosen_activities
      // const project_name = this.soilForm.property_name
      // const project_attributes = {
      //   version: 3,
      //   accepted_terms: true,
      //   owner_name: this.$store.state.userDict.owner_name,
      //   owner_email: this.$store.state.userDict.email_address,
      //   submitted_form: {
      //     property_id: this.soilForm.property_id,
      //     property_name: this.soilForm.property_name,
      //     chosen_activities: this.soilForm.chosen_activities,
      //     name: this.$store.state.userDict.owner_name,
      //     nature_of_farm: this.soilForm.nature_of_farm,
      //     cropping_data: this.soilForm.cropping_data,
      //     grazing_data: this.soilForm.grazing_data,
      //     existing_actions: this.soilForm.existing_actions,
      //     existingActionsMoreDetails: this.soilForm.existingActionsMoreDetails,
      //     assessByEye: this.soilForm.assessByEye,
      //     useBudgetTool: this.soilForm.useBudgetTool,
      //     budgetToolUsage: this.soilForm.budgetToolUsage,
      //     budgetToolType: this.soilForm.budgetToolType,
      //     budgetToolName: this.soilForm.budgetToolName,
      //     useMinBenchmark: this.soilForm.useMinBenchmark,
      //     benchmarkTarget: this.soilForm.benchmarkTarget,
      //     frequencyOfRainfallModel: this.soilForm.frequencyOfRainfallModel,
      //     frequencyOfMoistureModel: this.soilForm.frequencyOfMoistureModel,
      //     frequencyOfWeatherForecasts: this.soilForm.frequencyOfWeatherForecasts,
      //     frequencyOfAnyPractice: this.soilForm.frequencyOfAnyPractice,
      //     frequencyOfManagementSoftware: this.soilForm.frequencyOfManagementSoftware,
      //     frequencyOfForecastUpdate: this.soilForm.frequencyOfForecastUpdate,
      //     grazingPracticesMoreDetails: this.soilForm.grazingPracticesMoreDetails
      //   }
      // }
      // const project_status = 'A2'
      // this.soilForm.project_id = await createAgriProveProject(property_id, chosen_actions, project_name, project_status, project_attributes)

      this.soilForm.actions.unsigned_pdf_file_name = `${this.soilForm.project_id}-${this.soilForm.property_id}-${this.soilForm.property_name}-confirmation-of-commitments.pdf`;
      this.soilForm.actions.signed_pdf_file_name = `${this.soilForm.project_id}-${this.soilForm.property_id}-${this.soilForm.property_name}-confirmation-of-commitments-signed.pdf`;

      let bucket = 'agriprove-dev'
      if (this.$store.state.stage === 'prod') bucket = 'cibo-cer-applications'

      setTimeout(async () => {
        this.soilForm.actions.pdfUrl = await getDownloadSignedUrl(
            bucket,
            this.soilForm.actions.unsigned_pdf_file_name
        )

      }, 2000)

      this.soilForm.actions.generatePDFpressed = false
      this.soilForm.step = 10
    },

    async uploadSignedCommitmentReportPDF() {
      this.soilForm.actions.fileUploadInProgress = true

      let bucket = 'agriprove-dev'
      if (this.$store.state.stage === 'prod') bucket = 'cibo-cer-applications'

      const resp = await getUploadUrls([{
        bucket_name: bucket,
        key_name: this.soilForm.actions.signed_pdf_file_name,
        file_type: this.soilForm.actions.pdfFile.type

      }])
      const upload_url = resp.data[0]
      const upload_response = await fetch(upload_url,
          {
            method: "PUT",
            headers: {'Content-Type': this.soilForm.actions.pdfFile.type},
            body: this.soilForm.actions.pdfFile
          });
      console.log(upload_response)

      await updateAgriproveProjectStatus(this.soilForm.project_id, 'A5')
      this.soilForm.actions.fileUploadInProgress = false
      this.cancelSoilForm()
    },

  },

  // updated() {
  //   this.getClientSoilProjects()
  // }
}
</script>

<style scoped>

</style>
