<template>
  <v-container>

    <div v-show="$store.state.userPlans.length > 0">
      <v-col
          v-if="!deleteClient.isActive && !addClient.isActive"
          style="max-width: 400px;"
          class="d-flex"
          cols="12"
          sm="6"
      >
        <v-select

            :items="$store.state.userPlans"
            v-model="selectedPlanId"
            item-text="plan_name"
            item-value="plan_id"
            label="Select Plan"
            v-on:change="onChangePlan"
        ></v-select>
      </v-col>

      <v-card v-if="selectedPlan !== null && !deleteClient.isActive && !addClient.isActive" max-width="500">

        <v-card-text>

          <p>
            Plan Name: {{ selectedPlan.plan_name }}<br>
            Property Key Price: ${{ selectedPlan.property_key_price }}<br>
            Benchmark Report Price: ${{ selectedPlan.benchmark_report_price }}<br>
            Forest Carbon Report Price: ${{ selectedPlan.carbon_report_price }}<br>
          </p>

          <v-list dense>
            <v-subheader>Plan Owners</v-subheader>
            <v-list-item-group
                v-model="selectedItem1"
                color="primary"
            >
              <v-list-item
                  v-for="owner_email in selectedPlan.owner_emails"
                  :key="owner_email"
              >
                <v-list-item-icon>
                  <v-icon color="green">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="owner_email"></v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <!--                <v-btn icon>-->
                <!--                  <v-icon>mdi-delete</v-icon>-->
                <!--                </v-btn>-->
              </v-list-item>
            </v-list-item-group>

            <v-subheader>Authorized Clients</v-subheader>
            <v-list-item-group
                v-model="selectedItem2"
                color="primary"
            >
              <v-list-item
                  v-for="authorised_client_email in selectedPlan.authorised_client_emails"
                  :key="authorised_client_email"
              >
                <v-list-item-icon>
                  <v-icon color="purple">mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="authorised_client_email"></v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="onDeleteClient(authorised_client_email)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </v-list-item-group>

          </v-list>

        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="primary" dark @click="onAddClient">
            <v-icon left>mdi-plus</v-icon>
            Add Authorized Clients
          </v-btn>
        </v-card-actions>

      </v-card>

      <v-card max-width="400" v-if="deleteClient.isActive">

        <v-card-text>
          Are you sure you want to remove<br>
          <b style="color: orangered;">{{ deleteClient.clientEmail }}</b><br>
          from this plan's authorised users?
          <v-progress-linear v-if="deleteClient.inProgress" color="orange" indeterminate>

          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <v-btn class="warning" @click="deleteAuthorizedClient" :disabled="deleteClient.inProgress">
            <v-icon left>mdi-delete</v-icon>
            Delete User
          </v-btn>
          <v-btn text @click="cancelDeleteAuthorizedClient" :disabled="deleteClient.inProgress">
            <v-icon left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>

      </v-card>

      <v-card max-width="400" v-if="addClient.isActive">

        <v-card-text>
          Please enter the email addresses of the clients that you would like
          to authorise to use this plan. Separate each of the email addresses by
          a comma.
          <v-col
              cols="12"
              sm="12"
              md="12"
              style="max-width: 500px;"
          >
            <v-text-field
                v-model="addClient.clientEmails"
                label="Email(s)"
                required
            ></v-text-field>
          </v-col>

          <v-progress-linear v-if="addClient.inProgress" color="orange" indeterminate>

          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary" @click="addAuthorizedClient" :disabled="addClient.inProgress">
            <v-icon left>mdi-plus</v-icon>
            Add Client
          </v-btn>
          <v-btn text @click="cancelAddAuthorizedClient" :disabled="addClient.inProgress">
            <v-icon left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>

      </v-card>

    </div>
  </v-container>
</template>

<script>


import {removePlanAuthorizedClients, addPlanAuthorizedClients} from '@/services/mfk_api'

export default {
  name: "AgentAdminComponent",
  data() {
    return {
      selectedPlanId: null,
      selectedPlanIndex: null,
      selectedPlan: null,

      selectedItem1: null,
      selectedItem2: null,

      deleteClient: {
        isActive: false,
        clientEmail: null,
        inProgress: false,
      },
      addClient: {
        isActive: false,
        clientEmails: null,
        inProgress: false,
      },


    }
  },
  methods: {
    onChangePlan() {

      for (let planIndex in this.$store.state.userPlans) {
        console.log(planIndex)
        if (this.$store.state.userPlans[planIndex].plan_id === this.selectedPlanId) {
          this.selectedPlanIndex = planIndex
          this.selectedPlan = this.$store.state.userPlans[this.selectedPlanIndex]
        }
      }

      console.log(this.selectedPlanId)
      console.log(this.selectedPlanIndex)
      console.log(this.$store.state.userPlans[this.selectedPlanIndex])
      console.log(this.$store.state.userPlans)
    },
    onDeleteClient(authorised_client_email) {
      this.deleteClient.isActive = true
      this.deleteClient.clientEmail = authorised_client_email
    },
    cancelDeleteAuthorizedClient(){
      this.deleteClient.isActive = false
      this.deleteClient.clientEmail = null
      this.deleteClient.inProgress = false
    },
    async deleteAuthorizedClient() {
      this.deleteClient.inProgress = true
      await removePlanAuthorizedClients(this.selectedPlanId, this.deleteClient.clientEmail)

      this.deleteClient.isActive = false
      this.deleteClient.clientEmail = null
      this.onChangePlan()
      this.$forceUpdate()
      this.deleteClient.inProgress = false
    },

    onAddClient() {
      this.addClient.isActive = true

    },
    cancelAddAuthorizedClient(){
      this.addClient.isActive = false
      this.addClient.clientEmail = null
      this.addClient.inProgress = false
    },
    async addAuthorizedClient() {
      console.log('add client')
      this.addClient.inProgress = true
      await addPlanAuthorizedClients(this.selectedPlanId, this.addClient.clientEmails)

      this.addClient.isActive = false
      this.addClient.clientEmails = null
      this.onChangePlan()
      this.$forceUpdate()
      this.addClient.inProgress = false
    }
  },


}
</script>

<style scoped>

</style>
