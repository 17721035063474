<template>
  <div>

    <v-tabs
        v-if="hasAccessToView"
        align-with-title>
      <v-tabs-slider color="purple"></v-tabs-slider>

      <v-tab href="#reg" v-if="isAuthClient || isAgriProveAdmin">
        Project Registration
      </v-tab>
      <v-tab href="#admin" v-if="isAgriProveAdmin">
        AgriProve Admin
      </v-tab>

      <!--      <v-tab h
      -->

      <v-tabs-slider color="purple"></v-tabs-slider>

      <v-tab-item id="reg" key="reg" v-if="isAuthClient || isAgriProveAdmin">
        <SoilRegistrationForm></SoilRegistrationForm>
      </v-tab-item>

      <v-tab-item id="admin" key="admin" v-if="isAgriProveAdmin">
        <SoilProjectsAdmin></SoilProjectsAdmin>
      </v-tab-item>

      <!--      <v-tab-item id="admin2" key="admin2" v-if="isAgriProveAdmin">-->
      <!--        <SoilProjectsAdmin2></SoilProjectsAdmin2>-->
      <!--      </v-tab-item>-->


    </v-tabs>

    <v-card v-if="showNoAccessMsg">

      <v-card-text>
        You don't have access to AgriProve soil registration form. Contact AgriProve if you need access.
      </v-card-text>


    </v-card>

  </div>


</template>

<script>
import SoilRegistrationForm from "../components/AgriProve/SoilRegistrationForm";
import SoilProjectsAdmin from "../components/AgriProve/SoilProjectsAdmin";
// import SoilProjectsAdmin2 from "../components/AgriProve/SoilProjectsAdmin2";
import {getAllPlans, getAgriProveSoilProjects} from '@/services/mfk_api'

export default {
  name: "AgriProveView",
  components: {
    SoilRegistrationForm,
    SoilProjectsAdmin,
    // SoilProjectsAdmin2
  },
  data() {
    return {
      isAgriProveAdmin: false,
      isAuthClient: false,
      hasAccessToView: false,
      showNoAccessMsg: false,
    }
  },
  methods: {
    async checkAccess() {
      const userEmail = this.$auth.user.email
      const userDomain = userEmail.split('@')[1]
      const authDomainsList = ['agriprove.io', 'cibolabs.com.au']

      if (authDomainsList.includes(userDomain)) {
        this.isAgriProveAdmin = true
        this.hasAccessToView = true

        let isInNav = false
        for (let navItem of this.$store.state.navItems) {
          if (navItem.title === 'AgriProve') {
            isInNav = true
          }
        }

        if (!isInNav) {
          this.$store.state.navItems.push({title: 'AgriProve', icon: 'mdi-file-certificate', link: '/agriprove'})
        }

        getAgriProveSoilProjects()
      } else {
        this.isAgriProveAdmin = false
        this.hasAccessToView = false
      }

      await getAllPlans()

      for (let planItem of this.$store.state.allPlans) {
        if (planItem.plan_name === 'AgriProve Plan') {
          console.log(planItem)
          if (planItem.authorised_client_emails.includes(userEmail)) {
            this.hasAccessToView = true
            this.isAuthClient = true
            this.$store.state.navItems.push({title: 'AgriProve', icon: 'mdi-file-certificate', link: '/agriprove'})
          }

        }

      }

      if (!this.isAgriProveAdmin && !this.isAuthClient) {
        this.showNoAccessMsg = true
      }


    },

  },

  mounted() {

    this.checkAccess()

  }
}

</script>

<style scoped>

</style>
