import { render, staticRenderFns } from "./ShopView.vue?vue&type=template&id=f41386f2&scoped=true&"
import script from "./ShopView.vue?vue&type=script&lang=js&"
export * from "./ShopView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f41386f2",
  null
  
)

export default component.exports