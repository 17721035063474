import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
// import VueSignaturePad from 'vue-signature-pad';
import store from './store'

if (process.env.NODE_ENV === 'production') {
    console.log = function () {
    };
}

// Vue.use(VueSignaturePad)

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
