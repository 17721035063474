<template>

  <div v-show="$store.state.auth0_does_owner_exist">
    <v-progress-linear
        v-if="!$store.state.initAllLoaded"
        indeterminate
        color="orange"
    ></v-progress-linear>

    <v-navigation-drawer
        hide-overlay absolute height="100%"
        style="z-index: 10000;"
        ref="leftDrawer" :width="leftDrawer.width"
        v-model="leftDrawer.show">

      <v-app-bar
          flat dense
          color="blue"
      >
        <v-btn icon @click="decreaseDrawerLeft">
          <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="increaseDrawerLeft">
          <v-icon color="white">mdi-chevron-right</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="switchDrawerLeft">
          <v-icon color="white">mdi-window-minimize</v-icon>
        </v-btn>

      </v-app-bar>

      <v-col style="min-height: 50px;">
        <div id="geocoder"></div>
      </v-col>
      <v-col>
        <small style="color: white;">{{ property_id }}</small>
      </v-col>

      <!--      ADD property Button-->
      <v-col v-if="!addPropertyOn & !editPropertyOn">
        <v-btn @click="addProperty"
        >Add Farm
        </v-btn>
      </v-col>

      <v-col v-if="cuPropertyInProgress">
        <p v-if="addPropertyOn">Adding new farm ...</p>
        <p v-if="editPropertyOn">Waiting for the edits to upload ...</p>
        <v-progress-linear
            indeterminate
            color="yellow darken-2"
        ></v-progress-linear>
      </v-col>

      <!--      Add property form-->
      <v-col v-if="(addPropertyOn || editPropertyOn) & !cuPropertyInProgress">

        <v-stepper
            v-model="cuPropertyStepper"
            vertical
        >

          <h3 v-if="addPropertyOn" style="margin: 10px;">
            Add Farm
          </h3>

          <h3 v-if="editPropertyOn" style="margin: 10px;">
            Edit Farm
          </h3>

          <v-stepper-items>
            <v-stepper-step
                :complete="cuPropertyStepper > 1"
                step="1"
            >
              Farm Name & ID
            </v-stepper-step>

            <v-stepper-content step="1">

              <v-text-field
                  v-model="cuProperty.pic_code"
                  label="Property Identification Code (optional)"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="cuProperty.property_name"
                  label="Farm Name"
                  required
              ></v-text-field>

              <v-btn
                  color="primary"
                  @click="cuPropertyStepper = 2"
                  :disabled="checkEmpty(cuProperty.property_name)"
              >
                Next
              </v-btn>
              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>

            <v-stepper-step
                :complete="cuPropertyStepper > 2"
                step="2"
            >
              Owner Information
            </v-stepper-step>

            <v-stepper-content step="2">

              <v-checkbox
                  v-model="cuProperty.isOwner"
                  :label="'I am the owner'"
                  @change="onChangeIsOwner()"
              >
              </v-checkbox>

              <div v-if="!cuProperty.isOwner">
                <v-text-field
                    v-model="cuProperty.owner_name"
                    label="Owner Name"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="cuProperty.owner_business_name"
                    label="Business Name"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="cuProperty.owner_phone_number"
                    label="Phone Number"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="cuProperty.owner_email_address"
                    label="Email"
                    required
                ></v-text-field>
              </div>

              <v-btn
                  color="secondary"
                  @click="cuPropertyStepper = 1"
              >
                Back
              </v-btn>

              <v-btn
                  color="primary"
                  @click="setStepNextOwner"
              >
                Next
              </v-btn>
              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>


            <v-stepper-step
                :complete="cuPropertyStepper > 3"
                step="3"
            >
              Farm Address
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-autocomplete
                  style="margin-top: 10px;"
                  v-model="cuProperty.address.country_code"
                  :items="countries_list"
                  @change="on_change_country"
                  outlined
                  dense
                  item-text="name"
                  item-value="code"
                  label="Country"
                  :hide-details="true"
              ></v-autocomplete>

              <v-text-field v-if="addPropertyOn"
                            v-model="cuProperty.address.road_number"
                            label="Road Number"
                            required

              ></v-text-field>

              <v-text-field v-if="addPropertyOn"
                            v-model="cuProperty.address.road_name"
                            label="Road Name"
                            required

              ></v-text-field>

              <v-text-field v-if="addPropertyOn"
                            v-model="cuProperty.address.suburb"
                            label="Town/Locality"
                            required

              ></v-text-field>

              <v-select v-if="addPropertyOn && cuProperty.address.country_code === 'AU'"
                        :items="['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']"
                        v-model="cuProperty.address.state"
                        label="State"

              ></v-select>

              <v-text-field v-if="addPropertyOn && cuProperty.address.country_code !== 'AU'"
                            v-model="cuProperty.address.state"
                            label="State/Province"
                            required

              ></v-text-field>

              <v-text-field v-if="addPropertyOn"
                            v-model="cuProperty.address.postcode"
                            label="Post Code"
                            required

              ></v-text-field>

              <v-btn
                  color="secondary"
                  @click="cuPropertyStepper = 2"
              >
                Back
              </v-btn>
              <v-btn
                  color="primary"
                  @click="cuPropertyStepper = 4; updateMapBoxAddress()"
                  :disabled="checkEmpty(cuProperty.address.road_name) ||
                    checkEmpty(cuProperty.address.suburb) || checkEmpty(cuProperty.address.state) || checkEmpty(cuProperty.address.postcode)"
              >
                Next
              </v-btn>
              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>

            <v-stepper-step
                :complete="cuPropertyStepper > 4"
                step="4"
            >
              Select Nearest Location
            </v-stepper-step>

            <v-stepper-content step="4">

              <v-select
                  :items="googleAddressList"
                  v-model="googleAddressIdx"
                  item-text="address"
                  item-value="idx"
                  label="Select Nearest Location"
                  v-on:change="onSelectGoogleAddress"
              ></v-select>

              <v-progress-linear color="orange" indeterminate v-if="googleAddressLoading"></v-progress-linear>

              <v-btn
                  color="secondary"
                  @click="cuPropertyStepper = 3"
              >
                Back
              </v-btn>
              <v-btn
                  color="primary"
                  @click="setStepNextNearbyLocation"
                  :disabled="checkEmpty(googleAddressIdx)"
              >
                Next
              </v-btn>
              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>


            <v-stepper-step
                :complete="cuPropertyStepper > 5"
                step="5"
            >
              Set Gate Location
            </v-stepper-step>

            <v-stepper-content step="5">

              <div v-if="cuProperty.front_gate_lat === null">
                <v-switch
                    color="indigo darken-3"
                    hide-details
                    v-model="digitizeGateLocationOnMap"
                    label="Set front gate location on map"
                    @change="addPropertyCheckOnMapClick()"
                ></v-switch>
              </div>
              <div v-else>
                <p>Gate location is set. Click and drag the marker if needed.<br>
                </p>
              </div>


              <v-btn
                  color="secondary"
                  @click="setStepBackGateLocation"
              >
                Back
              </v-btn>
              <v-btn
                  color="primary"
                  @click="setStepNextGateLocation"
                  :disabled="checkEmpty(cuProperty.front_gate_lat)"
              >
                Next
              </v-btn>
              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>


            <v-stepper-step
                :complete="cuPropertyStepper > 6"
                step="6"
            >
              Select Farm Boundary
            </v-stepper-step>

            <v-stepper-content step="6">


              <v-label class="body-2">Boundary Type</v-label>
              <v-radio-group
                  v-model="cuProperty.boundary_type"
                  column
                  @change="on_change_boundary_type"
                  :disabled="editPropertyOn || cuProperty.address.country_code !== 'AU'"
              >
                <v-radio class="caption"
                         label="Select Land Parcels"
                         color="green"
                         value="farm_key"
                ></v-radio>
                <v-radio class="caption"
                         label="Custom Boundary"
                         color="orange"
                         value="geojson"
                ></v-radio>
              </v-radio-group>

              <div v-if="cuProperty.boundary_type === 'farm_key'">
                <p class="caption" v-if="Object.keys(this.cuPropertyCadsDict).length < 1">
                  Land parcels option is for farms located in <b>Australia</b>.<br>
                  Please click inside parcel polygons to select them.<br>
                  Click again inside a selected parcel to de-select it.
                </p>

                <v-switch
                    v-model="use_geojson_lookup_tool"
                    label="Upload a Reference Layer (GeoJSON, KML)"
                ></v-switch>

                <v-col v-if="use_geojson_lookup_tool">
                  <v-file-input
                      dense
                      outlined
                      show-size
                      :clearable="true"
                      color="primary"
                      accept=".json, .geojson, .kml"
                      label="Upload Geojson or KML"
                      v-model="geojson_file"
                      @change="on_change_upload_geojson"
                  ></v-file-input>

                  <p>
                    {{ geojson_error }}
                  </p>

                  <v-btn
                      v-if="geojson_geometry !== null"
                      @click="find_by_geojson">
                    Find Parcels for GeoJSON/KML
                  </v-btn>
                  <p></p>
                  <v-progress-linear color="purple" indeterminate
                                     v-if="geojson__lookup_in_progress"></v-progress-linear>

                </v-col>


                <v-col>
                  <v-row v-for="cibo_id of Object.keys(cuPropertyCadsDict)"
                         :key="cibo_id">
                    <v-chip
                        class="ma-2"
                        close
                        color="red"
                        label
                        outlined
                        @click:close="deletecuPropertyFromListAndMap(cibo_id)"
                    >
                      {{ cuPropertyCadsDict[cibo_id].jurisdictional_id }}
                    </v-chip>
                  </v-row>
                </v-col>

              </div>
              <div v-else>
                <p class="caption">
                  Please upload farm boundary in GeoJSON or KML format in WGS84 projection.<br>
                  For CeresTag alerts, paddock/field names must be in a column with either of the following names: <b>name, title, description,
                  paddock_name</b>
                </p>
                <v-file-input
                    dense
                    outlined
                    show-size
                    :clearable="true"
                    color="primary"
                    accept=".json, .geojson, .kml"
                    label="Upload Geojson or KML"
                    v-model="geojson_file"
                    @change="on_change_upload_geojson"
                ></v-file-input>

              </div>


              <v-btn
                  color="secondary"
                  @click="setStepBackSelectParcels"
              >
                Back
              </v-btn>
              <v-btn color="primary" v-if="addPropertyOn && cuProperty.boundary_type === 'farm_key'"
                     :disabled="Object.keys(this.cuPropertyCadsDict).length < 1 || cuProperty.property_name === null"
                     @click="submitAddProperty">
                Submit
              </v-btn>

              <v-btn color="primary" v-if="addPropertyOn && cuProperty.boundary_type === 'geojson'"
                     :disabled="geojson_geometry.length < 1 || geojson_error !== null || cuProperty.property_name === null"
                     @click="submitAddProperty">
                Submit
              </v-btn>

              <v-btn color="primary" v-if="editPropertyOn && cuProperty.boundary_type === 'farm_key'"
                     :disabled="Object.keys(this.cuPropertyCadsDict).length < 1 || cuProperty.property_name === null"
                     @click="submitEditProperty">Update
              </v-btn>

              <v-btn color="primary" v-if="editPropertyOn && cuProperty.boundary_type === 'geojson'"
                     :disabled="geojson_error !== null || cuProperty.property_name === null"
                     @click="submitEditPropertyNonFarmKey">Update
              </v-btn>


              <v-btn text @click="cancel_cuProperty">Cancel</v-btn>
            </v-stepper-content>
          </v-stepper-items>

        </v-stepper>

      </v-col>


      <!--      List of properties-->
      <div v-if="!addPropertyOn & !editPropertyOn">
        <!--        <v-subheader align="left">Farms</v-subheader>-->

        <v-card>
          <v-card-text>
            <v-select
                :items="makePropertyList()"
                v-model="property_id"
                item-text="property_name"
                item-value="property_id"
                label="Select Farm"
                v-on:change="onSelectProperty"
            ></v-select>
            <v-progress-linear color="orange" indeterminate
                               v-if="property_is_loading || !$store.state.initAllLoaded"></v-progress-linear>
          </v-card-text>

          <v-card-actions v-if="property_id !== null">

            <v-btn icon
                   @click="do_editProperty()"
                   title="Edit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn icon
                   @click="deletePropertyDialog = true;"
                   title="Delete">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>

            <v-btn icon
                   v-if="$store.state.properties[property_id].inf.is_aus_farmkey"
                   @click="addPropertyKeyToShoppingCart('Property Key', {}, null)"
                   title="Add Farm Key to Cart">
              <v-icon color="orange">mdi-cart-arrow-down</v-icon>
            </v-btn>

            <v-btn icon
                   v-if="$store.state.properties[property_id].inf.is_aus_farmkey"
                   @click="addPropertyKeyToShoppingCart('Carbon Report', {}, null)"
                   title="Add Carbon Report to Cart">
              <v-icon color="green">mdi-cart-arrow-down</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>
      </div>

      <v-dialog
          v-model="deletePropertyDialog"
          width="500"
      >
        <v-card>
          <v-card-title>
            Delete Farm
          </v-card-title>

          <v-card-text>
            <b>Are you sure you want to delete this farm?</b><br>
            Please make sure this farm is not linked to Ceres Tag or other third-party accounts.
            Deleting a farm that is already linked to other services may cause problems accessing them.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deletePropertyDialog = false">
              Cancel
            </v-btn>
            <v-btn
                color="warning"
                @click="deleteProperty"
            >
              Permanently Delete Farm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <p></p>
      <p></p>
      <v-divider></v-divider>
      <v-subheader align="left">WMS Layers</v-subheader>
      <v-expansion-panels accordion>


        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox dense
                        v-model="wmsLayers.ciboPSMA.show"
                        :label="`PSMA Australia`"
                        @change="ciboPSMACheckbox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>

          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox
                v-model="wmsLayers.woodyCoverChange.show"
                :label="`Woody Cover Change`"
                @change="woodyCoverChangeCheckBox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-slider
                v-model="wmsLayers.woodyCoverChange.year"
                :min="1991"
                :max="2019"
                @change="woodyCoverChangeChangeYear"
                thumb-label="always"
                ticks
                @keydown.native="woodyCoverChangeChangeYearKeyDown"
                @keyup.native="woodyCoverChangeChangeYearKeyUp"
            ></v-slider>

            <v-img
                contain
                aspect-ratio="1"
                width="300"
                max-height="600"
                src="@/assets/legends/WoodyCoverChangeLegend.png"
            ></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header style="padding-top: 0; padding-bottom: 0; margin: 0;">
            <v-checkbox
                v-model="wmsLayers.SeasonalGroundCover.show"
                :label="`Seasonal Ground Cover (%)`"
                @change="SeasonalGroundCoverCheckbox"
            >
            </v-checkbox>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ wmsLayers.SeasonalGroundCover.date_list[wmsLayers.SeasonalGroundCover.date_index] }}<br>

            <v-slider
                v-model="wmsLayers.SeasonalGroundCover.date_index"
                min="0"
                max="125"
                @change="SeasonalGroundCoverChangeDate"
                ticks
                @keydown.native="SeasonalGroundCoverChangeDateKeyDown"
                @keyup.native="SeasonalGroundCoverChangeDateKeyUp"
            ></v-slider>

            <v-img
                contain
                aspect-ratio="1"
                width="200"
                max-height="400"
                src="@/assets/legends/SeasonalGroundCoverLegend.png"
            ></v-img>

          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>

      <p></p>
      <p></p>
      <v-divider></v-divider>
      <v-subheader align="left">Base Layer</v-subheader>
      <v-list dense>

        <v-list-item-group
            color="primary"
        >
          <v-list-item>
            <v-list-item-content align="left">
              <v-select
                  v-model="selectedBaseLayer"
                  :items="baseLayersList"
                  @change="setBaseLayer($event)"
              ></v-select>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>

      <v-col>

      </v-col>


    </v-navigation-drawer>

    <v-navigation-drawer v-model="mapDrawerRight" right hide-overlay absolute width="500">
      <h1>Right Drawer</h1>
    </v-navigation-drawer>

    <v-system-bar
        style="z-index: 1000; height: 1px;"
        flat
        dense
    >
      <v-btn fab color="pink" style=" z-index: 1000; margin-top: 100px;" @click="switchDrawerLeft">
        <v-icon color="white">mdi-page-layout-sidebar-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!--        <v-btn fab color="pink" style=" z-index: 1000;" @click="switchDrawerRight">-->
      <!--          <v-icon color="white">mdi-page-layout-sidebar-right</v-icon>-->
      <!--        </v-btn>-->
    </v-system-bar>

    <v-main id="map" v-bind:style="mapStyle" style="z-index: 0;">

    </v-main>


  </div>


</template>

<script>

import "leaflet/dist/leaflet.css";
import L from "leaflet/dist/leaflet.js";
import toGeoJSON from '@mapbox/togeojson';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled.js';


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import '@/assets/scripts/Leaflet.MakiMarkers'
import {countries} from "@/assets/countries";

import 'leaflet-draw/dist/leaflet.draw.js'
import 'leaflet-draw/dist/leaflet.draw.css'


import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
  getProperties, getPropertyBoundaryAtLatLon, addCadsToProperty, find_cads_in_geojson,
  createProperty, editProperty, getPropertyBoundary, addToShoppingCart,
  deleteProperty, showSnackBar
} from '@/services/mfk_api'
import _cloneDeep from 'lodash/cloneDeep'

import axios from 'axios'
import {update_farm_geojson_boundary, update_farm_info_nonfarmkey} from "../../services/mfk_api";

export default {
  name: "MapComponent",
  data() {
    return {
      leftDrawer: {
        show: true,
        width: '30%',
        borderSize: 3
      },
      mapDrawerRight: false,

      mapStyle: {
        padding: 0,
        cursor: 'grab',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 0,
        margin: 0,

      },


      map: null,
      mapBoxAccessToken: 'pk.eyJ1IjoiYWxpZXJzaGFkaSIsImEiOiJjazg2dDJnNGIwbGFiM2ZtY3ExdjQ3Z3BvIn0.UlrwVCrd5ZPJ68BU2Z0yYA',
      mapboxGeocoderObj: null,

      baseLayers: {},

      tools: null,

      // map base layer
      currentBaseLayer: 'Satellite',
      selectedBaseLayer: 'Satellite',
      baseLayersList: ['Streets', 'Satellite'],

      // selected property
      property_id: null,
      property_is_loading: false,
      property_listing_in_progress: false,
      property_obj_list: [],
      propertyGateMarker: null,
      property_bounds: null,

      // area lookup

      use_geojson_lookup_tool: false,
      geojson_file: null,
      geojson_geometry: [],
      geojson_properties: [],
      geojson_leaflet_obj: [],
      geojson_error: null,
      geojson__lookup_in_progress: false,

      // edit property
      deletePropertyDialog: false,
      editPropertyOn: false,

      // add property
      countries_list: countries,
      states_list: [],

      addPropertyOn: false,
      cuPropertyStepper: 1,
      cuPropertyInProgress: false,
      cuPropertyErrors: [],
      cuPropertyTemplate: {
        pic_code: null,
        property_name: null,
        front_gate_address: null,
        typed_front_gate_address: null,
        front_gate_lat: null,
        front_gate_lng: null,
        isOwner: true,

        owner_name: null,
        owner_business_name: null,
        owner_phone_number: null,
        owner_email_address: null,


        selectedProperties: [],
        address: {
          country_code: 'AU',
          country: 'Australia',
          road_number: null,
          road_name: null,
          suburb: null,
          state: null,
          postcode: null
        },
        boundary_type: 'farm_key', // farm_key, geojson
      },
      cuProperty: {},
      cuPropertyGateMarker: null,

      digitizeGateLocationOnMap: false,

      selectPropertyOnMap: false,
      cuPropertyCadsDict: {},
      cuPropertyCadsObjs: {},


      googleAddressList: [],
      googleAddressAddress: null,
      googleAddressIdx: null,
      googleAddressLoading: false,


      wmsLayers: {
        ciboPSMA: {
          obj: null,
          show: false
        },
        woodyCoverChange: {
          obj: null,
          show: false,
          year: 2019
        },
        SeasonalGroundCover: {
          obj: null,
          show: false,
          // date: '2017-12',
          date_index: 125,
          date_list: ['1989-12',
            '1990-03',
            '1990-06',
            '1990-09',
            '1990-12',
            '1991-03',
            '1991-06',
            '1991-09',
            '1991-12',
            '1992-03',
            '1992-06',
            '1992-09',
            '1992-12',
            '1993-03',
            '1993-06',
            '1993-09',
            '1993-12',
            '1994-03',
            '1994-06',
            '1994-09',
            '1994-12',
            '1995-03',
            '1995-06',
            '1995-09',
            '1995-12',
            '1996-03',
            '1996-06',
            '1996-09',
            '1996-12',
            '1997-03',
            '1997-06',
            '1997-09',
            '1997-12',
            '1998-03',
            '1998-06',
            '1998-09',
            '1998-12',
            '1999-03',
            '1999-06',
            '1999-09',
            '1999-12',
            '2000-03',
            '2000-06',
            '2000-09',
            '2000-12',
            '2001-03',
            '2001-06',
            '2001-09',
            '2001-12',
            '2002-03',
            '2002-06',
            '2002-09',
            '2002-12',
            '2003-03',
            '2003-06',
            '2003-09',
            '2003-12',
            '2004-03',
            '2004-06',
            '2004-09',
            '2004-12',
            '2005-03',
            '2005-06',
            '2005-09',
            '2005-12',
            '2006-03',
            '2006-06',
            '2006-09',
            '2006-12',
            '2007-03',
            '2007-06',
            '2007-09',
            '2007-12',
            '2008-03',
            '2008-06',
            '2008-09',
            '2008-12',
            '2009-03',
            '2009-06',
            '2009-09',
            '2009-12',
            '2010-03',
            '2010-06',
            '2010-09',
            '2010-12',
            '2011-03',
            '2011-06',
            '2011-09',
            '2011-12',
            '2012-03',
            '2012-06',
            '2012-09',
            '2012-12',
            '2013-03',
            '2013-06',
            '2013-09',
            '2013-12',
            '2014-03',
            '2014-06',
            '2014-09',
            '2014-12',
            '2015-03',
            '2015-06',
            '2015-09',
            '2015-12',
            '2016-03',
            '2016-06',
            '2016-09',
            '2016-12',
            '2017-03',
            '2017-06',
            '2017-09',
            '2017-12',
            '2018-03',
            '2018-06',
            '2018-09',
            '2018-12',
            '2019-03',
            '2019-06',
            '2019-09',
            '2019-12',
            '2020-03',
            '2020-06',
            '2020-09',
            '2020-12',
            '2021-03',
          ],
        },
        S2: {
          obj: null,
          show: false,
        },

      },

    }
  },
  methods: {

    setLeftDrawerBorderWidth() {
      let i = this.$refs.leftDrawer.$el.querySelector(
          ".v-navigation-drawer__border"
      );
      i.style.width = this.leftDrawer.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const minSize = this.leftDrawer.borderSize;
      const el = this.$refs.leftDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
          ? "right"
          : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
          "mousedown",
          function (e) {
            if (e.offsetX < minSize) {
              e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
      );

      document.addEventListener(
          "mouseup",
          function () {
            el.style.transition = '';
            vm.leftDrawer.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
      );
    },
    switchDrawerLeft() {
      this.leftDrawer.show = !this.leftDrawer.show
    },
    increaseDrawerLeft() {
      let width = parseInt(this.leftDrawer.width.replace('px', ''))
      this.leftDrawer.width = width + 10 + 'px'
    },
    decreaseDrawerLeft() {
      let width = parseInt(this.leftDrawer.width.replace('px', ''))
      this.leftDrawer.width = width - 10 + 'px'
    },
    switchDrawerRight() {
      this.mapDrawerRight = !this.mapDrawerRight
    },

    async initMap() {

      L.MakiMarkers.accessToken = this.mapBoxAccessToken;

      this.map = L.map("map", {
        editable: true,
        zoomControl: false,
        attributionControl: true,
        boxZoom: true,
        touchZoom: true,
        continuousWorld: false,
        noWrap: true,
        zoomSnap: 0,
        zoomDelta: 0.01,
      }).setView([-25, 133], 5);
      this.map.scrollWheelZoom = true
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      // this.map.scrollWheelZoom.enable();
      this.map.keyboard.enable();
      L.control.scale({maxWidth: 150, position: 'bottomright'}).addTo(this.map);
      this.baseLayers = {
        Streets: L.tileLayer(
            'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + this.mapBoxAccessToken),
        Satellite: L.tileLayer(
            'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' + this.mapBoxAccessToken),
      };

      this.baseLayers[this.currentBaseLayer].addTo(this.map).bringToBack()

      // this.tools = {
      //   polygon: new L.Draw.Polygon(this.map, {
      //     shapeOptions: {
      //       color: '#DA5555',
      //       weight: 4,
      //     },
      //     drawError: {
      //       color: '#e1e100', // Color the shape will turn when intersects
      //       message: '<strong>Wrong move!<strong> you can\'t intersect a node!' // Message that will show when intersect
      //     },
      //     allowIntersection: false,
      //     guidelineDistance: 10,
      //     metric: true,
      //     showArea: true,
      //     icon: new L.DivIcon({
      //       iconSize: new L.Point(10, 10),
      //       className: 'leaflet-div-icon leaflet-editing-icon'
      //     })
      //   }),
      //   rectangle: new L.Draw.Rectangle(this.map, {
      //     shapeOptions: {
      //       color: '#DA5555'
      //     },
      //     metric: true,
      //     showArea: true,
      //     icon: new L.DivIcon({
      //       iconSize: new L.Point(10, 10),
      //       className: 'leaflet-div-icon leaflet-editing-icon'
      //     })
      //   }),
      //   circle: new L.Draw.Circle(this.map, {
      //     shapeOptions: {
      //       color: '#DA5555'
      //     },
      //     metric: true,
      //     showArea: true,
      //     icon: new L.DivIcon({
      //       iconSize: new L.Point(10, 10),
      //       className: 'leaflet-div-icon leaflet-editing-icon'
      //     })
      //   }),
      //   polyline: new L.Draw.Polyline(this.map, {
      //     shapeOptions: {
      //       color: '#DA5555'
      //     },
      //     icon: new L.DivIcon({
      //       iconSize: new L.Point(10, 10),
      //       className: 'leaflet-div-icon leaflet-editing-icon'
      //     })
      //   }),
      //   marker: new L.Draw.Marker(this.map, {
      //     shapeOptions: {
      //       color: '#DA5555'
      //     }
      //   }),
      // };
      //
      // this.tools.circle.enable();

      this.wmsLayers.ciboPSMA.obj = L.vectorGrid.protobuf(
          "https://cibo-mvt.s3-ap-southeast-2.amazonaws.com/{z}/{x}/{y}.pbf",
          {
            rendererFactory: L.svg.tile,
            interactive: true,
            attribution: '&copy; PSMA, Cibolabs',
            vectorTileLayerStyles: {
              cad: {
                weight: 0.5,
                color: '#FFFF00',
                fillColor: '#000000',
                fillOpacity: 0.1,
                fill: true
              }
            },
            maxNativeZoom: 15,
            minZoom: 11,
          })

      // this.wmsLayers.S2.obj = L.tileLayer.wms('https://lambda.cibolabs.com/?', {
      //   layers: 'S2',
      //   format: 'image/png',
      //   useCache: true,
      //   crossOrigin: true,
      //   tileSize: 256,
      // })

      if (this.$store.state.edit_property_map_actions !== null) {
        this.property_id = this.$store.state.edit_property_map_actions.property_id
        await this.onSelectProperty()
        this.do_editProperty()
        this.$store.state.edit_property_map_actions = null
      }

    },

    setBaseLayer(baseLayerName) {
      this.map.removeLayer(this.baseLayers[this.currentBaseLayer])
      this.baseLayers[baseLayerName].addTo(this.map).bringToBack()
      this.currentBaseLayer = baseLayerName
    },

    addMapboxGeocoder() {
      this.mapboxGeocoderObj = new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        placeholder: 'Search for address',
        limit: 5,
      })
      this.mapboxGeocoderObj.addTo('#geocoder')
      this.mapboxGeocoderObj.on('result', (ev) => {
        if ('bbox' in ev.result) {
          var bbox = ev.result.bbox; //   [minX,minY,maxX,maxY]

          var corner1 = L.latLng(bbox[1], bbox[0]),
              corner2 = L.latLng(bbox[3], bbox[2]);
          this.map.fitBounds(L.latLngBounds(corner1, corner2));
        } else if ('center' in ev.result) {
          var center = ev.result.center; //   [Lon, Lat]

          // this.map.panTo(new L.LatLng(center[1], center[0]));
          this.map.setView(new L.LatLng(center[1], center[0]), 16);
        }
      })
    },

    S2Checkbox() {
      if (this.wmsLayers.S2.show) {
        this.wmsLayers.S2.obj.addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.S2.obj)
      }
    },
    show_PSMA() {
      this.wmsLayers.ciboPSMA.show = true
      this.map.removeLayer(this.wmsLayers.ciboPSMA.obj)
      this.wmsLayers.ciboPSMA.obj.addTo(this.map)
    },
    ciboPSMACheckbox() {
      if (this.wmsLayers.ciboPSMA.show) {
        this.wmsLayers.ciboPSMA.obj.addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.ciboPSMA.obj)
      }
    },

    woodyCoverChangeCheckBox() {
      if (this.wmsLayers.woodyCoverChange.show) {
        this.wmsLayers.woodyCoverChange.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/DOEE2019/${this.wmsLayers.woodyCoverChange.year}/{z}/{x}/{y}.png`,
            {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.woodyCoverChange.obj)
      }
    },
    woodyCoverChangeChangeYear() {
      this.map.removeLayer(this.wmsLayers.woodyCoverChange.obj)
      this.wmsLayers.woodyCoverChange.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/DOEE2019/${this.wmsLayers.woodyCoverChange.year}/{z}/{x}/{y}.png`,
          {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
    },
    woodyCoverChangeChangeYearKeyDown() {
      this.wmsLayers.woodyCoverChange.year -= 1
      console.log(this.wmsLayers.woodyCoverChange.year)
      this.woodyCoverChangeChangeYear()
    },
    woodyCoverChangeChangeYearKeyUp() {
      this.wmsLayers.woodyCoverChange.year += 1
      console.log(this.wmsLayers.woodyCoverChange.year)
      this.woodyCoverChangeChangeYear()
    },

    SeasonalGroundCoverCheckbox() {
      if (this.wmsLayers.SeasonalGroundCover.show) {
        let yyyymm = this.wmsLayers.SeasonalGroundCover.date_list[this.wmsLayers.SeasonalGroundCover.date_index].replace('-', '')
        console.log(yyyymm)
        this.wmsLayers.SeasonalGroundCover.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/GC/${yyyymm}/{z}/{x}/{y}.png`,
            {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
      } else {
        this.map.removeLayer(this.wmsLayers.SeasonalGroundCover.obj)
      }
    },
    SeasonalGroundCoverChangeDate() {
      this.map.removeLayer(this.wmsLayers.SeasonalGroundCover.obj)
      let yyyymm = this.wmsLayers.SeasonalGroundCover.date_list[this.wmsLayers.SeasonalGroundCover.date_index].replace('-', '')
      console.log(yyyymm)
      this.wmsLayers.SeasonalGroundCover.obj = L.tileLayer(`https://cibo-tms.s3-ap-southeast-2.amazonaws.com/GC/${yyyymm}/{z}/{x}/{y}.png`,
          {minNativeZoom: 3, maxNativeZoom: 14}).addTo(this.map)
    },
    SeasonalGroundCoverChangeDateKeyDown() {
      this.wmsLayers.SeasonalGroundCover.date_index -= 1
      this.SeasonalGroundCoverChangeDate()
    },
    SeasonalGroundCoverChangeDateKeyUp() {
      this.wmsLayers.SeasonalGroundCover.date_index += 1
      this.SeasonalGroundCoverChangeDate()
    },

    // Add Remove property from Map
    makePropertyList() {

      let propList = []
      for (let propId of Object.keys(this.$store.state.properties)) {
        propList.push({property_id: propId, property_name: this.$store.state.properties[propId].inf.property_name})
      }

      return propList.sort((a, b) => a.property_name.localeCompare(b.property_name))

    },

    async addPropertyToMap() {

      this.property_obj_list = []

      this.property_bounds = []
      for (let cibo_id in this.$store.state.properties[this.property_id].bnd) {
        this.property_bounds.push(this.$store.state.properties[this.property_id].bnd[cibo_id].bndObj.geo_json)
        this.property_obj_list.push(L.geoJSON(this.$store.state.properties[this.property_id].bnd[cibo_id].bndObj.geo_json, {
          style: {
            "color": "#ff7800",
            "weight": 5,
            "opacity": 0.65
          }
        }))
      }

      for (let item of this.property_obj_list) {
        item.addTo(this.map)
      }

      this.zoomToSelectedProperty()
      let gate_lat = this.$store.state.properties[this.property_id].inf.front_gate_lat
      let gate_lon = this.$store.state.properties[this.property_id].inf.front_gate_lng
      if (gate_lat !== null) {
        this.propertyGateMarker = L.marker([gate_lat, gate_lon], {
          title: "Gate Location",
          draggable: false
        }).bindPopup("<b>Gate Location</b>")
            .addTo(this.map)
      }

    },

    async removePropertyFromMap() {
      for (let item of this.property_obj_list) {
        this.map.removeLayer(item)
      }
      this.property_obj_list = []

      if (this.propertyGateMarker !== null) {
        this.map.removeLayer(this.propertyGateMarker)
        this.propertyGateMarker = null
      }

    },
    zoomToSelectedProperty() {

      this.map.fitBounds(L.geoJson(this.property_bounds).getBounds(), {paddingTopLeft: [200, 50]})


    },

    async onSelectProperty() {

      // remove every other property from the map
      await this.removePropertyFromMap()

      // Load property and add to map
      if (this.$store.state.properties[this.property_id].bnd === null) {
        this.property_is_loading = true
        await getPropertyBoundary(this.$auth.user.email, this.property_id)
        this.property_is_loading = false
      }
      await this.addPropertyToMap()

    },


    async deleteProperty() {
      this.deletePropertyDialog = false
      await deleteProperty(this.property_id)
      this.removePropertyFromMap()
      this.makePropertyList()
      this.property_id = null
    },

    on_change_boundary_type() {

      if (this.cuProperty.boundary_type === 'farm_key') {
        this.selectPropertyOnMap = true
        this.addPropertyCheckOnMapClick()
      } else {
        this.selectPropertyOnMap = false
        this.map.off('click');
        this.mapStyle.cursor = 'grab'
        this.deleteAllcuPropertiesFromListAndMap()
      }

      this.the_force_update()

    },

    // EDIT PROPERTY
    do_editProperty() {
      this.zoomToSelectedProperty()

      this.editPropertyOn = true
      this.selectPropertyOnMap = false

      let inf = _cloneDeep(this.$store.state.properties[this.property_id].inf)
      console.log('inf ---------------')
      console.log(inf)
      this.cuProperty = {
        is_aus_farmkey: inf.is_aus_farmkey,
        pic_code: inf.pic_code,
        property_name: inf.property_name,
        front_gate_address: inf.front_gate_address,
        typed_front_gate_address: inf.typed_front_gate_address,
        front_gate_lat: inf.front_gate_lat,
        front_gate_lng: inf.front_gate_lng,
        isOwner: !inf.not_owner,

        owner_name: inf.client_name,
        owner_business_name: inf.client_business_name,
        owner_phone_number: inf.client_phone_number,
        owner_email_address: inf.client_email_address,

        selectedProperties: [],
        address: {
          road_number: null,
          road_name: null,
          suburb: null,
          state: null,
          postcode: null
        },
      }

      if (inf.is_aus_farmkey) {
        this.cuProperty.boundary_type = 'farm_key'
      } else {
        this.cuProperty.boundary_type = 'geojson'
      }

      if (inf.front_gate_lat !== null) {
        this.cuProperty.front_gate_lat = parseFloat(inf.front_gate_lat)
        this.cuProperty.front_gate_lng = parseFloat(inf.front_gate_lng)
      }

      if (inf.typed_front_gate_address !== null) {
        if (inf.typed_front_gate_address.includes('***')) {
          let address_comps = inf.typed_front_gate_address.split('***')
          console.log(address_comps)
          if (address_comps.length > 4) {
            this.cuProperty.address = {
              road_number: address_comps[0],
              road_name: address_comps[1],
              suburb: address_comps[2],
              state: address_comps[3],
              postcode: address_comps[4],
            }
          }
        }
      }

      console.log(this.cuProperty)

      let bnd = _cloneDeep(this.$store.state.properties[this.property_id].bnd)

      this.removePropertyFromMap()
      for (let cibo_id of Object.keys(bnd)) {

        let propData = bnd[cibo_id].bndObj
        propData.jurisdictional_id = propData.juri_id
        delete propData.juri_id

        propData.geo_json.properties = {
          cibo_id: cibo_id,
        }
        this.cuPropertyCadsDict[cibo_id] = propData
        this.cuPropertyCadsObjs[cibo_id] = L.geoJSON(propData.geo_json, {
          style: {
            "color": "red",
            "weight": 5,
            "opacity": 0.65
          },
          // onEachFeature: (feature, layer) => {
          //   console.log('***********************')
          //   console.log(feature)
          //   console.log(layer)
          // }
        })
        if (inf.is_aus_farmkey) {
          this.cuPropertyCadsObjs[cibo_id].bindTooltip(propData.jurisdictional_id, {
            permanent: true,
            opacity: 0.7
          }).openTooltip()
        }
        // this.cuPropertyCadsObjs[cibo_id].on('click', () => {
        //   this.cuPropertyCadsObjs[cibo_id].remove()
        //   delete this.cuPropertyCadsObjs[cibo_id]
        //   this.map.off('click');
        //   setTimeout(() => {
        //     delete this.cuPropertyCadsDict[cibo_id]
        //     this.addPropertyCheckOnMapClick()
        //   }, 100)
        // })
        this.cuPropertyCadsObjs[cibo_id].addTo(this.map)

      }
      // }

      // add gate location
      console.log('this.cuProperty.front_gate_lat')
      console.log(this.cuProperty.front_gate_lat)
      if (this.cuProperty.front_gate_lat !== null) {
        this.cuPropertyGateMarker = L.marker(
            [this.cuProperty.front_gate_lat,
              this.cuProperty.front_gate_lng
            ], {title: "Gate Location", draggable: true})
            .bindTooltip("<b>Front Gate</b><br>Drag me", {
              style: {"opacity": 0.5},
              permanent: true,
              draggable: true,
              name: 'front-gate-tooltip'
            })
            .addTo(this.map)
            .on('dragend', (de) => {
              let latlng = de.target.getLatLng()
              this.cuProperty.front_gate_lat = latlng.lat;
              this.cuProperty.front_gate_lng = latlng.lng;
              this.$forceUpdate()

            })
        this.cuPropertyGateMarker.openTooltip()
        this.digitizeGateLocationOnMap = false
      }
      // this.addPropertyCheckOnMapClick()
      this.show_PSMA()

      console.log('===============================')
      console.log(Object.keys(this.cuPropertyCadsDict));
    },

    the_force_update() {
      setTimeout(() => {
        this.$forceUpdate()
      }, 100)
    },

    // ADD PROPERTY
    on_change_country() {

      this.cuProperty.address.country = null

      for (let item of this.countries_list) {
        if (item.code === this.cuProperty.address.country_code) {
          this.cuProperty.address.country = item.name
          break;
        }
      }

      if (this.cuProperty.address.country !== 'Australia') {
        this.cuProperty.address.state = null
        this.cuProperty.boundary_type = 'geojson'
      }


    },

    async updateMapBoxAddress() {

      let typed_gate_address
      // if (this.cuProperty.address.country === 'Australia'){
      typed_gate_address = `${this.cuProperty.address.road_number} ${this.cuProperty.address.road_name} ${this.cuProperty.address.suburb} ${this.cuProperty.address.state} ${this.cuProperty.address.postcode} ${this.cuProperty.address.country}`.replaceAll('null', '')
      // } else {
      //   typed_gate_address = `${this.cuProperty.address.road_number} ${this.cuProperty.address.road_name} ${this.cuProperty.address.suburb} ${this.cuProperty.address.postcode} ${this.cuProperty.address.country}`.replaceAll('null', '')
      // }

      console.log('typed_gate_address')
      console.log(typed_gate_address)
      this.googleAddressLoading = true
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          key: 'AIzaSyCuD_rmdqwcD3ySIvgNRX0S8g3mO0Vkhbg',
          region: 'au',
          address: typed_gate_address
        },
      })
      this.googleAddressLoading = false
      this.cuProperty.front_gate_address = null
      this.googleAddressIdx = null
      this.googleAddressList = []
      let idx = -1
      for (let gItem of response.data.results) {
        idx += 1
        this.googleAddressList.push({
          address: gItem.formatted_address,
          geometry: gItem.geometry,
          idx: idx,
        })
      }

    },
    onSelectGoogleAddress() {

      this.cuProperty.front_gate_address = this.googleAddressList[this.googleAddressIdx].address

      let geometry = this.googleAddressList[this.googleAddressIdx].geometry
      console.log(geometry)

      if ('bounds' in geometry) {
        this.map.fitBounds(L.latLngBounds(geometry.bounds.northeast, geometry.bounds.southwest));
      } else if ('viewport' in geometry) {
        this.map.fitBounds(L.latLngBounds(geometry.viewport.northeast, geometry.viewport.southwest));
      } else if ('location' in geometry) {
        this.map.setView(new L.LatLng(geometry.location.lat, geometry.location.lng), 16);
      }


    },

    onChangeIsOwner() {
      if (this.cuProperty.isOwner) {
        this.cuProperty.owner_name = this.$store.state.userDict.owner_name
        this.cuProperty.owner_business_name = this.$store.state.userDict.business_name
        this.cuProperty.owner_phone_number = this.$store.state.userDict.phone_number
        this.cuProperty.owner_email_address = this.$store.state.userDict.email_address
      } else {
        this.cuProperty.owner_name = null
        this.cuProperty.owner_business_name = null
        this.cuProperty.owner_phone_number = null
        this.cuProperty.owner_email_address = null
      }

    },
    addProperty() {
      this.removePropertyFromMap()

      this.property_id = null
      console.log('add property')
      this.addPropertyOn = true
      this.selectPropertyOnMap = false

      this.cuProperty = _cloneDeep(this.cuPropertyTemplate)

      this.cuProperty.owner_name = this.$store.state.userDict.owner_name
      this.cuProperty.owner_business_name = this.$store.state.userDict.business_name
      this.cuProperty.owner_phone_number = this.$store.state.userDict.phone_number
      this.cuProperty.owner_email_address = this.$store.state.userDict.email_address


      this.cuPropertyErrors = []
      this.show_PSMA()
      showSnackBar(`Please click inside parcels on the map to select them. It may take few seconds for the parcel boundary to load and add to the map.`, 60000, 'red')
      this.addPropertyCheckOnMapClick()
      this.$forceUpdate()
    },

    setStepNextOwner() {
      if (this.addPropertyOn) {
        this.cuPropertyStepper = 3 // go to address
      } else {
        this.cuPropertyStepper = 5 // go to gate
      }
    },
    setStepBackGateLocation() {
      if (this.addPropertyOn) {
        this.cuPropertyStepper = 4 // go to address
      } else {
        this.cuPropertyStepper = 2 // go to gate
      }
    },
    setStepNextNearbyLocation() {

      this.cuPropertyStepper = 5

      if (this.cuProperty.front_gate_lat === null) {
        let geometry = this.googleAddressList[this.googleAddressIdx].geometry

        this.cuProperty.front_gate_lat = geometry.location.lat
        this.cuProperty.front_gate_lng = geometry.location.lng

        this.cuPropertyGateMarker = L.marker(
            [this.cuProperty.front_gate_lat,
              this.cuProperty.front_gate_lng
            ], {title: "Gate Location", draggable: true})
            .bindTooltip("<b>Front Gate</b><br>Drag me", {
              style: {"opacity": 0.5},
              permanent: true,
              draggable: true,
              name: 'front-gate-tooltip'
            })
            .addTo(this.map)
            .on('dragend', (de) => {
              let latlng = de.target.getLatLng()
              this.cuProperty.front_gate_lat = latlng.lat;
              this.cuProperty.front_gate_lng = latlng.lng;
              this.$forceUpdate()

            })
        this.cuPropertyGateMarker.openTooltip()
        this.digitizeGateLocationOnMap = false

        this.addPropertyCheckOnMapClick()
      }

    },
    setStepNextGateLocation() {

      if (this.editPropertyOn) {

        for (let cibo_id of Object.keys(this.cuPropertyCadsDict)) {

          this.cuPropertyCadsObjs[cibo_id].removeFrom(this.map)

          this.cuPropertyCadsObjs[cibo_id].on('click', () => {
            this.cuPropertyCadsObjs[cibo_id].remove()
            delete this.cuPropertyCadsObjs[cibo_id]
            this.map.off('click');
            setTimeout(() => {
              delete this.cuPropertyCadsDict[cibo_id]
              this.addPropertyCheckOnMapClick()
            }, 100)
          })
          this.cuPropertyCadsObjs[cibo_id].addTo(this.map)
        }

        if (this.cuProperty.is_aus_farmkey) {
          this.selectPropertyOnMap = true
          this.addPropertyCheckOnMapClick()
        }

      } else {
        this.selectPropertyOnMap = true
        this.addPropertyCheckOnMapClick()
      }


      this.cuPropertyStepper = 6


    },
    setStepBackSelectParcels() {
      this.cuPropertyStepper = 5
      this.selectPropertyOnMap = false
      this.addPropertyCheckOnMapClick()
    },


    checkEmpty(inVar) {
      let isEmpty = false
      if (inVar === null || inVar === '' || inVar === ' ') {
        isEmpty = true
      }
      return isEmpty
    },

    async check_AddProperty() {
      const check_empty = (inVar) => {
        let isEmpty = false
        if (inVar === null || inVar === '' || inVar === ' ') {
          isEmpty = true
        }
        return isEmpty
      }

      this.cuPropertyErrors = []
      if (check_empty(this.cuProperty.property_name)) {
        this.cuPropertyErrors.push('Name cannot be empty')
      }
      if (check_empty(this.cuProperty.address.road_number)) {
        this.cuPropertyErrors.push('Road number cannot be empty')
      }
      if (check_empty(this.cuProperty.address.road_name)) {
        this.cuPropertyErrors.push('Road name cannot be empty')
      }
      if (check_empty(this.cuProperty.address.suburb)) {
        this.cuPropertyErrors.push('Suburb/town name cannot be empty')
      }
      if (check_empty(this.cuProperty.address.state)) {
        this.cuPropertyErrors.push('State cannot be empty')
      }
      if (check_empty(this.cuProperty.address.postcode)) {
        this.cuPropertyErrors.push('Post code cannot be empty')
      }

    },
    async submitAddProperty() {
      console.log('submit')
      console.log(this.cuProperty)

      await this.check_AddProperty()
      console.log(this.cuPropertyErrors)
      console.log(this.cuPropertyErrors.length)

      if (this.cuPropertyErrors.length < 1) {

        this.cuPropertyInProgress = true
        this.mapStyle.cursor = 'grab'

        let typed_gate_address = `${this.cuProperty.address.road_number}***${this.cuProperty.address.road_name}***${this.cuProperty.address.suburb}***${this.cuProperty.address.state}***${this.cuProperty.address.postcode}`

        let data = {
          email: this.$store.state.userDict.email_address,
          property_name: this.cuProperty.property_name,
          pic_code: this.cuProperty.pic_code,
          front_gate_address: this.cuProperty.front_gate_address,
          front_gate_lat: this.cuProperty.front_gate_lat,
          front_gate_lng: this.cuProperty.front_gate_lng,
          typed_front_gate_address: typed_gate_address,
          not_owner: !this.cuProperty.isOwner,
          owner_name: this.cuProperty.owner_name,
          owner_business_name: this.cuProperty.owner_business_name,
          owner_phone_number: this.cuProperty.owner_phone_number,
          owner_email_address: this.cuProperty.owner_email_address
        }

        const resp = await createProperty(data)

        if (await resp.length > 0) {
          if ('create_property' in resp[0]) {
            const property_id = resp[0]['create_property']

            // here to switch for farm_key or geojson
            if (this.cuProperty.boundary_type === 'farm_key') {
              const cuPropertiesList = Object.keys(this.cuPropertyCadsDict)

              if (cuPropertiesList.length > 0) {
                await addCadsToProperty({
                  "property_id": property_id,
                  "cibo_ids": cuPropertiesList
                })
              }
            } else {
              let geojson_obj = {
                "type": "FeatureCollection",
                "features": []
              }
              for (let idx in this.geojson_geometry) {
                geojson_obj.features.push({
                  "type": "Feature",
                  "geometry": this.geojson_geometry[idx],
                  "properties": this.geojson_properties[idx]
                })
              }
              console.log('geojson_obj before submit')
              console.log(geojson_obj)
              await update_farm_geojson_boundary(property_id, geojson_obj)

            }

            await getProperties(this.$auth.user.email)
            this.makePropertyList()

            setTimeout(() => {
              this.property_id = property_id
              this.onSelectProperty()
              this.$forceUpdate()

            }, 100)

          }

        }
        this.cancel_cuProperty()

      }


    },

    async submitEditPropertyNonFarmKey() {
      console.log('submit submitEditPropertyNonFarmKey')
      console.log(this.cuProperty)

      this.cuPropertyInProgress = true
      this.mapStyle.cursor = 'grab'
      console.log(this.cuPropertyCadsDict)
      console.log(Object.keys(this.cuPropertyCadsDict))
      let req_obj = {
        property_id: this.property_id,
        property_name: this.cuProperty.property_name,
        pic_code: this.cuProperty.pic_code,
        front_gate_address: this.cuProperty.front_gate_address,
        typed_front_gate_address: this.cuProperty.typed_front_gate_address,
        front_gate_lat: this.cuProperty.front_gate_lat,
        front_gate_lng: this.cuProperty.front_gate_lng,
        not_owner: !this.cuProperty.isOwner,
        owner_name: this.cuProperty.owner_name,
        owner_business_name: this.cuProperty.owner_business_name,
        owner_phone_number: this.cuProperty.owner_phone_number,
        owner_email_address: this.cuProperty.owner_email_address,
      }


      // first edit property_info

      await update_farm_info_nonfarmkey(req_obj)
      // check if geojson exists. if yes only update that
      if (this.geojson_geometry.length > 0) {
        console.log('updating geometry for non farm key <-----------')
        let geojson_obj = {
          "type": "FeatureCollection",
          "features": []
        }
        for (let idx in this.geojson_geometry) {
          geojson_obj.features.push({
            "type": "Feature",
            "geometry": this.geojson_geometry[idx],
            "properties": this.geojson_properties[idx]
          })
        }
        console.log(this.property_id)
        console.log(geojson_obj)
        await update_farm_geojson_boundary(this.property_id, geojson_obj)
      }

      this.cancel_cuProperty()

      delete this.$store.state.properties[this.property_id]
      await getProperties(this.$auth.user.email)
      this.makePropertyList()
      this.onSelectProperty()
      this.$forceUpdate()


    },

    async submitEditProperty() {
      console.log('submit Edit')
      console.log(this.cuProperty)

      // await this.check_AddProperty()
      // console.log(this.cuPropertyErrors)
      // console.log(this.cuPropertyErrors.length)

      // if (this.cuPropertyErrors.length < 1) {

      this.cuPropertyInProgress = true
      this.mapStyle.cursor = 'grab'
      console.log(this.cuPropertyCadsDict)
      console.log(Object.keys(this.cuPropertyCadsDict))
      let data = {
        property_id: this.property_id,
        property_name: this.cuProperty.property_name,
        pic_code: this.cuProperty.pic_code,
        front_gate_lat: this.cuProperty.front_gate_lat,
        front_gate_lng: this.cuProperty.front_gate_lng,
        cibo_ids: Object.keys(this.cuPropertyCadsDict),
        not_owner: !this.cuProperty.isOwner,
        owner_name: this.cuProperty.owner_name,
        owner_business_name: this.cuProperty.owner_business_name,
        owner_phone_number: this.cuProperty.owner_phone_number,
        owner_email_address: this.cuProperty.owner_email_address,
      }
      this.cancel_cuProperty()
      const resp = await editProperty(data)

      if (resp.length > 0) {
        if ('edit_property' in resp[0]) {
          delete this.$store.state.properties[this.property_id]
          await getProperties(this.$auth.user.email)
          this.makePropertyList()
          this.onSelectProperty()
          this.$forceUpdate()
        }

      }


    },

    on_change_upload_geojson() {
      this.deleteAllcuPropertiesFromListAndMap()
      this.geojson_error = null

      if (this.geojson_leaflet_obj.length > 0) {
        for (let g_obj of this.geojson_leaflet_obj) {
          g_obj.removeFrom(this.map)
        }
      }
      this.geojson_geometry = []
      this.geojson_properties = []
      this.geojson_leaflet_obj = []
      setTimeout(() => {
        if (this.geojson_file !== null) {
          this.readGeoJsonFile()
        }

      }, 500)

    },

    readGeoJsonFile() {
      // this.cuProperty.property_name = 'TEST' // TO REMOVE
      this.geojson_geometry = []
      this.geojson_properties = []
      this.geojson_leaflet_obj = []

      if (this.geojson_file !== null) {
        let reader = new FileReader();

        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        console.log(this.geojson_file);
        console.log(this.geojson_file.type);
        reader.readAsText(this.geojson_file);
        reader.onload = () => {
          let geoJsonIn;
          if (this.geojson_file.type === 'application/vnd.google-earth.kml+xml' || this.geojson_file.name.includes('.kml')) {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(reader.result, "text/xml");
            geoJsonIn = toGeoJSON.kml(xmlDoc)
          } else {
            geoJsonIn = JSON.parse(reader.result)
          }

          console.log(geoJsonIn)
          if ('geometry' in geoJsonIn) {
            this.geojson_geometry.push(geoJsonIn.geometry)
          } else {
            if ('features' in geoJsonIn) {
              for (let f of geoJsonIn.features) {
                this.geojson_geometry.push(f.geometry)
                this.geojson_properties.push(f.properties)
              }
            } else {
              this.geojson_error = 'GeoJSON format is not valid - it does not have geometry or features field.'
            }
          }

          if (this.geojson_geometry.length > 0) {
            for (let g of this.geojson_geometry) {
              this.geojson_leaflet_obj.push(L.geoJSON(g, {
                style: {
                  "color": 'yellow',
                  "weight": 5,
                  "opacity": 0.95
                },
              }))
            }

            for (let g_obj of this.geojson_leaflet_obj) {
              g_obj.addTo(this.map)

              this.map.fitBounds(L.geoJson(this.geojson_geometry).getBounds(),
                  {paddingTopLeft: [200, 50]})
            }

          }
          console.log('this.geojson_geometry')
          console.log(this.geojson_geometry)
          console.log(this.geojson_properties)

        }
      }


    },

    async find_by_geojson() {
      this.geojson__lookup_in_progress = true
      let cads_list = await find_cads_in_geojson(this.geojson_geometry)

      for (let propData of cads_list) {
        // const cibo_id = propData['cibo_id']
        const cibo_id = propData['cadastre_polygon_pid']

        if (!(cibo_id in this.cuPropertyCadsDict)) {
          this.cuPropertyCadsDict[cibo_id] = propData
          this.cuPropertyCadsObjs[cibo_id] = L.geoJSON(propData.geo_json, {
            style: {
              "color": 'red',
              "weight": 5,
              "opacity": 0.65
            },
          })
          propData.jurisdictional_id = propData.juri_id
          this.cuPropertyCadsObjs[cibo_id].bindTooltip(propData.jurisdictional_id, {
            permanent: true,
            opacity: 0.7
          }).openTooltip()
          this.cuPropertyCadsObjs[cibo_id].on('click', () => {
            this.cuPropertyCadsObjs[cibo_id].remove()
            delete this.cuPropertyCadsObjs[cibo_id]
            // L.DomEvent.stop(e)
            this.map.off('click');
            setTimeout(() => {
              delete this.cuPropertyCadsDict[cibo_id]
              this.addPropertyCheckOnMapClick()
            }, 100)
          })
          this.cuPropertyCadsObjs[cibo_id].addTo(this.map)

        }

        this.$forceUpdate();

      }

      this.geojson__lookup_in_progress = false

    },


    cancel_cuProperty() {
      console.log('in CANCEL ADD PROPERTY')
      this.cuPropertyStepper = 1

      this.cuPropertyInProgress = false
      this.cuPropertyErrors = []
      this.cuProperty = {}
      if (this.cuPropertyGateMarker !== null) {
        this.map.removeLayer(this.cuPropertyGateMarker)
      }

      this.geojson_error = null
      this.geojson_file = null
      if (this.geojson_leaflet_obj.length > 0) {
        for (let g_obj of this.geojson_leaflet_obj) {
          g_obj.removeFrom(this.map)
        }
      }
      this.geojson_geometry = []
      this.geojson_properties = []
      this.geojson_leaflet_obj = []

      this.cuPropertyGateMarker = null
      this.digitizeGateLocationOnMap = false
      this.selectPropertyOnMap = false
      this.map.off('click');
      this.mapStyle.cursor = 'grab'

      this.deleteAllcuPropertiesFromListAndMap()
      this.googleAddressIdx = null
      this.googleAddressList = []
      this.googleAddressLoading = false

      // add to map if edit
      if (this.editPropertyOn) {
        this.onSelectProperty()
      }

      this.addPropertyOn = false
      this.editPropertyOn = false

      for (let g_obj of this.geojson_leaflet_obj) {
        g_obj.removeFrom(this.map)
      }


      this.$forceUpdate();
    },

    addPropertyKeyToShoppingCart(item_type, comparison_cads) {
      addToShoppingCart(this.property_id, this.$store.state.properties[this.property_id].inf.property_name, item_type, comparison_cads, null)
      console.log('shopping cart')
      console.log(this.$store.state.shoppingCart)
      this.$forceUpdate();
    },

    // SELECT BY CLICK ON MAP
    deletecuPropertyFromListAndMap(cibo_id) {
      console.log('<<<<<<<<<<<<<<<<<<<<<')
      console.log(cibo_id)
      this.map.removeLayer(this.cuPropertyCadsObjs[cibo_id])
      delete this.cuPropertyCadsObjs[cibo_id]
      delete this.cuPropertyCadsDict[cibo_id]
      this.$forceUpdate();
    },
    deleteAllcuPropertiesFromListAndMap() {
      for (let cibo_id in this.cuPropertyCadsDict) {
        this.deletecuPropertyFromListAndMap(cibo_id)
      }
    },
    async getPropertyBoundaryOnMapClick(e) {

      showSnackBar('Loading property boundary for clicked location ...', 10000, 'red')
      const propResp = await getPropertyBoundaryAtLatLon(e.latlng.lat, e.latlng.lng)
      this.$store.state.snackbar.show = false
      this.$store.state.snackbar.text = null
      console.log(propResp)
      if (propResp.length > 0) {

        const propData = propResp[0]
        // const cibo_id = propData['cibo_id']
        const cibo_id = propData['cadastre_polygon_pid']

        if (!(cibo_id in this.cuPropertyCadsDict)) {
          this.cuPropertyCadsDict[cibo_id] = propData
          this.cuPropertyCadsObjs[cibo_id] = L.geoJSON(propData.geometry, {
            style: {
              "color": "red",
              "weight": 5,
              "opacity": 0.65
            },
            // onEachFeature: (feature, layer) => {
            //   console.log('***********************')
            //   console.log(feature)
            //   console.log(layer)
            // }
          })
          this.cuPropertyCadsObjs[cibo_id].bindTooltip(propData.jurisdictional_id, {
            permanent: true,
            opacity: 0.7
          }).openTooltip()
          this.cuPropertyCadsObjs[cibo_id].on('click', () => {
            this.cuPropertyCadsObjs[cibo_id].remove()
            delete this.cuPropertyCadsObjs[cibo_id]
            // L.DomEvent.stop(e)
            this.map.off('click');
            setTimeout(() => {
              delete this.cuPropertyCadsDict[cibo_id]
              this.addPropertyCheckOnMapClick()
            }, 100)
          })
          this.cuPropertyCadsObjs[cibo_id].addTo(this.map)

        }

        this.$forceUpdate();

      }

    },
    addPropertyCheckOnMapClick() {
      this.addCadToPropertyOnMapClick()
      this.setGateLocationOnMapClick()
    },
    setGateLocationOnMapClick() {
      if (this.cuProperty.front_gate_lat === null && this.digitizeGateLocationOnMap) {
        this.mapStyle.cursor = 'crosshair'
        this.map.on('click', (e) => {

          this.cuProperty.front_gate_lat = e.latlng.lat;
          this.cuProperty.front_gate_lng = e.latlng.lng;

          // new_marker.bindTooltip(
          //     'Front Gate (Drag Me)',
          //     {
          //       style: {"opacity": 0.5},
          //       permanent: true,
          //       draggable: true,
          //       name: 'front-gate-tooltip'
          //     }
          // ).openTooltip();

          this.cuPropertyGateMarker = L.marker(e.latlng, {title: "Gate Location", draggable: true})
              .bindTooltip("<b>Front Gate</b><br>Drag me", {
                style: {"opacity": 0.5},
                permanent: true,
                draggable: true,
                name: 'front-gate-tooltip'
              })
              .addTo(this.map)
              .on('dragend', (de) => {
                let latlng = de.target.getLatLng()
                this.cuProperty.front_gate_lat = latlng.lat;
                this.cuProperty.front_gate_lng = latlng.lng;
                this.$forceUpdate()

              })
          this.cuPropertyGateMarker.openTooltip()
          this.digitizeGateLocationOnMap = false

          this.mapStyle.cursor = 'grab'
          this.map.off('click')

          this.addCadToPropertyOnMapClick()
        });

      }

    },
    addCadToPropertyOnMapClick() {
      if (this.selectPropertyOnMap) {
        this.mapStyle.cursor = 'crosshair'
        this.map.on('click', this.getPropertyBoundaryOnMapClick)
      } else {
        this.map.off('click');
        this.mapStyle.cursor = 'grab'
      }
      this.$forceUpdate();
    },
    setPanelWidth() {
      if (screen.width < 500) {
        this.leftDrawer.width = 300
      }
    },


  }
  ,
  mounted() {
    this.setPanelWidth()
    this.initMap()
    this.addMapboxGeocoder()
    this.setLeftDrawerBorderWidth();
    this.setEvents();

  }
  ,

}
</script>

<style scoped>

</style>
